import axios from "axios"
import { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import Cookies from "universal-cookie";
import { Accordion, Button, Form, InputGroup, Nav, Offcanvas, Stack, Toast, ToastContainer } from "react-bootstrap";
import { IoSearchSharp } from "react-icons/io5";
import { MdAddShoppingCart } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { MdPriceChange } from "react-icons/md";
import { IoFilterSharp } from "react-icons/io5";
import { ModalProduct, MsgToast } from "../components/MsgComponent";
import CartComponent from "../components/CartComponent";
import { FaPlus } from "react-icons/fa6";
import PaginatedItems from "../components/pagination";


function Market() {
    const { t, i18n } = useTranslation();
    const [showProduct, setShowProduct] = useState(false)

    let params = useParams()
    const cookie = new Cookies()
    const [open, setOpen] = useState(false)
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(50)

    useEffect(() => {
        cookie.remove("bearer")
        cookie.remove("refreshToken")
        cookie.remove("userDetals")
    })

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 500);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const [search, setSearch] = useState()
    const [priceCategory, setPriceCategory] = useState()

    const [filterData, setFilterData] = useState()
    const dataSaerch = { search: search, category: params.category, priceCategory: priceCategory, limit, page }
    async function getSearchData() {
        await axios.post(`${process.env.REACT_APP_API_URL}/clineArticales/SearchProduct`, dataSaerch)
            .then((doc) => { setFilterData(doc.data) })
    }
    useEffect(() => {
        const debounce = setTimeout(() => {
            getSearchData()
        }, 800)
        return () => clearTimeout(debounce)
    }, [search, priceCategory, limit, page])
    const [show, setShow] = useState(false);
    const CloseFilter = () => {
        setOpen(false)
    }

    const btnAddToCart = () => {
        setShow(true)
    }

    return (<>
        <div style={{ display: 'flex' }}>
            <div style={{ display: "flex", marginTop: "65px", backgroundColor: "white", minHeight: "100vh", width: "100%" }}>
                <div style={{ width: "100%", padding: isMobile ? "0 2.5%" : "" }}>
                    <div style={{ width: "96%", backgroundColor: "white", margin: "10px 2%", display: "flex", justifyContent: "center" }}>
                        {isMobile && <><button style={{ backgroundColor: "initial", border: "none" }} onClick={() => { setOpen(true) }}> <IoFilterSharp size={"30"} style={{ margin: "5px 5px 5px 0" }} /></button></>}
                        <div className="d-flex " style={{ width: "100%", justifyContent: "center", marginTop: "20px" }}>
                            <Form className="d-flex search-bar" style={{ width: "80%" }}>
                                <InputGroup>
                                    <InputGroup.Text style={{ backgroundColor: "white", borderLeft: "none", borderRadius: "0" }}>
                                        <IoSearchSharp style={{ color: "#ea004b", fontSize: "20px" }} />
                                    </InputGroup.Text>
                                    <Form.Control
                                        type="search"
                                        placeholder={t("Search")}
                                        aria-label="Search"
                                        value={search}
                                        style={{ borderRadius: "0", borderRight: "none", margin: "0", padding: "0" }}
                                        onChange={(e) => setSearch(e.target.value)}
                                    />
                                </InputGroup>
                            </Form>
                        </div>
                    </div>
                    <div style={{ backgroundColor: "white", width: isMobile ? "100%" : '96%', margin: isMobile ? "0" : "0 2% 1% 2%", borderRadius: "5px", minHeight: "85vh" }}>
                        <div id="PageUlProduct">
                            {filterData?.movies?.map((item) =>
                                <div class="card" style={{ width: "150px", margin: "1%", border: "none", borderRadius: "10px", maxHeight: "250px" }}>
                                    {item.disable == false && item.number != 0 && <button title={t("Add to cart")} onClick={btnAddToCart} style={{ position: "absolute", marginTop: "5px", marginRight: "5px", width: "40px", height: "40px", color: "#ea004b", borderRadius: "50%", backgroundColor: "white", border: "none" }}>{<FaPlus style={{ fontSize: "20px" }} />}</button>}
                                    <Link onClick={() => { setShowProduct({ show: true, dataa: item, role: "visitor" }) }} style={{ margin: "1%", minWidth: isMobile && "150px" }} >
                                        <img src={`${process.env.REACT_APP_API_URL}/files/${item.file[0]}`} class="card-img-top" style={{ maxHeight: "150px" }} />
                                        <div class="card-body" style={{ textAlign: "center" }}>
                                            {(item.number == 0 || item.disable == true) && <div style={{ color: "red", fontWeight: "500" }}>{t("Out of stock")}</div>}
                                            <h5 class="card-title" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>{item.newPricee && <div>{item.newPricee} ₪</div>} <div style={item.newPricee && { fontWeight: "400", fontSize: "13px", textDecoration: 'line-through', color: "#818181" }}>{item.price} ₪</div>  </h5>
                                            {item.discount && <p class="card-text" style={{ textAlign: "center", backgroundColor: "#ea004b", borderRadius: "10px", color: "white" }}>{item.discount}% {t("Discount")}</p>}
                                            <p class="card-text">{i18n.language == "ar" ? item.title.ar : item.title.he}</p>
                                        </div>
                                    </Link>
                                </div>
                            )}
                        </div>
                        <div style={{ display: 'flex', justifyContent: "flex-start", marginRight: "2%", alignItems: 'baseline' }}>
                            <Form.Select style={{ width: "80px" }} onChange={(e) => setLimit(e.target.value)}>
                                {/* <option value={20}>20</option> */}
                                {/* <option value={30}>30</option> */}
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                                <option value={150}>150</option>
                            </Form.Select>
                            <PaginatedItems total={filterData?.total} itemsPerPage={limit} setPage={setPage} />
                        </div>
                    </div>
                </div>

                <Offcanvas i18nIsDynamicList={true} show={open} onHide={CloseFilter} placement="end" style={{ width: '150px', height: '100%' }}>
                    <Offcanvas.Header closeButton>
                        <div className="ms-auto fw-bold fs-5" style={{ bottom: "50px", marginRight: "20%" }}>
                            {t("Filter")}
                        </div>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Stack >
                            <form onChange={(e) => setPriceCategory(e.target.value)} defaultValue={priceCategory}>
                                <input type="radio" id="All" name="Role" style={{ width: "20%" }} />
                                <label for={"All"} style={{ width: "80%" }}>{t("All")}</label>
                                <input type="radio" id="0-199" name="Role" value="0-199" style={{ width: "20%" }} />
                                <label for={"0-199"} style={{ width: "80%" }}>0 - 199</label>
                                <input type="radio" id="200-499" name="Role" value="200-499" style={{ width: "20%" }} />
                                <label for={"200-499"} style={{ width: "80%" }}>200 - 499</label>
                                <input type="radio" id="500-999" name="Role" value="500-999" style={{ width: "20%" }} />
                                <label for={"500-999"} style={{ width: "80%" }}>500 - 999</label>
                                <input type="radio" id="1000" name="Role" value="1000" style={{ width: "20%" }} />
                                <label for={"1000"} style={{ width: "80%" }}> {t("more than")} 1000</label>
                            </form>
                        </Stack>
                    </Offcanvas.Body>
                    <div style={{ width: "90%", margin: "0 5%" }}>
                        {/* <Button variant="outline-success" style={{ width: "100%", bottom: "0" }} onClick={"btnFinishMarket"}> asdsad</Button> */}
                    </div>
                </Offcanvas>
                <ModalProduct show={showProduct.show} handleClose={() => setShowProduct(false)} dataa={showProduct.dataa} role={showProduct.role} />
                <ToastContainer >
                    <MsgToast setShow={setShow} body={t("You are not registered, please log in and shop immediately")} show={show} title={t("You are not registered")} type={"danger"} />
                </ToastContainer >
            </div>
            {isMobile ? null :
                <div style={{ minHeight: "100vh", height: "100%", width: "25%", maxWidth: "500px", marginTop: "65px" }}>
                    {<CartComponent />}
                </div>
            }
        </div></>)
}

export default Market