import axios from 'axios';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const ResetePassword = () => {
    const { t, i18n } = useTranslation();
    let params = useParams()
    const [err, setErr] = useState()
    const [msg, setMsg] = useState()
    const [password, setPassword] = useState()
    const [confirmePassword, setConfirmePassword] = useState()
    const btnResetePassword = async () => {
        if (password == confirmePassword) {
            await axios.patch(`${process.env.REACT_APP_API_URL}/auth/resetPassword/${params.token}`, { password })
                .then((doc) => { setMsg(doc.data.msg) })
                .then(() => { setErr("") })
                .catch((err) => {
                    setErr(err.response.data.errors[0].msg);
                    setMsg("");
                });

        } else if (password != confirmePassword) {
            setErr("كلمتا المرور غير متطابقات")
            setMsg("")
        }
    }

    return (<>
        <div id="allPage" style={{ minHeight: "500px" }}>
            <div id="PageResatePassword" style={{ width: "40%", marginRight: "30%", marginLeft: "30%" }}>
                <div id="H1Login">
                    <h1>{t("Password Recovery")}</h1>
                </div>
                <div style={{ color: 'red' }}>{err}</div>
                <div style={{ color: "green" }}>{msg}</div>
                <div class="form-floating mb-3">
                    <input type="password" name='password' class="form-control" id="floatingInputUser" onChange={e => setPassword(e.target.value)} placeholder="password" />
                    <label for="floatingInput" >{t("Password")}</label>
                </div><div class="form-floating mb-3">
                    <input type="password" name="ConfirmePassword" class="form-control" id="floatingInputUser" onChange={e => setConfirmePassword(e.target.value)} placeholder="ConfirmePassword" />
                    <label for="floatingInput" >{t("CreateUser.Confirme Password")}</label>
                </div>
                <div>
                    <Button bsPrefix className={"ButtonBNB"} style={{ width: "200px" }} onClick={btnResetePassword}>{t("Password Recovery")}</Button>
                </div>
            </div>
        </div>
    </>)
}

export default ResetePassword;