import { Outlet, useNavigate } from "react-router-dom";
import { User } from "../context/context";
import { useContext, useEffect, useState } from "react";
import axios from 'axios';
import Cookies from "universal-cookie";
import { Loading } from "./loading";
import { isExpired, decodeToken } from "react-jwt";

export default function RefreshPage() {
    const [loading, setLoading] = useState(true);
    const usernaw = useContext(User);
    const ContextToken = usernaw.auth.token;
    const nav = useNavigate();
    const cookie = new Cookies();

    useEffect(() => {
        const getTokenCookie = cookie.get("bearer");
        const TokenCookie =  getTokenCookie?.token ? getTokenCookie.token : nav("/GetCategoryMarket");
        const userDetalsCookie = getTokenCookie?.userDetals ? getTokenCookie.userDetals : nav("/GetCategoryMarket");
        const RefreshTokenCookie =  getTokenCookie?.refreshToken ? getTokenCookie.refreshToken : nav("/GetCategoryMarket");
        const decodedTokenCookie =  getTokenCookie?.decoded ? getTokenCookie.decoded : nav("/GetCategoryMarket");
        const decodedContextToken = decodeToken(ContextToken);
        const IsExpiredContextToken = isExpired(ContextToken);

        const timer = setTimeout(() => {
            setLoading(false);
        }, 10000); // 15 ثانية

        async function refresh() {
            if (TokenCookie) {
                if (IsExpiredContextToken) {
                    axios.post(`${process.env.REACT_APP_API_URL}/auth/refreshToken`, null, {
                        headers: {
                            Accept: "application/json",
                            Authorization: "Bearer " + RefreshTokenCookie,
                        }
                    }).then(async (doc) => {
                        const token = doc.data.token;
                        const newuserDetals = doc.data.data;
                        const newdecoded = doc.data.decoded;
                        await cookie.remove("bearer");
                        await cookie.set("bearer", { token: token, refreshToken: RefreshTokenCookie, userDetals: newuserDetals, decoded: newdecoded }, { path: "/" });
                        await usernaw.setAuth({ token: token, userDetals: newuserDetals, decoded: newdecoded });
                        setLoading(false);
                    }).catch((err) => {
                        console.log("err refreshPage : ", err);
                        setLoading(false);
                    });
                } else {
                    await cookie.set("bearer", { token: TokenCookie, refreshToken: RefreshTokenCookie, userDetals: userDetalsCookie, decoded: decodedTokenCookie }, { path: "/" });
                    await usernaw.setAuth({ token: TokenCookie, userDetals: userDetalsCookie });
                    setLoading(false);
                }
            } else {
                nav("/");
            }
        }

        if (!ContextToken) {
            refresh();
        } else {
            setLoading(false);
        }

        return () => clearTimeout(timer);
    }, [ContextToken, cookie, usernaw]);

    return loading ? <Loading /> : <Outlet />;
}
