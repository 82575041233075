import React, { useContext, useState } from 'react';
import Autosuggest from 'react-autosuggest';
import axios from 'axios';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { useTranslation } from "react-i18next";
import './compnennts.css';
import { Link, useNavigate } from 'react-router-dom';
import { User } from '../context/context';
import { Button } from 'react-bootstrap';



const SearchBar = () => {
    const { t, i18n } = useTranslation();
    const usernaw = useContext(User)
    const role = usernaw.auth.userDetals?.role
    const nav = useNavigate()
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);

    const fetchSearchResults = async (value) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/clineArticales/SearchProduct`, {
                search: value,
                page: 1,
                limit: 10
            });
            return response.data.movies;
        } catch (error) {
            console.error('Error fetching search results:', error);
            return [];
        }
    };

    const onSuggestionsFetchRequested = async ({ value }) => {
        const results = await fetchSearchResults(value);
        setSearchResults(results);
    };

    const onSuggestionsClearRequested = () => {
        setSearchResults([]);
    };

    const getSuggestionValue = (suggestion) => i18n.language == "ar" ? suggestion.name.ar : suggestion.category.he;

    const renderSuggestion = (suggestion) => (
        <Link style={{ padding: '0', margin: "0" }}
            to={role == "user" ? `/cline/Articales/SearchPage/${suggestion.title.ar}` :
                (role == "admin" ? `/admin/market/SearchPage/${suggestion.title.ar}` :
                    `/SearchPage/${suggestion.title.ar}`)}>
            <div>
                <img
                    src={`${process.env.REACT_APP_API_URL}/files/${suggestion.file[0]}`}
                    alt="item image"
                    class="card-img-top"
                    style={{ width: "50px" , marginLeft:"30px"}} />
                {/* {suggestion.name.ar} - {suggestion.price} */}
                {suggestion.name.ar}
            </div>
        </Link>
    );

    const BtnSearch = () => {
        nav(role == "user" ? `/cline/Articales/SearchPage/${searchTerm}` :
            (role == "admin" ? `/admin/market/SearchPage/${searchTerm}` :
                `/SearchPage/${searchTerm}`))
    }

    const inputProps = {
        placeholder: t('Search'),
        value: searchTerm,
        onChange: (event, { newValue }) => setSearchTerm(newValue)
    };

    return (<form style={{ width: "100%", display: "flex" }} onSubmit={BtnSearch}>
        <Autosuggest
            suggestions={searchResults}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            inputProps={inputProps}
        />
        {/* <Button type='submit' onClick={BtnSearch} className='ButtonBNB' style={{ width: "60px",color:"#ea004b" ,backgroundColor:'white' }}>{t('Search')}</Button> */}
    </form>
    );
};

export default SearchBar;
