import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { User } from "../../context/context";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, CloseButton, Form } from "react-bootstrap";
import { MsgModal, MsgModalAnOffer } from "../../components/MsgComponent";


const CreateAnOffer = () => {
    const { t } = useTranslation();
    const usernaw = useContext(User)
    const token = usernaw.auth.token
    const nav = useNavigate()

    const [emgg, setEmg] = useState(null);
    const [formData, setFormData] = useState(new FormData());
    const [priceDelevaryOffer, setPriceDelevaryOffer] = useState()
    const [priceDelevary, setPriceDelevary] = useState()
    const [getPriceDelevary, setGetPriceDelevary] = useState()
    const [timeGetOpenMarket, setTimeGetOpenMarket] = useState()
    const [timeOpenMarket, setTimeOpenMarket] = useState(timeGetOpenMarket)
    const [timeGetCloseMarket, setTimeGetCloseMarket] = useState()
    const [timeCloseMarket, setTimeCloseMarket] = useState(timeGetCloseMarket)
    const [getAnOffer, setGetAnOffer] = useState()

    const [hoveredItemId, setHoveredItemId] = useState(null);
    const [showAnOffer, setShowAnOffer] = useState(false)
    const [show, setShow] = useState(false);
    const [itemId, setItemId] = useState();
    const [categoryAnOffer, setCategoryAnOffer] = useState("Top")

    const handleClose = () => setShow(false);
    const handleShow = (id) => {
        setShow(true)
        setItemId(id)
    };


    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/clineArticales/GetAnOfferPriceDelevary`)
            .then((doc) => {
                { setGetPriceDelevary(doc.data) } {
                    if (doc.data.isoTimeOpenMarket) {
                        const dateOpen = new Date(doc.data.isoTimeOpenMarket);
                        const dateClose = new Date(doc.data.isoTimeCloseMarket);
                        const hoursOpen = String(dateOpen.getHours()).padStart(2, '0');
                        const hoursClose = String(dateClose.getHours()).padStart(2, '0');
                        const minutesOpen = String(dateOpen.getMinutes()).padStart(2, '0');
                        const minutesClose = String(dateClose.getMinutes()).padStart(2, '0');
                        setTimeGetOpenMarket(`${hoursOpen}:${minutesOpen}`)
                        setTimeOpenMarket(`${hoursOpen}:${minutesOpen}`)
                        setTimeGetCloseMarket(`${hoursClose}:${minutesClose}`)
                        setTimeCloseMarket(`${hoursClose}:${minutesClose}`)
                        // setRetrievedTime(`${hours}:${minutes}`);
                    }
                }
            })
            .catch((err) => console.log(err))

        axios.get(`${process.env.REACT_APP_API_URL}/clineArticales/GetAnOffer`)
            .then((doc) => setGetAnOffer(doc.data))
            .catch((err) => console.log(err))
    }, [])

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 500);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    function handleFileSelect(event) {
        const files = event.target.files;
        const selectedFiles = []

        for (let i = 0; i < files.length; i++) {
            selectedFiles.push(files[i]);
        }
        setEmg(selectedFiles);

        const updatedFormData = new FormData();
        for (let i = 0; i < selectedFiles.length; i++) {
            updatedFormData.append("file", selectedFiles[i]);
        }
        setFormData(updatedFormData);
    }


    async function btnInsert() {
        if (!emgg || emgg.length === 0) {
            console.error("No files selected");
            return;
        }

        await axios.post(`${process.env.REACT_APP_API_URL}/articales/CreateAnOffer/${categoryAnOffer}`, formData, {
            headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "multipart/form-data"
            }
        })
            .then(async () =>
                await Swal.fire({
                    icon: 'success',
                    title: '<h1> Success </h1> <br /> تم اضافة العرض ',
                    // text: ' تم اضافة المنتج ' ,
                    // footer: '<a href="/login">login?</a>',
                    showConfirmButton: false,
                    timer: 1500,
                }))
            .then((doc) => { window.location.replace("/admin/market/CreateAnOffer") })
            .catch((err) => console.log("err Post : ", err))
    }

    const datapriceDelevary = { priceDelevaryOffer, priceDelevary }

    const btnInsertPriceDelevary = async () => {
        await axios.patch(`${process.env.REACT_APP_API_URL}/articales/CreateAnOfferPriceDelevary`, datapriceDelevary, {
            headers: {
                Authorization: "Bearer " + token,
            }
        })
            .then(async () =>
                await Swal.fire({
                    icon: 'success',
                    title: '<h1> Success </h1> <br /> تم اضافة العرض ',
                    // text: ' تم اضافة المنتج ' ,
                    // footer: '<a href="/login">login?</a>',
                    showConfirmButton: false,
                    timer: 1500,
                }))
    }

    async function saveTime() {
        if (timeOpenMarket && timeCloseMarket) {
            // تحويل الوقت إلى صيغة ISO 8601
            const currentDateOpenMarket = new Date();
            const currentDateCloseMarket = new Date();
            const timePartsOpenMarket = timeOpenMarket.split(':');
            const timePartsCloseMarket = timeCloseMarket.split(':');
            currentDateOpenMarket.setHours(timePartsOpenMarket[0], timePartsOpenMarket[1]);
            currentDateCloseMarket.setHours(timePartsCloseMarket[0], timePartsCloseMarket[1]);

            const isoTimeOpenMarket = currentDateOpenMarket.toISOString();
            const isoTimeCloseMarket = currentDateCloseMarket.toISOString();

            await axios.patch(`${process.env.REACT_APP_API_URL}/articales/CreateAnOfferPriceDelevary`, { isoTimeOpenMarket, isoTimeCloseMarket }, {
                headers: {
                    Authorization: "Bearer " + token,
                }
            })
                .then(async () =>
                    await Swal.fire({
                        icon: 'success',
                        title: '<h1> Success </h1> <br /> تم تعديل الوقت ',
                        // text: ' تم اضافة المنتج ' ,
                        // footer: '<a href="/login">login?</a>',
                        showConfirmButton: false,
                        timer: 1500,
                    }))
        } else {
            console.log('لم يتم إدخال وقت بعد.');
        }
    }

    const DeleteAnOffer = async (id) => {
        await axios.delete(`${process.env.REACT_APP_API_URL}/articales/DeleteAnOffer/${id}`, {
            headers: {
                Authorization: "Bearer " + token,
            }
        })
            .then(() => { setShow(false) })
            .then(() => window.location.replace("/admin/market/CreateAnOffer"))
    }

    return (<>
        <div id="allPage">
            <div id="PageAdmin">
                <div id="H1Login">
                    <h1>{t("hedarAdmin.Create Offer")}</h1>
                </div>

                <h2 style={{ marginBottom: "20px" }}>عرض الصور</h2>

                <div class="form-floating mb-3">
                    <input type="file" class="form-control" id="floatingInputNumber" onChange={(e) => handleFileSelect(e)} multiple placeholder="name@example.com" />
                    <label for="floatingInputNumber">{t("ProductId.emg")}</label>
                    <div className="errMsgInbut" id="errphone"></div>
                </div>
                <div>
                    <Form.Select style={{ width: "100px", padding: "0 0 0 0 ", height: "30px" }} onChange={(e) => setCategoryAnOffer(e.target.value)} defaultChecked={"Bottom"}>
                        {/* <option>en</option> */}
                        <option value={"Top"}>{t("Top")}</option>
                        <option value={"Bottom"}>{t("Bottom")}</option>
                    </Form.Select>
                </div>

                <div class="col-12" style={{ marginTop: "10px" }}>
                    <Button bsPrefix className={"ButtonBNB"} style={{ width: "200px" }} onClick={btnInsert}> {t("hedarAdmin.Create Offer")}</Button>
                </div>
                <h3 style={{ width: '80%', textAlign: "start" }}>
                    {t("Top")}
                </h3>
                <div style={{ width: "100%", display: "flex", flexWrap: 'wrap', justifyContent: "center" }}>
                    {getAnOffer && getAnOffer.map((item) => (<>
                        {item.category == "Top" &&
                            <div
                                key={item._id}
                                className="card"
                                style={{ width: "150px", margin: "1%", border: "none", position: "relative" }}
                                onMouseEnter={() => setHoveredItemId(item._id)}
                                onMouseLeave={() => setHoveredItemId(null)}>
                                {isMobile ? (
                                    <CloseButton
                                        style={{ position: "absolute", top: "10px", left: "23px", zIndex: 10 }}
                                        onClick={() => handleShow(item._id)}
                                    />
                                ) : hoveredItemId === item._id && (
                                    <CloseButton
                                        style={{ position: "absolute", top: "10px", left: "23px", zIndex: 10 }}
                                        onClick={() => handleShow(item._id)}
                                    />
                                )}
                                <Link onClick={() => setShowAnOffer({ show: true, data: item })}>
                                    <div style={{ margin: "10px" }}>
                                        <img src={`${process.env.REACT_APP_API_URL}/files/${item.file[0]}`} class="card-img-top" style={{ maxHeight: "100px", maxWidth: "100px" }} />
                                    </div>
                                </Link>
                            </div>}
                    </>))}
                </div>
                <h3 style={{ width: '80%', textAlign: "start" }}>
                    {t("Bottom")}
                </h3>
                <div style={{ width: "100%", display: "flex", flexWrap: 'wrap', justifyContent: "center" }}>
                    {getAnOffer && getAnOffer.map((item) => (<>
                        {item.category == "Bottom" &&
                            <div
                                key={item._id}
                                className="card"
                                style={{ width: "150px", margin: "1%", border: "none", position: "relative" }}
                                onMouseEnter={() => setHoveredItemId(item._id)}
                                onMouseLeave={() => setHoveredItemId(null)}>
                                {isMobile ? (
                                    <CloseButton
                                        style={{ position: "absolute", top: "10px", left: "23px", zIndex: 10 }}
                                        onClick={() => handleShow(item._id)}
                                    />
                                ) : hoveredItemId === item._id && (
                                    <CloseButton
                                        style={{ position: "absolute", top: "10px", left: "23px", zIndex: 10 }}
                                        onClick={() => handleShow(item._id)}
                                    />
                                )}
                                <Link onClick={() => setShowAnOffer({ show: true, data: item })}>
                                    <div style={{ margin: "10px" }}>
                                        <img src={`${process.env.REACT_APP_API_URL}/files/${item.file[0]}`} class="card-img-top" style={{ maxHeight: "100px", maxWidth: "100px" }} />
                                    </div>
                                </Link>
                            </div>}
                    </>))}
                </div>
            </div>
            <div id="PageAdmin" style={{ marginTop: "50px", paddingTop: "50px" }}>
                <h2 style={{ marginBottom: "20px" }}>{t("التوصيل")}</h2>

                <div class="form-floating mb-3" style={{ width: "40%" }}>
                    <input type="number" class="form-control" id="1" onChange={(e) => setPriceDelevary(e.target.value)} placeholder="name@example.com" defaultValue={getPriceDelevary?.priceDelevary} />
                    <label for="1">{t("سعر التوصيل")}</label>
                    <div className="errMsgInbut" id="errphone"></div>
                </div> <div class="form-floating mb-3" style={{ width: "40%" }}>
                    <input type="number" class="form-control" id="1" onChange={(e) => setPriceDelevaryOffer(e.target.value)} placeholder="name@example.com" defaultValue={getPriceDelevary?.priceDelevaryOffer} />
                    <label for="1">{t("مبلغ عرض التوصيل المجاني")}</label>
                    <div className="errMsgInbut" id="errphone"></div>
                </div>
                <div class="col-12">
                    <Button bsPrefix className={"ButtonBNB"} style={{ width: "200px" }} onClick={btnInsertPriceDelevary}> {t("hedarAdmin.Create Offer")}</Button>
                </div>
            </div>
            <div id="PageAdmin" style={{ marginTop: "50px", paddingTop: "50px", display: 'flex' }}>
                <h2 style={{ marginBottom: "20px" }}> وقت فتح واغلاق المتجر</h2>

                <div class="form-floating mb-3" style={{ width: "40%" }}>
                    <input type="time" id="TimeOpenMarket" class="form-control" onChange={(e) => setTimeOpenMarket(e.target.value)} defaultValue={timeGetOpenMarket} />
                    <label for="TimeOpenMarket"> فتح المتجر</label>
                </div>
                <div class="form-floating mb-3" style={{ width: "40%" }}>
                    <input type="time" id="TimeCloseMarket" class="form-control" onChange={(e) => setTimeCloseMarket(e.target.value)} defaultValue={timeGetCloseMarket} />
                    <label for="TimeCloseMarket">إغلاق المتجر</label>
                </div>
                <div class="col-12">
                    <Button bsPrefix className={"ButtonBNB"} style={{ width: "200px" }} onClick={saveTime}> {t("hedarAdmin.Create Offer")}</Button>
                </div>
            </div>
        </div>
        <MsgModal show={show} handleClose={handleClose} opj={() => DeleteAnOffer(itemId)} title={t("confirmation message")} body={t("Do you really want to delete this category?")} BtnState={t("Yes sure")} />
        <MsgModalAnOffer show={showAnOffer.show} handleClose={() => setShowAnOffer(false)} dataa={showAnOffer.data} />
    </>)
}

export default CreateAnOffer