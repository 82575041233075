import axios from "axios"
import { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { Loading } from "../refreshPage/loading"
import img1 from "../file/555.png"
// import Form from 'react-bootstrap/Form';
import { IoSearchSharp } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import Cookies from "universal-cookie";
import { Form, InputGroup, ToastContainer } from 'react-bootstrap';
import CartComponent from "../components/CartComponent"
import { FaPlus } from "react-icons/fa6";
import { ModalProduct, MsgToast } from "../components/MsgComponent"
import PaginatedItems from "../components/pagination"
import SearchBar from "../components/SearchBar"


function GetCategoryMarket() {
    const [dataaCategory, setDataaCategory] = useState()
    const [dataaOffers, setDataaOffers] = useState()
    const [dataaAllProduct, setDataaAllProduct] = useState()
    const { t, i18n } = useTranslation();
    const cookie = new Cookies()
    const [imagesTop, setImagesTop] = useState()
    const [imagesBottom, setImagesBottom] = useState()
    const [showProduct, setShowProduct] = useState(false)
    const [show, setShow] = useState(false);
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(50)
    const [pageOffers, setPageOffers] = useState(1)
    const [limitOffers, setLimitOffers] = useState(50)

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);

    const lng = cookie.get("i18next") || "ar"

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/clineArticales/GetArticaleOffers/${limitOffers}/${pageOffers}`)
            .then((doc) => { setDataaOffers(doc.data) })
            .catch((err) => console.log("err Get Offers :", err))
    }, [lng, limit, page])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/clineArticales/postGetArticales/${limit}/${page}`)
            .then((doc) => { setDataaAllProduct(doc.data) })
            .catch((err) => console.log("err Get Offers :", err))
    }, [lng, limitOffers, pageOffers])


    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/clineArticales/GetCategoryMarket`)
            .then((doc) => setDataaCategory(doc.data))
            .catch((err) => console.log("err Get :", err))

        axios.get(`${process.env.REACT_APP_API_URL}/clineArticales/GetAnOffer/Top`)
            .then((doc) => setImagesTop(doc.data))
            .catch((err) => console.log("err : ", err))

        axios.get(`${process.env.REACT_APP_API_URL}/clineArticales/GetAnOffer/Bottom`)
            .then((doc) => setImagesBottom(doc.data))
            .catch((err) => console.log("err : ", err))

        const handleResize = () => {
            setIsMobile(window.innerWidth <= 500);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [])

    const [currentSetIndex, setCurrentSetIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentSetIndex(prevIndex => (prevIndex + (isMobile ? 1 : 2)) % imagesTop?.length);
        }, 5000);

        return () => clearInterval(interval);
    }, [imagesTop, isMobile]);

    const handleNext = () => {
        setCurrentSetIndex(prevIndex => (prevIndex + (isMobile ? 1 : 2)) % imagesTop?.length);
    };

    const handlePrev = () => {
        setCurrentSetIndex(prevIndex => (prevIndex - (isMobile ? 1 : 2) + imagesTop?.length) % imagesTop?.length);
    };

    const [currentSetIndeximagesBottom, setCurrentSetIndeximagesBottom] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentSetIndeximagesBottom(prevIndex => (prevIndex + (isMobile ? 1 : 2)) % imagesBottom?.length);
        }, 5000);

        return () => clearInterval(interval);
    }, [imagesBottom, isMobile]);

    const handleNextimagesBottom = () => {
        setCurrentSetIndeximagesBottom(prevIndex => (prevIndex + (isMobile ? 1 : 2)) % imagesBottom?.length);
    };

    const handlePrevimagesBottom = () => {
        setCurrentSetIndeximagesBottom(prevIndex => (prevIndex - (isMobile ? 1 : 2) + imagesBottom?.length) % imagesBottom?.length);
    };

    const data = dataaCategory?.map((item) => ({
        _id: item._id,
        name: (i18n.language === 'ar' ? item.name.ar : item.name.he),
        file: item.file
    })) || [];

    const btnAddToCart = () => {
        setShow(true)
    }

    return (<>
        <div style={{ display: "flex" }}>
            <div id="allPage" style={{ width: "100%", padding: "0", display: "flex", flexDirection: "row", justifyContent: "flex-end", marginTop: isMobile && "35px", alignItems: "flex-start" }}>
                <div style={{ width: "100%", display: 'flex', alignItems: "center", flexDirection: "column" }}>
                    <div style={{ width: "100%", minHeight: "100px", backgroundColor: "#ea004b", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                        <img src={`${img1}`} style={{ maxHeight: "70px", marginLeft: "50px" }}></img> </div>
                    <div id="Page" style={{ borderTop: "soled 1px black", padding: "0", borderRadius: '0' }}>
                        <div style={{ height: "40px", width: "100%", marginTop: "-25px", position: "sticky", top: isMobile ? "50px" : "70px", zIndex: "2", backgroundColor: "#ffffff", borderRadius: "20px", display: "flex", }}>
                            <InputGroup.Text style={{ backgroundColor: "white", borderLeft: "none", borderRadius: "0" }}>
                                <IoSearchSharp style={{ color: "#ea004b", fontSize: "20px" }} />
                            </InputGroup.Text>
                            <SearchBar />
                        </div>

                        {imagesTop?.length > 0 && (
                            <div style={{ margin: isMobile ? "20px 0" : "50px 0", maxWidth: "1410px", minHeight: "400px", display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                                <button
                                    onClick={handlePrev}
                                    style={{
                                        position: 'absolute',
                                        left: '0px',
                                        background: 'none',
                                        border: '1px solid',
                                        height: "auto",
                                        padding: "5px 10px 5px 10px",
                                        fontSize: '1rem',
                                        backgroundColor: "#ea004b",
                                        color: 'white',
                                        borderRadius: "10px",
                                        cursor: 'pointer'
                                    }}
                                >
                                    &gt;
                                </button>
                                <div style={{ width: "100%", height: "100%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <img
                                        src={`${process.env.REACT_APP_API_URL}/files/${imagesTop[currentSetIndex]?.file[0]}`}
                                        alt={`Advertisement ${currentSetIndex + 1}`}
                                        style={{
                                            maxWidth: isMobile ? "100%" : '50%',
                                            maxHeight: '400px',
                                            minWidth: '50%',
                                            objectFit: 'cover',
                                            opacity: 1,
                                            transition: 'opacity 1s ease-in-out',
                                            margin: '0 10px',
                                        }}
                                    />
                                    {!isMobile && (
                                        <img
                                            src={`${process.env.REACT_APP_API_URL}/files/${imagesTop[(currentSetIndex + 1) % imagesTop.length]?.file[0]}`}
                                            alt={`Advertisement ${(currentSetIndex + 2) % imagesTop.length + 1}`}
                                            style={{
                                                maxWidth: '50%',
                                                minWidth: '50%',
                                                maxHeight: '400px',
                                                objectFit: 'cover',
                                                opacity: 1,
                                                transition: 'opacity 1s ease-in-out',
                                                margin: '0 10px',
                                            }}
                                        />
                                    )}
                                </div>
                                <button
                                    onClick={handleNext}
                                    style={{
                                        position: 'absolute',
                                        right: '0px',
                                        background: 'none',
                                        border: '1px solid',
                                        padding: "5px 10px 5px 10px",
                                        fontSize: '1rem',
                                        backgroundColor: "#ea004b",
                                        color: 'white',
                                        borderRadius: "10px",
                                        cursor: 'pointer'
                                    }}
                                >
                                    &lt;
                                </button>
                            </div>
                        )}
                        {dataaCategory ? <>
                            <h2 style={{ width: "100%", maxWidth: "1410px", textAlign: "start" }}>{t("All categories")}</h2>
                            <div id="PageUlCategory" style={isMobile ? { minHeight: "30vh", width: "100%", justifyContent: "center" } : { marginTop: "40px", minHeight: "auto", width: "100%", maxWidth: "1410px" }}>
                                {data.map((item) =>
                                    <div class="card" style={{ width: "100px", margin: "1%", border: "none" }}>
                                        <Link to={`/GetCategoryMarket/${item.name}`}>
                                            <div >
                                                <img
                                                    src={`${process.env.REACT_APP_API_URL}/files/${item.file[0]}`}
                                                    class="card-img-top"
                                                    style={{ maxHeight: "100px" }}
                                                    alt="item image"
                                                />
                                                <div class="card-body">
                                                    <h5 class="card-title" style={{ textAlign: "center", fontSize: "13px" }}>
                                                        {item.name}
                                                    </h5>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                )}
                            </div>
                            {dataaOffers?.total != 0 && <><h2 style={{ width: "100%", maxWidth: "1410px", textAlign: "start" }}>{t("Offers")}</h2>
                                <div id="PageUlProduct">
                                    {dataaOffers?.doc?.map((item) =>
                                        <div class="card" style={{ width: "150px", margin: "1%", border: "none", borderRadius: "10px", maxHeight: "250px", minWidth: isMobile && "150px" }}>
                                            {(item.disable === "false" && item.number != 0) && <button title={t("Add to cart")} onClick={btnAddToCart} style={{ position: "absolute", marginTop: "5px", marginRight: "5px", width: "40px", height: "40px", color: "#ea004b", borderRadius: "50%", backgroundColor: "white", border: "none" }}>{<FaPlus style={{ fontSize: "20px" }} />}</button>}
                                            <Link onClick={() => { setShowProduct({ show: true, dataa: item, role: "visitor" }) }} style={{ margin: "1%" }} >
                                                <img src={`${process.env.REACT_APP_API_URL}/files/${item.file[0]}`} class="card-img-top" style={{ maxHeight: "150px" }} />
                                                <div class="card-body" style={{ textAlign: "center" }}>
                                                    {(item.number == 0 || item.disable === "true") && <div style={{ color: "red", fontWeight: "500" }}>{t("Out of stock")}</div>}
                                                    <h5 class="card-title" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>{item.newPricee && <div>{item.newPricee} ₪</div>} <div style={item.newPricee && { fontWeight: "400", fontSize: "13px", textDecoration: 'line-through', color: "#818181" }}>{item.price} ₪</div>  </h5>
                                                    {item.discount && <p class="card-text" style={{ textAlign: "center", backgroundColor: "#ea004b", borderRadius: "10px", color: "white" }}>{item.discount}% {t("Discount")}</p>}
                                                    <p class="card-text">{i18n.language == "ar" ? item.title.ar : item.title.he}</p>
                                                </div>
                                            </Link>
                                        </div>
                                    )}
                                    <div style={{ display: 'flex', justifyContent: "flex-start", marginRight: "2%", alignItems: isMobile ? "center" : 'baseline', width: "98%", flexDirection: isMobile && "column-reverse", }}>
                                        <Form.Select style={{ width: "80px", marginRight: isMobile ? "0" : "10px" }} onChange={(e) => setLimitOffers(e.target.value)}>
                                            {/* <option value={20}>20</option> */}
                                            {/* <option value={30}>30</option> */}
                                            <option value={50}>50</option>
                                            <option value={100}>100</option>
                                            <option value={150}>150</option>
                                        </Form.Select>
                                        <PaginatedItems total={dataaOffers?.total} itemsPerPage={limitOffers} setPage={setPageOffers} />
                                    </div>
                                </div>
                            </>}

                            {imagesBottom?.length > 0 && (
                                <div style={{ margin: isMobile ? "20px 0" : "50px 0", maxWidth: "1410px", minHeight: "400px", display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                                    <button
                                        onClick={handlePrevimagesBottom}
                                        style={{
                                            position: 'absolute',
                                            left: '0px',
                                            background: 'none',
                                            border: '1px solid',
                                            height: "auto",
                                            padding: "5px 10px 5px 10px",
                                            fontSize: '1rem',
                                            backgroundColor: "#ea004b",
                                            color: 'white',
                                            borderRadius: "10px",
                                            cursor: 'pointer'
                                        }}
                                    >
                                        &gt;
                                    </button>
                                    <div style={{ width: "100%", height: "100%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img
                                            src={`${process.env.REACT_APP_API_URL}/files/${imagesBottom[currentSetIndeximagesBottom]?.file[0]}`}
                                            alt={`Advertisement ${currentSetIndeximagesBottom + 1}`}
                                            style={{
                                                maxWidth: isMobile ? "100%" : '50%',
                                                maxHeight: '400px',
                                                minWidth: '50%',
                                                objectFit: 'cover',
                                                opacity: 1,
                                                transition: 'opacity 1s ease-in-out',
                                                margin: '0 10px',
                                            }}
                                        />
                                        {!isMobile && (
                                            <img
                                                src={`${process.env.REACT_APP_API_URL}/files/${imagesBottom[(currentSetIndeximagesBottom + 1) % imagesBottom.length]?.file[0]}`}
                                                alt={`Advertisement ${(currentSetIndeximagesBottom + 2) % imagesBottom.length + 1}`}
                                                style={{
                                                    maxWidth: '50%',
                                                    minWidth: '50%',
                                                    maxHeight: '400px',
                                                    objectFit: 'cover',
                                                    opacity: 1,
                                                    transition: 'opacity 1s ease-in-out',
                                                    margin: '0 10px',
                                                }}
                                            />
                                        )}
                                    </div>
                                    <button
                                        onClick={handleNextimagesBottom}
                                        style={{
                                            position: 'absolute',
                                            right: '0px',
                                            background: 'none',
                                            border: '1px solid',
                                            padding: "5px 10px 5px 10px",
                                            fontSize: '1rem',
                                            backgroundColor: "#ea004b",
                                            color: 'white',
                                            borderRadius: "10px",
                                            cursor: 'pointer'
                                        }}
                                    >
                                        &lt;
                                    </button>
                                </div>
                            )}
                            <h2 style={{ width: "100%", maxWidth: "1410px", textAlign: "start" }}>{t("All Products")}</h2>
                            <div id="PageUlProduct" style={{ display: 'flex' }}>
                                <div style={{ display: 'flex', width: "100%", justifyContent: "space-evenly", flexWrap: "wrap" }}>
                                    {dataaAllProduct?.doc?.map((item) =>
                                        <div class="card" style={{ width: "150px", margin: "1%", border: "none", borderRadius: "10px", maxHeight: "250px", minWidth: isMobile && "150px" }}>
                                            {(item.disable == "false" && item.number != 0) && <button title={t("Add to cart")} onClick={btnAddToCart} style={{ position: "absolute", marginTop: "5px", marginRight: "5px", width: "40px", height: "40px", color: "#ea004b", borderRadius: "50%", backgroundColor: "white", border: "none" }}>{<FaPlus style={{ fontSize: "20px" }} />}</button>}
                                            <Link onClick={() => { setShowProduct({ show: true, dataa: item, role: "visitor" }) }} style={{ margin: "1%" }} >
                                                <img src={`${process.env.REACT_APP_API_URL}/files/${item.file[0]}`} class="card-img-top" style={{ maxHeight: "150px" }} />
                                                <div class="card-body" style={{ textAlign: "center" }}>
                                                    {(item.number == 0 || item.disable === "true") && <div style={{ color: "red", fontWeight: "500" }}>{t("Out of stock")}</div>}
                                                    <h5 class="card-title" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>{item.newPricee && <div>{item.newPricee} ₪</div>} <div style={item.newPricee && { fontWeight: "400", fontSize: "13px", textDecoration: 'line-through', color: "#818181" }}>{item.price} ₪</div>  </h5>
                                                    {item.discount && <p class="card-text" style={{ textAlign: "center", backgroundColor: "#ea004b", borderRadius: "10px", color: "white" }}>{item.discount}% {t("Discount")}</p>}
                                                    <p class="card-text">{i18n.language == "ar" ? item.title.ar : item.title.he}</p>
                                                </div>
                                            </Link>
                                        </div>
                                    )}
                                </div>
                                <div style={{ display: 'flex', justifyContent: "flex-start", marginRight: "2%", alignItems: isMobile ? "center" : 'baseline', width: "98%", flexDirection: isMobile && "column-reverse", }}>
                                    <Form.Select style={{ width: "80px", marginRight: isMobile ? "0" : "10px" }} onChange={(e) => setLimit(e.target.value)}>
                                        {/* <option value={20}>20</option> */}
                                        {/* <option value={30}>30</option> */}
                                        <option value={50}>50</option>
                                        <option value={100}>100</option>
                                        <option value={150}>150</option>
                                    </Form.Select>
                                    <PaginatedItems total={dataaAllProduct?.total} itemsPerPage={limit} setPage={setPage} />
                                </div>
                            </div>
                        </> : <Loading />}
                        <ToastContainer >
                            <MsgToast setShow={setShow} body={t("You are not registered, please log in and shop immediately")} show={show} title={t("You are not registered")} type={"danger"} />
                        </ToastContainer >
                    </div>
                </div>
            </div>
            {isMobile ? null :
                <div style={{ minHeight: "100vh", height: "100%", width: "25%", maxWidth: "500px", marginTop: "65px" }}>
                    {<CartComponent />}
                </div>
            }
        </div>
        <ModalProduct show={showProduct.show} handleClose={() => setShowProduct(false)} dataa={showProduct.dataa} role={showProduct.role} />
    </>)
}

export default GetCategoryMarket