import axios from "axios"
import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import "./Articales.css"
import { Accordion, Button, Form, InputGroup, Nav, Offcanvas, Stack } from "react-bootstrap";
import { IoSearchSharp } from "react-icons/io5";
import { MdPriceChange } from "react-icons/md";
import { MdAddShoppingCart } from "react-icons/md";
import { useShoppingCart } from "../../context/shoppingCartContext"
import PaginatedItems from "../../components/pagination"
import { useTranslation } from "react-i18next";
import { FaPlus } from "react-icons/fa6";
import { ModalProduct } from "../../components/MsgComponent";
import { FaMinus } from "react-icons/fa";
import CartComponent from "../../components/CartComponent";
import { IoFilterSharp } from "react-icons/io5";

function AllArticales() {

    const { t, i18n } = useTranslation();
    let params = useParams()
    const nav = useNavigate()
    const [show, setShow] = useState(false)
    const [open, setOpen] = useState(false)


    const [search, setSearch] = useState("")
    const [priceCategory, setPriceCategory] = useState("")

    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(50)
    const start = (page - 1) * limit
    const end = Number(start) + Number(limit)
    // const final = dataa && dataa.slice(start, end)

    const [filterData, setFilterData] = useState()
    const dataSaerch = { search: search, category: params.category, priceCategory: priceCategory, page, limit }
    async function getSearchData() {
        await axios.post(`${process.env.REACT_APP_API_URL}/clineArticales/SearchProduct`, dataSaerch)
            .then((doc) => { setFilterData(doc.data) })
    }

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 500);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const debounce = setTimeout(() => {
            getSearchData()
        }, 800)
        return () => clearTimeout(debounce)
    }, [search, priceCategory, limit, page])

    const { getItemQuantity, increaseCartQuantity, decreaseCartQuantity } = useShoppingCart()

    const CloseFilter = () => {
        setOpen(false)
    }


    return (<>
        <div style={{ display: 'flex' }}>
            <div style={{ minHeight: "100vh", marginTop: isMobile ? "40px" : "85px", display: "flex", width: "100%" }}>
                <div style={{ width: "100%", padding: isMobile ? "0 2.5%" : "" }}>
                    <div style={{ width: "100%", backgroundColor: "white", margin: "10px 2%", borderRadius: "5px", display: 'flex', justifyContent: "center" }}>
                        {isMobile && <><button style={{ backgroundColor: "initial", border: "none" }} onClick={() => { setOpen(true) }}> <IoFilterSharp size={"30"} style={{}} /></button></>}
                        <div className="d-flex" style={{ width: isMobile ? "80%" : "50%" }}>
                            <Form className="d-flex search-bar" style={{ width: isMobile ? "100%" : "80%", margin: isMobile && "0 5px 5px 0", }}>
                                <InputGroup>
                                    <InputGroup.Text style={{ backgroundColor: "white", borderLeft: "none", borderRadius: "0" }}>
                                        <IoSearchSharp style={{ color: "#ea004b", fontSize: "20px" }} />
                                    </InputGroup.Text>
                                    <Form.Control
                                        type="search"
                                        placeholder={t("Search")}
                                        aria-label="Search"
                                        value={search}
                                        style={{ borderRadius: "0", borderRight: "none", margin: "0", padding: "0" }}
                                        onChange={(e) => setSearch(e.target.value)}
                                    />
                                </InputGroup>
                            </Form>
                        </div>
                    </div>
                    <div style={{ minHeight: "85vh", backgroundColor: "white", width: '100%', borderRadius: "5px" }}>
                        <div id="PageUlProduct"  >
                            {filterData?.movies?.map((item, index) =>
                                <div class="card" style={{ width: "150px", margin: "1%", border: "none", borderRadius: "10px", maxHeight: "250px" }}>
                                    {(item.disable == "false" && item.number != 0) && (getItemQuantity(item._id) == 0 ?
                                        <button title={t("Add to cart")} onClick={() => increaseCartQuantity(item)} className="btnPlass" style={{ display: "block", position: "absolute", marginTop: "5px", marginRight: "5px", width: "40px", height: "40px", color: "#ea004b", borderRadius: "50%", backgroundColor: "white", border: "none" }}>
                                            {<FaPlus style={{ fontSize: "20px" }} />}
                                        </button>
                                        :
                                        <div style={{ display: "flex", position: "absolute", marginTop: "5px", marginRight: "5px", justifyContent: 'space-between', width: "80px", backgroundColor: "white", borderRadius: '5px' }}>
                                            <div style={{ height: "10px" }}>
                                                <button style={{ border: "none", backgroundColor: "white", color: "#ea004b", borderRadius: '5px' }} onClick={() => decreaseCartQuantity(item)}>
                                                    <div className="btnPlass" ><FaMinus style={{ fontSize: "28px", padding: '8px' }} /></div>
                                                </button>
                                            </div>
                                            <div style={{ color: "#ea004b", fontWeight: "500", fontSize: "20px" }}>
                                                {getItemQuantity(item._id)}
                                            </div>
                                            <div>
                                                <button style={{ border: "none", backgroundColor: "white", color: "#ea004b", borderRadius: '5px' }} onClick={() => increaseCartQuantity(item)}>
                                                    <div className="btnPlass"><FaPlus style={{ fontSize: "28px", padding: '8px' }} /></div>
                                                </button>
                                            </div>
                                        </div>)}
                                    <button style={{ border: "none", backgroundColor: "white", minWidth: isMobile && "150px" }} onClick={() => { setShow({ show: true, dataa: item, role: "cline" }) }}>
                                        <img src={`${process.env.REACT_APP_API_URL}/files/${item.file[0]}`} class="card-img-top" style={{ maxHeight: "150px" }} />
                                        <div class="card-body" style={{ textAlign: "center", paddingBottom: "0" }}>
                                            {(item.number == 0 || item.disable === "true") && <div style={{ color: "red", fontWeight: "500" }}>{t("Out of stock")}</div>}
                                            <h5 class="card-title" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>{item.newPricee && <div>{item.newPricee} ₪</div>} <div style={item.newPricee && { fontWeight: "400", fontSize: "13px", textDecoration: 'line-through', color: "#818181" }}>{item.price} ₪</div>  </h5>
                                            {(item.number == 0 || item.disable == true) && <p style={{ textAlign: "start", width: "fit-content", padding: "0 10px", backgroundColor: "#ea004b", borderRadius: "10px", color: "white" }}>{item.discount}% {t("Discount")}</p>}
                                            <p class="card-text">{i18n.language == "ar" ? item.title.ar : item.title.he}</p>
                                        </div>
                                    </button>
                                </div>
                            )}
                        </div>
                        <div style={{ display: 'flex', justifyContent: "flex-start", marginRight: "2%", alignItems: 'baseline' }}>
                            <Form.Select style={{ width: "80px" }} onChange={(e) => setLimit(e.target.value)}>
                                {/* <option value={20}>20</option> */}
                                {/* <option value={30}>30</option> */}
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                                <option value={150}>150</option>
                            </Form.Select>
                            <PaginatedItems total={filterData && filterData.total} itemsPerPage={limit} setPage={setPage} />
                        </div>
                    </div>
                </div>
                <Offcanvas i18nIsDynamicList={true} show={open} onHide={CloseFilter} placement="end" style={{ width: '150px', height: '100%' }}>
                    <Offcanvas.Header closeButton>
                        {/* <Offcanvas.Title> asd</Offcanvas.Title> */}
                        <div className="ms-auto fw-bold fs-5" style={{ bottom: "50px", marginRight: "20%" }}>
                            {t("Filter")}
                        </div>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Stack >
                            <form onChange={(e) => setPriceCategory(e.target.value)} defaultValue={priceCategory}>
                                <input type="radio" id="All" name="Role" style={{ width: "20%" }} />
                                <label for={"All"} style={{ width: "80%" }}>{t("All")}</label>
                                <input type="radio" id="0-199" name="Role" value="0-199" style={{ width: "20%" }} />
                                <label for={"0-199"} style={{ width: "80%" }}>0 - 199</label>
                                <input type="radio" id="200-499" name="Role" value="200-499" style={{ width: "20%" }} />
                                <label for={"200-499"} style={{ width: "80%" }}>200 - 499</label>
                                <input type="radio" id="500-999" name="Role" value="500-999" style={{ width: "20%" }} />
                                <label for={"500-999"} style={{ width: "80%" }}>500 - 999</label>
                                <input type="radio" id="1000" name="Role" value="1000" style={{ width: "20%" }} />
                                <label for={"1000"} style={{ width: "80%" }}> {t("more than")} 1000</label>
                            </form>
                        </Stack>
                    </Offcanvas.Body>
                    <div style={{ width: "90%", margin: "0 5%" }}>
                        {/* <Button variant="outline-success" style={{ width: "100%", bottom: "0" }} onClick={"btnFinishMarket"}> asdsad</Button> */}
                    </div>
                </Offcanvas>
                <ModalProduct show={show.show} handleClose={() => setShow(false)} dataa={show.dataa} role={show.role} />
            </div>
            {isMobile ? null :
                <div style={{ minHeight: "100vh", height: "100%", width: "25%", maxWidth: "500px", marginTop: "65px" }}>
                    {<CartComponent />}
                </div>
            }
        </div>
    </>)
}


export default AllArticales