import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Loading } from '../../../refreshPage/loading';
import { Button } from 'react-bootstrap';
import { useShoppingCart } from '../../../context/shoppingCartContext';
import { useTranslation } from "react-i18next";
import CartComponent from '../../../components/CartComponent';
import RefundCard from '../RefundCard';
import axios from 'axios';

const DetalisOrder = () => {
    const { t, i18n } = useTranslation();
    const { reorderCartQuantity } = useShoppingCart();
    const [dataOrder, setDataOrder] = useState()
    const [statusBtn, setStatusBtn] = useState(t("Request Refund"))
    const nav = useNavigate();

    let params = useParams()

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/clineArticales/GetOrder/${params.id}`)
            .then((doc) => { setDataOrder(doc.data) })
            .catch((err) => console.log("err", err))
    }, [statusBtn])

    const Stages = {
        IN_PREPARATION: i18n.language === "ar" ? "قيد التجهيز" : "בהכנה",
        IN_THE_WAY: i18n.language === "ar" ? "في الطريق" : "בדרך",
        RECEIVED: i18n.language === "ar" ? "تم التسليم" : "קיבלו"
    };

    const stagesArray = [
        Stages.IN_PREPARATION,
        Stages.IN_THE_WAY,
        Stages.RECEIVED
    ];

    const BtnReOrder = () => {
        dataOrder.cart.map((item) => reorderCartQuantity({ data: item, id: item._id, NumQuantity: item.quantity }));
        nav("/cline/Articales/FinishMarket");
    };

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 500);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const isStageCompleted = (stage) => {
        const currentIndex = stagesArray.indexOf(i18n.language == "ar" ? dataOrder?.status.ar : dataOrder?.status.he);
        const stageIndex = stagesArray.indexOf(stage);
        return stageIndex <= currentIndex;
    };

    const orderStatus = () => {
        if (dataOrder.status.ar === "جديد") {
            return <span style={{ color: "red" }}>{t("Orders.Status.ok")}</span>;
        } else if (dataOrder.status.ar === "تم المشاهدة") {
            return <span style={{ color: "red" }}>{t("Orders.Status.ok")}</span>;
        } else if (dataOrder.status.ar === "قيد التجهيز") {
            return <span style={{ color: "red" }}>{t("Orders.Status.in preparation")}</span>;
        } else if (dataOrder.status.ar === "في الطريق") {
            return <span style={{ color: "red" }}>{t("Orders.Status.in the way")}</span>;
        } else if (dataOrder.status.ar === "تم التسليم") {
            return <span style={{ color: "green" }}>{t("Orders.Status.received")}</span>;
        } else if (dataOrder.status.ar === "الغاء") {
            return <span style={{ color: "green" }}>{t("the request has been canceled")}</span>;
        }
    };

    const DataPatchOrderRefund = { cancelOrder: true, status: { ar: "الغاء", he: "ביטול" }, dataOrder }

    const BtnRefundCash = async () => {
        setStatusBtn(t("Returning..."))
        await axios.patch(`${process.env.REACT_APP_API_URL}/clineArticales/PatchOrderRefund/${dataOrder._id}`, DataPatchOrderRefund)
            .then((doc) => {
                setTimeout(async () => {
                    setStatusBtn(t("Refund Successful"))
                }, 7000)
            })
            .catch((err) => console.log(err))
    }

    const dataCart = dataOrder?.cart?.map((i) => ({
        _id: i._id,
        title: i18n.language === "ar" ? i.title.ar : i.title.he,
        quantity: i.quantity,
        price: i.price,
        newPricee: i.newPricee,
    }));

    return (
        <div style={{ display: "flex" }}>
            <div id="allPage">
                <div id="PageAdmin">
                    {dataOrder ?
                        <div style={{ marginTop: "30px", border: "10px", width: "90%" }}>
                            <div style={{ display: "flex", justifyContent: "space-around", textAlign: "start", paddingBottom: "2%" }}>
                                <div>
                                    <div>{t("Name")} : {dataOrder.UserDetails.name}</div>
                                    <div>{t("Orders.Orderdetails.Order Id")} : {dataOrder._id}</div>
                                </div>
                                <div>
                                    <div>{t("CreateUser.Phone")} :  {dataOrder.UserDetails.phone}</div>
                                    <div>{t("Orders.Date Order")} : {dataOrder.DateOrder}</div>
                                </div>
                            </div>
                            <div style={{ borderTop: "1px solid #dbdada ", padding: "2% 0" }}>
                                <table className="table table-hover table-light" style={{ fontSize: "20px", height: "10px", width: "96%", backgroundColor: "white", marginLeft: "2%", borderRadius: "5px", boxShadow: "2px 2px 5px 0 rgb(219, 218, 218)" }}>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>{t("Orders.Orderdetails.item")}</th>
                                            <th>{t("Orders.Orderdetails.quantity")}</th>
                                            <th>{t("Price")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dataCart.map((item, index) => (
                                            <tr key={index} style={{ padding: "0" }}>
                                                <td>{index + 1}</td>
                                                <td>{item.title}</td>
                                                <td>{item.quantity}</td>
                                                <td style={{ height: "auto", display: "flex", flexDirection: 'column' }}>
                                                    {item.newPricee && <div style={{ textDecoration: 'line-through', color: "#818181" }}>
                                                        {item.price}₪
                                                    </div>}
                                                    <div>
                                                        {(item.newPricee || item.price)}₪
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div style={{ display: 'flex', color: 'red', borderTop: "1px solid #dbdada", justifyContent: "space-evenly", padding: "2% 0" }}>
                                <div>{t("Orders.Orderdetails.Total Price")} : {dataOrder.totalPrice} ₪</div>
                                <div>{t("Orders.Payment.Payment")} : {i18n.language === "ar" ? dataOrder.payment.ar : dataOrder.payment.he}</div>
                            </div>
                            <div style={{ borderTop: "1px solid #dbdada", padding: "2% 0" }}>{t("Orders.Orderdetails.Receipt")} : {i18n.language === "ar" ? dataOrder.receipt.ar : dataOrder.receipt.he}</div>
                            {<div style={{ borderTop: "1px solid #dbdada", paddingTop: "2%" }}>
                                <div style={{ textAlign: "start", marginLeft: "20%", marginRight: "20%", paddingBottom: "2px" }}>{t("Orders.Orderdetails.Receipt Address")} :</div>
                                <div style={{ border: "1px solid", borderRadius: "5px", width: "60%", margin: "0 20%", textAlign: "right", padding: "2%" }}>
                                    <div>
                                        {dataOrder.receiptAddress.city} ,
                                        {dataOrder.receiptAddress.district}
                                    </div>
                                    <div>
                                        {t("Orders.Orderdetails.Street")} {dataOrder.receiptAddress.street} ,
                                        {t("Orders.Orderdetails.Architecture")} {dataOrder.receiptAddress.architectureName} ,
                                        {t("Orders.Orderdetails.Floor")} {dataOrder.receiptAddress.floorNumber} ,
                                        {t("Orders.Orderdetails.Apartment")} {dataOrder.receiptAddress.apartmentNumber},
                                    </div>
                                </div>
                            </div>}
                            {dataOrder.status.ar != "الغاء" && <>
                                <div style={{ borderTop: "1px solid #dbdada", marginTop: "3%", paddingTop: "1%", fontSize: "18px" }}>
                                    {t("hedarAdmin.Status")} :
                                    {orderStatus()}
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: "3%" }}>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        {stagesArray.map((stage, index) => (
                                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '0 20px' }} key={index}>
                                                <StageCircle stage={stage} completed={isStageCompleted(stage)} text={stage} />
                                            </div>
                                        ))}
                                    </div>
                                </div></>}
                            {dataOrder.status.ar === "تم التسليم" && (
                                <div style={{ marginTop: "3%" }}>
                                    <Button bsPrefix className={"ButtonBNB"} style={{ width: "200px", height: "40px", bottom: "0" }} onClick={BtnReOrder} variant='success'>{t("Orders.Reorder")}</Button>
                                </div>
                            )}

                            {(dataOrder.status.ar === "جديد" || dataOrder.status.ar === "تم المشاهدة" || dataOrder.status.ar === "الغاء") ? (dataOrder.payment.ar === "بطاقة" ?
                                <div>
                                    <RefundCard Order={dataOrder} />
                                </div>
                                :
                                <div>
                                    {dataOrder.status.ar === "الغاء" ?
                                        <div style={{ color: "red", margin: "20px" }}>{t("the request has been canceled")}</div>
                                        :
                                        <Button bsPrefix className={"ButtonBNB"} onClick={BtnRefundCash} style={{ width: "150px", height: "40px", bottom: "0" }} >{statusBtn}</Button>
                                    }
                                </div>
                            )
                                :
                                <div>
                                    {dataOrder.status.ar != "تم التسليم" &&
                                        <div>
                                            طلبك {i18n.language == "ar" ? dataOrder.status.ar : dataOrder.status.he} ,لا يمكنك الغاء الطلب
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                        : <Loading />
                    }
                </div>
            </div>
            {isMobile ? null :
                <div style={{ minHeight: "100vh", height: "100%", width: "25%", maxWidth: "500px", marginTop: "65px" }}>
                    {<CartComponent />}
                </div>
            }
        </div>
    );
};

const StageCircle = ({ completed, text }) => (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '0 5px' }}>
        <div style={{
            width: '15px',
            height: '15px',
            borderRadius: '50%',
            backgroundColor: completed ? 'green' : 'white',
            border: "1px solid green",
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'white'
        }}></div>
        <p>{text}</p>
    </div>
);

export default DetalisOrder;