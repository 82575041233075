import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { Button } from 'react-bootstrap';
import { User } from '../../context/context';
import { useTranslation } from "react-i18next";


const RefundCard = (Order) => {
    const { t } = useTranslation();
    const [status, setStatus] = useState(t("Request Refund"));
    const [dataStatus, setDataStatus] = useState()
    const [err, setErr] = useState()
    const usernaw = useContext(User)
    const role = usernaw.auth.userDetals.role
    const token = usernaw.auth.token
    const dataOrder = Order.Order
    const paymentIntentid = Order.Order.paymentIntentid
    const id = Order.Order._id


    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/clineArticales/GetOrder/${id}`)
            .then((doc) => {
                setDataStatus(doc.data.status)
            })
    }, [status])


    const handleRefund = async () => {
        const newData = await axios.get(`${process.env.REACT_APP_API_URL}/clineArticales/GetOrder/${id}`)
        if (newData.data.status.ar === "جديد" || newData.data.status.ar === "تم المشاهدة" || role === "admin") {
            setStatus(t("Returning..."));
            await axios.post(`${process.env.REACT_APP_API_URL}/card/refund`, { paymentIntentid, dataStatus, dataOrder, role },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + token,
                    }
                })
                .then(async (doc) => {
                    const refundResponse = doc.data.id
                    const DataPatchOrderRefund = { refundOrderid: refundResponse, cancelOrder: true, status: { ar: "الغاء", he: "ביטול" }, dataOrder, role }

                    await axios.patch(`${process.env.REACT_APP_API_URL}/clineArticales/PatchOrderRefund/${id}`, DataPatchOrderRefund)
                        .then(async (doc) => {
                            await setStatus(t("Refund Successful"));
                            await setTimeout(async () => {
                                setStatus(t("Request Refund"))
                            }, 7000)
                        })
                        .catch((err) => {
                            setStatus(t("Refund Failed"));
                            console.log("Refund Error: ", err);
                            setTimeout(async () => {
                                setStatus(t("Request Refund"))
                            }, 7000)
                        })
                })
                .catch((err) => {
                    setStatus(t("Refund Failed"));
                    console.error("Refund Error: ", err);
                    setTimeout(async () => {
                        setStatus(t("Request Refund"))
                    }, 7000)
                })
        } else {
            setErr(t("Your request has been accepted and cannot be cancelled"))
        }
    };
    return (
        <>
            {dataStatus?.ar == "الغاء" ? <div style={{ color: "red" }}>{t("the request has been canceled")}</div>
                :
                ((dataStatus?.ar === "جديد" || dataStatus?.ar === "تم المشاهدة" || role ==="admin") ?
                    (!err &&<Button onClick={handleRefund} style={{ width: "150px" }} variant="danger">{status}</Button>)
                    :
                    "")
            }
            <div style={{ color: "red" }}>{err}</div>
        </>
    );
};

export default RefundCard;
