import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Button, Col, Modal, Toast, ToastContainer } from 'react-bootstrap';
import { Loading } from '../refreshPage/loading';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { MdAddShoppingCart } from "react-icons/md";
import { User } from '../context/context';
import { useShoppingCart } from '../context/shoppingCartContext';


const MsgToast = ({ setShow, show, type, title, body }) => {
    return (<>
        <Col xs={6} style={{ position: "fixed", textAlign: "start", top: "80px", right: "40px" }}>
            <Toast bg={type.toLowerCase()} onClose={() => setShow(false)} show={show} delay={4000} autohide>
                <Toast.Header style={{ display: "flex", justifyContent: "space-between" }} >
                    <img
                        src="holder.js/20x20?text=%20"
                        className="rounded me-2"
                        alt=""
                    />
                    <strong > {title}</strong>
                    <small></small>
                </Toast.Header>
                <Toast.Body style={{ color: "white" }}> {body}</Toast.Body>
            </Toast>
        </Col>
    </>)
}

const MsgModal = ({ handleClose, opj, show, title, body, BtnState }) => {
    // const [status, setStatus] = useState("نعم ,متأكد")
    const { t } = useTranslation();

    return (<>
        <Modal show={show} onHide={handleClose}>
            <Modal.Header style={{ display: "flex", justifyContent: "space-between" }} closeButton>
                <Modal.Title>{title}</Modal.Title>
                {/* <Button variant="close" onClick={handleClose} /> */}
            </Modal.Header>
            <Modal.Body> {body}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {t("Close")}
                </Button>
                <Button variant="success" onClick={opj}>
                    {BtnState}
                </Button>
            </Modal.Footer>
        </Modal>
    </>)
}

const MsgModalAnOffer = ({ handleClose, show, dataa }) => {

    const usernaw = useContext(User)
    const token = usernaw.auth.token

    const DeleteAnOffer = async () => {
        await axios.delete(`${process.env.REACT_APP_API_URL}/articales/DeleteAnOffer/${dataa._id}`, {
            headers: {
                Authorization: "Bearer " + token,
            }
        })
            .then(() => { handleClose() })
            .then(() => window.location.reload())
    }
    const { t } = useTranslation();

    return (<>
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{ }</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <img src={`${process.env.REACT_APP_API_URL}/files/${dataa?.file[0]}`} class="card-img-top" style={{ maxHeight: "500px", maxWidth: "500px" }} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {t("Close")}
                </Button>
                <Button bsPrefix className={"ButtonBNB"} onClick={DeleteAnOffer} style={{ width: "100px" }} >
                    {t("Delete")}
                </Button>
            </Modal.Footer>
        </Modal>
    </>)
}

const ModalProduct = ({ handleClose, show, dataa, role }) => {
    const { t, i18n } = useTranslation();
    const { getItemQuantity, increaseCartQuantity, decreaseCartQuantity, removeFromCart } = useShoppingCart()
    const quantity = getItemQuantity(dataa?._id)
    const context = useContext(User)
    const token = context.auth.token
    const nav = useNavigate()
    const [showMsg, setShowMsg] = useState(false);

    const handleCheck = async (e) => {
        const isChecked = e?.target.checked;

        if (isChecked) {
            await axios.patch(`${process.env.REACT_APP_API_URL}/articales/PatchArticale/${dataa?._id}`, { disable: true }, {
                headers: {
                    Authorization: "Bearer " + token,
                    "Content-Type": "multipart/form-data"
                }
            }).then(() => nav("/admin/Home/Home4"));

        } else {
            await axios.patch(`${process.env.REACT_APP_API_URL}/articales/PatchArticale/${dataa?._id}`, { disable: false }, {
                headers: {
                    Authorization: "Bearer " + token,
                    "Content-Type": "multipart/form-data"
                }
            }).then(() => nav("/admin/Home/Home4"));
        }
    }

    const btnAddToCart = () => {
        setShowMsg(true)
    }
    const btndelete = async function deletee() {
        await axios.delete(`${process.env.REACT_APP_API_URL}/articales/DeleteArticale/${dataa._id}`, {
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
            }
        })
            .then(() => handleClose())
            .then(() => { nav("/admin/Home/Home4") })
            .catch((err) => console.log("err delete : ", err))
    }

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 500);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const btnUpDateArticale = () => {
        nav(`/admin/market/UpDateArticale/${dataa._id}`, { state: { dataa } })
    }

    return (<>
        <Modal size="xl" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                {dataa ?
                    <div>
                        <div style={{ width: "100%", display: isMobile ? null : "flex" }}>
                            <div id="carouselExampleCaptions" style={{ width: isMobile ? "100%" : "50%" }} class="carousel slide">
                                <div class="carousel-indicators">
                                    {dataa.file && dataa.file.map((item, index) => (
                                        <button
                                            key={index}
                                            type="button"
                                            data-bs-target="#carouselExampleCaptions"
                                            data-bs-slide-to={index}
                                            style={{ backgroundColor: "#ea004b" }}
                                            className={index === 0 ? "active" : ""}
                                            aria-current={index === 0 ? "true" : "false"}
                                            aria-label={`Slide ${index + 1}`}
                                        ></button>
                                    ))}
                                </div>

                                <div class="carousel-inner">
                                    {dataa.file && dataa.file.map((item, index) => (
                                        <div key={index} className={`carousel-item ${index === 0 ? "active" : ""}`}>
                                            <img src={`${process.env.REACT_APP_API_URL}/files/${item}`} style={{ width: "100%", maxWidth: "500px" }} className="d-block w-100" alt={`Slide ${index + 1}`} />
                                            <div className="carousel-caption d-none d-md-block">
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Previous</span>
                                </button>

                                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Next</span>
                                </button>
                            </div>
                            <div style={{ width: "50%", display: 'flex', flexDirection: "column", justifyContent: "center", marginRight: "5%" }} className="liUserDetalse">
                                <div style={{ width: "fit-content", marginTop: isMobile && "20px" }}>
                                    {dataa.discount && <p class="card-text" style={{ textAlign: "center", padding: "0 10px", backgroundColor: "#ea004b", borderRadius: "10px", color: "white" }}>{dataa.discount}% {t("Discount")}</p>}
                                </div>
                                <li id="liUserId" style={{ fontSize: "30px" }}> {i18n.language == "ar" ? dataa.title.ar : dataa.title.he}</li>
                                <h5 class="card-title" style={{ display: "flex", justifyContent: "flex-start", gap: "5px", alignItems: "center", width: isMobile ? "80%" : "35%" }}>{dataa.newPricee && <div>{dataa.newPricee} ₪</div>} <div style={dataa.newPricee && { fontWeight: "400", fontSize: "13px", textDecoration: 'line-through', color: "#818181" }}>{dataa.price} ₪</div>  </h5>
                                {/* <li id="liUserId" style={{ fontSize: "25px" }}> {dataa.price} <div style={{ fontSize: "25px" }}> $ </div></li> */}
                            </div>
                        </div>
                        <div style={{ borderTop: " 1px  solid #e1e1e1", margin: "2% 0" }}>
                            <li id="liUserId" style={{ fontSize: "30px" }}><div style={{ fontSize: "25px" }}> {t("ProductId.information")} : </div> {i18n.language == "ar" ? dataa.information.ar : dataa.information.he}</li>
                        </div>
                        <div style={{ borderTop: " 1px  solid #e1e1e1", margin: "2% 0" }}>
                            <li id="liUserId" style={{ fontSize: "30px" }}><div style={{ fontSize: "25px" }}> {t("ProductId.Existing quantity")} : </div> {dataa.number}</li>
                        </div>
                    </div>
                    : <Loading />
                }
            </Modal.Body>
            <Modal.Footer style={{ display: 'flex', justifyContent: "center" }}>
                {role == "admin" ?
                    <>
                        <button type="submit" style={{ margin: "0 2%" }} class="btn btn-success" onClick={btnUpDateArticale}> {t("ProductId.Up Date Product")}</button>
                        <button type="submit" style={{ margin: "0 2%" }} class="btn btn-danger" onClick={btndelete}> {t("ProductId.Delete Product")}</button>
                        <div className="form-check form-switch" style={{ width: "150px", marginLeft: '50px' }}>
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="flexSwitchCheckDefault"
                                defaultChecked={(dataa?.disable == "true")}
                                onClick={handleCheck}
                                style={{ width: '50px', height: '20px' }}
                            />
                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                                {t("Disable the product")}
                            </label>
                        </div>
                    </>
                    :
                    dataa?.number != 0 &&
                    (role == "visitor" ? (dataa?.disable === "false" ?
                        <Button bsPrefix className={"ButtonBNB"} style={{ width: "200px", height: "40px", bottom: "0" }} onClick={btnAddToCart} >{<MdAddShoppingCart style={{ fontSize: "20px" }} />}{t("Add to cart")}</Button>
                        :
                        <div style={{ color: "red", fontWeight: "500" }}>{t("Out of stock")}</div>
                    )
                        :
                        <div class="col-12" style={{ display: "flex", justifyContent: "center" }}>
                            {dataa?.disable === "false" ? (quantity === 0 ? (
                                <Button bsPrefix className={"ButtonBNB"} style={{ width: "200px", height: "40px", bottom: "0" }} class="btn btn-success" onClick={() => increaseCartQuantity(dataa)} >{<MdAddShoppingCart style={{ fontSize: "20px" }} />}{t("Add to cart")}</Button>)
                                :
                                (<div className="d-flex align-items-center flex-column" >
                                    <div className="d-flex align-items-center justify-content-center" style={{ gap: "10px" }}>
                                        <Button bsPrefix className={"ButtonBNB"} style={{ width: "40px", height: "40px", bottom: "0" }} onClick={() => decreaseCartQuantity(dataa)}>-</Button>
                                        <span className="fs-3" style={{ width: "130px" }}> {quantity} {t("in Cart")}</span>
                                        <Button bsPrefix className={"ButtonBNB"} style={{ width: "40px", height: "40px", bottom: "0" }} onClick={() => increaseCartQuantity(dataa)}>+</Button>
                                    </div>
                                    <Button variant='danger' style={{ width: "200px", height: "40px", bottom: "0", marginTop: "20px" }} onClick={() => removeFromCart(dataa._id)}>{t("ProductId.Remove")}</Button>
                                </div>))
                                :
                                <div style={{ color: "red", fontWeight: "500" }}>{t("Out of stock")}</div>
                            }
                        </div>)
                }
                <Button variant="secondary" onClick={handleClose}>
                    {t("Close")}
                </Button>
            </Modal.Footer>
        </Modal>
        <ToastContainer >
            <MsgToast setShow={setShowMsg} body={t("You are not registered, please log in and shop immediately")} show={showMsg} title={t("You are not registered")} type={"danger"} />
        </ToastContainer >
    </>)
}


export { MsgToast, MsgModal, ModalProduct, MsgModalAnOffer } 