import { Link, NavLink, useNavigate } from "react-router-dom";
import './heder.css'
import React, { useContext, useEffect, useState } from "react";
import { User } from "../../context/context";
import Cookies from "universal-cookie";
import axios from "axios";
import { isExpired } from "react-jwt";
import { FaUsers } from "react-icons/fa6";
import { TbCategoryPlus } from "react-icons/tb";
import { MdClass } from "react-icons/md";
import { BiSolidOffer } from "react-icons/bi";
import { FaTasks } from "react-icons/fa";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { Badge, Form } from "react-bootstrap";
import { MdNotificationsActive } from "react-icons/md";
import moment from 'moment';
import 'moment/locale/ar';
import 'moment/locale/he';
import { useTranslation } from "react-i18next";
import img2 from "../../file/666555.png"
import img1 from "../../file/images.png"
import { IoMdMenu } from "react-icons/io";

const setMomentLanguage = (language) => {
    if (language === 'ar') {
        moment.locale('ar');
    } else {
        moment.locale('he');
    }
};

function HederAdmin() {
    const { t, i18n } = useTranslation();
    setMomentLanguage(i18n.language);
    const usernaw = useContext(User)
    const UserId = usernaw.auth.userDetals._id
    const UserName = usernaw.auth.userDetals.user
    const cookie = new Cookies()
    const nav = useNavigate()

    useEffect(() => {
        document.body.dir = i18n.dir()
    }, [])
    const BtnLanguge = (lan) => (
        i18n.changeLanguage(lan),
        window.location.reload()
    )

    const [dataOrders, setDataOrders] = useState()

    useEffect(() => {
        const ContextToken = usernaw.auth.token
        const IsExpiredContextToken = isExpired(ContextToken)
        const getTokenCookie = cookie.get("bearer")
        const token = getTokenCookie && getTokenCookie.token ? getTokenCookie.token : ""
        const RefreshTokenCookie = getTokenCookie && getTokenCookie.refreshToken ? getTokenCookie.refreshToken : ''
        if (IsExpiredContextToken) {
            axios.post(`${process.env.REACT_APP_API_URL}/auth/refreshToken`, null, {
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + RefreshTokenCookie,
                }
            }).then(async (doc) => {
                const newtoken = doc.data.token
                const newuserDetals = doc.data.data
                const newdecoded = doc.data.decoded
                await cookie.remove("bearer")
                await cookie.set("bearer", { token: token, refreshToken: RefreshTokenCookie, userDetals: newuserDetals, decoded: newdecoded }, { path: "/" })
                await usernaw.setAuth({ token: newtoken, userDetals: newuserDetals, decoded: newdecoded })
            }).catch((err) => console.log("err refreshPage : ", err))
        }
    });

    const btnLogout = async () => {
        const getTokenCookie = cookie.get("bearer")
        const token = getTokenCookie.token
        const RefreshTokenCookie = getTokenCookie.refreshToken
        await axios.get(`${process.env.REACT_APP_API_URL}/auth/logout`,
            {
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + token,
                    token: token,
                    refreshToken: RefreshTokenCookie,
                }
            }
        ).then(async () => {
            await cookie.remove("bearer")
            await cookie.remove("refreshToken")
            await usernaw.setAuth({ userDetals: "", token: "" })
            nav("/")
        })
            .catch((err) => {
                console.log("err : ", err)
            })
    }
    const btnMyAccount = () => {
        nav("/admin/user/GetUserId")
    }
    const btnChangePassword = () => {
        nav(`/admin/user/ChangePassword/${UserId}`)
    }
    const getNotificationsOrders = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/clineArticales/GetOrder`)
            .then((doc) => setDataOrders(doc.data))
            .catch((err) => console.log("err : ", err))
    }

    const filterDataNew = dataOrders && dataOrders.filter((item) =>
        i18n.language == "ar" ? item.status?.ar.toLowerCase().includes("جديد") : i18n.language == "he" ? item.status?.he.toLowerCase().includes("חָדָשׁ") : ""
    )
    const NumberOrders = filterDataNew && filterDataNew.length

    useEffect(() => {
        const debounce = setTimeout(() => {
            getNotificationsOrders()
        }, 800)
        return () => clearTimeout(debounce)
    })
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 500);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    const BtnDropdownButton = async (status) => {
        const LStatus = JSON.parse(status);
        await axios.patch(`${process.env.REACT_APP_API_URL}/clineArticales/patchorder`, { LStatus })
            .then((doc) => { })
            .catch((err) => console.log("err Patch : ", err))
    }

    const BtnDetalisOrderAdmin = (id) => {
        nav(`/admin/market/orders/DetalisOrderAdmin/${id}`);
    };

    const BtnAllOreders = () => {
        nav('/admin/market/orders/allorders')
    }
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const start = (page - 1) * limit
    const end = Number(start) + Number(limit)

    const data = dataOrders?.map((i) => ({
        status: i18n.language == "ar" ? i.status.ar : i.status.he,
        DateOrder: i.DateOrder,
        UserDetails: i.UserDetails,
        cart: i.cart,
        createdAt: i.createdAt,
        payment: i.payment,
        receipt: i.receipt,
        receiptAddress: i.receiptAddress,
        totalPrice: i.totalPrice,
        _id: i._id
    }))
    const handleLink = (Link) => {
        nav(`${Link}`)
    }

    return (
        <nav>
            {isMobile ?
                <div className="App" style={{ width: "100%", position: "fixed", zIndex: "6" }}><div>
                    <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "flex-start", width: "100%", position: "fixed" }} >
                        <div style={{ width: "100%", top: "0", padding: "0", backgroundColor: "white", boxShadow: "0 0 8px #898989 ", display: "flex", justifyContent: "space-between", position: "fixed", minHeight: "45px", alignItems: 'center' }}>
                            <header style={{ display: "flex", minHeight: "45px" }}>
                                <DropdownButton align={"start"} bsPrefix="custom-dropdown-button-mobail-hedar" style={{ textAlign: "start" }} id="dropdown-basic-button" title={<IoMdMenu style={{ minWidth: "110px", height: "30px" }} />}>
                                    <Dropdown.Header style={{ textAlign: "center", fontWeight: "600", backgroundColor: "#e9e9e9" }}>{t("Menu")}</Dropdown.Header>
                                    <Dropdown.Item onClick={() => handleLink("/admin/Home/Home2")} style={{ textAlign: "start" }} >{t("Users")}</Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleLink("/admin/Home/Home4")} style={{ textAlign: "start" }} >{t("Market")}</Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleLink("/admin/market/CreateCategory")} style={{ textAlign: "start" }} >{t("hedarAdmin.Create Category")}</Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleLink("/admin/market/CreateArticales")} style={{ textAlign: "start" }} >{t("hedarAdmin.Create Product")}</Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleLink("/admin/market/CreateAnOffer")} style={{ textAlign: "start" }} >{t("hedarAdmin.Create Offer")}</Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleLink("/admin/market/orders/allorders")} style={{ textAlign: "start" }} >{t("hedarAdmin.All Orders")}</Dropdown.Item>
                                    <Dropdown.Header style={{ textAlign: "center", fontWeight: "600", backgroundColor: "#e9e9e9" }}>Account</Dropdown.Header>
                                    <Dropdown.Item onClick={btnMyAccount} style={{ textAlign: "start" }} eventKey="1">{t("hedarAdmin.my Account")}</Dropdown.Item>
                                    <Dropdown.Item onClick={btnChangePassword} style={{ textAlign: "start" }} eventKey="2">{t("hedarAdmin.Change Password")}</Dropdown.Item>
                                    <Dropdown.Divider />
                                    <Dropdown.Item onClick={btnLogout} style={{ color: 'red', textAlign: "start" }} eventKey="4">{t("Logout")}</Dropdown.Item>
                                </DropdownButton>
                            </header>
                            <div style={{ display: 'flex', margin: "0 20px" }}>
                                <Form.Select style={{
                                    paddingLeft: "40px",
                                    paddingRight: "20px",
                                    width: "80px",
                                    height: "35px",
                                    appearance: "none",
                                    background: `url(${img1}) no-repeat 10px center`,
                                    backgroundSize: "30px 30px, 100%",
                                    backgroundColor: "#ffffff",
                                    textAlign: "center"
                                }} className="language-selector" onChange={(e) => BtnLanguge(e.target.value)} value={i18n.language}>
                                    {/* <option>en</option> */}
                                    <option value={"ar"}>AR</option>
                                    <option value={"he"}>HE</option>
                                </Form.Select>
                            </div>
                            <div style={{ margin: isMobile ? "2px 10px" : "2px 5px" }}>
                                <DropdownButton
                                    size="xl"
                                    bsPrefix="custom-dropdown-button"
                                    // className="custom-dropdown-button"
                                    title={<>
                                        <Badge bg="#ff2222" style={{ position: "absolute", top: "-2px", right: "80%", backgroundColor: 'red' }}>
                                            {NumberOrders == 0 ?
                                                ""
                                                : NumberOrders}
                                        </Badge>
                                        <MdNotificationsActive style={{ paddingBottom: "3px", fontSize: "32px" }} />
                                    </>}
                                    onClick={() => BtnDropdownButton(JSON.stringify({ ar: "جديد", he: "חָדָשׁ" }))}
                                >
                                    {data && data.map((item) => (
                                        <Dropdown.Item style={{ maxWidth: "100%" }} eventKey="2" onClick={() => BtnDetalisOrderAdmin(item._id)}>
                                            <div style={{ maxWidth: "10%", wordWrap: "break-word", overflowWrap: "break-word" }}>
                                                {t("hedarAdmin.Order from")} <span style={{ fontWeight: "600" }}> {item.UserDetails.name} </span> ,{t("hedarAdmin.Status")} : {item.status == "received" ? <span style={{ color: '#198754', fontWeight: "500" }}>{item.status}</span> : <span style={{ color: 'red' }}>{item.status}</span>}
                                                <div style={{ fontSize: '13px' }}>
                                                    {moment(item.createdAt).fromNow()}
                                                </div>
                                            </div>
                                        </Dropdown.Item>
                                    ))}
                                    <Dropdown.Divider />
                                    <Dropdown.Item onClick={BtnAllOreders} style={{ textAlign: "start" }} eventKey="2">{t("hedarAdmin.All notifications")} </Dropdown.Item>
                                </DropdownButton>
                            </div>


                        </div>
                    </div>
                </div>
                </div>
                :
                <div style={{ display: "flex", justifyContent: "center", position: "fixed", width: "100%", top: "0", zIndex: "3", minHeight: "64px", maxHeight: "64px", padding: "0", backgroundColor: "white", boxShadow: "0 0 8px #898989 " }}>
                    <div className="HederExperienceActive" style={{ width: "100%", padding: "0 30px", maxWidth: "1440px", minHeight: "64px", display: "flex", alignItems: 'center', justifyContent: "space-between" }}>
                        <div style={{ minHeight: "64px", display: "flex", alignItems: 'center' }}>
                            <div style={{ display: "flex", alignItems: 'center' }}>
                                <NavLink to={"/admin/Home/Home4"} activeClassName="active-link" style={{ margin: "20px" }} ><img style={{ maxHeight: "40px", marginTop: "10px" }} src={img2}></img></NavLink>
                                <NavLink to={"/admin/Home/Home2"} className="custom-button" ><div><FaUsers style={{ margin: "0 5px" }} />{t("Users")}</div></NavLink>
                                <NavLink to={"/admin/market/CreateCategory"} className="custom-button" ><div><TbCategoryPlus style={{ margin: "0 5px" }} />{t("hedarAdmin.Create Category")}</div></NavLink>
                                <NavLink to={"/admin/market/CreateArticales"} className="custom-button" ><div><MdClass style={{ margin: "0 5px" }} />{t("hedarAdmin.Create Product")}</div></NavLink>
                                <NavLink to={"/admin/market/CreateAnOffer"} className="custom-button" ><div><BiSolidOffer style={{ margin: "0 5px", fontSize: "18px" }} />{t("hedarAdmin.Create Offer")}</div></NavLink>
                                <NavLink to={"/admin/market/orders/allorders"} className="custom-button" ><div><FaTasks style={{ margin: "0 5px" }} />{t("hedarAdmin.All Orders")}</div></NavLink>
                            </div>
                        </div>
                        <div style={{ display: "flex", alignItems: 'center' }}>
                            <div className="unitMenu " style={{ backgroundColor: "white", color: "#ea004b", display: "flex", alignItems: "center", justifyContent: "center", minWidth: "110px", minHeight: "64px", maxHeight: "64px" }}>
                                <DropdownButton
                                    // as={ButtonGroup}
                                    size="sm"
                                    variant="liht"
                                    style={{ backgroundColor: "white", minWidth: "88px" }}
                                    title={
                                        <span style={{ color: "#ea004b", padding: "0 4px" }}>
                                            <i className="fa fa-user" style={{ fontSize: "20px", color: "#ea004b", padding: "0 4px" }}></i> {UserName}
                                        </span>
                                    }
                                >
                                    <Dropdown.Item onClick={btnMyAccount} style={{ textAlign: "start" }} eventKey="1">{t("hedarAdmin.my Account")}</Dropdown.Item>
                                    <Dropdown.Item onClick={btnChangePassword} style={{ textAlign: "start" }} eventKey="2">{t("hedarAdmin.Change Password")}</Dropdown.Item>
                                    <Dropdown.Divider />
                                    <Dropdown.Item onClick={btnLogout} style={{ color: 'red', textAlign: "start" }} eventKey="4">{t("Logout")}</Dropdown.Item>
                                </DropdownButton>
                            </div>
                            <div style={{ margin: "2px 5px", border: "none" }}>
                                <DropdownButton
                                    size="sm"
                                    bsPrefix="custom-dropdown-button"
                                    // className={"ButtonBNB"}
                                    style={{ border: "none" }}

                                    // className="custom-dropdown-button"
                                    title={<>
                                        <Badge bg="#ff2222" style={{ position: "absolute", top: "-5px", right: "80%", backgroundColor: 'red' }}>
                                            {NumberOrders != 0 && NumberOrders}
                                        </Badge>
                                        <MdNotificationsActive style={{ paddingBottom: "3px", fontSize: "22px", border: "none" }} />
                                    </>}
                                    onClick={() => BtnDropdownButton(JSON.stringify({ ar: "جديد", he: "חָדָשׁ" }))}
                                >
                                    {data && data.map((item) => (
                                        <Dropdown.Item style={{ maxWidth: "100%" }} eventKey="2" onClick={() => BtnDetalisOrderAdmin(item._id)}>
                                            <div style={{ maxWidth: "10%", wordWrap: "break-word", overflowWrap: "break-word" }}>
                                                {t("hedarAdmin.Order from")} <span style={{ fontWeight: "600" }}> {item.UserDetails.name} </span> ,{t("hedarAdmin.Status")} : {item.status == "تم التسليم" ? <span style={{ color: '#198754', fontWeight: "500" }}>{item.status}</span> : <span style={{ color: 'red' }}>{item.status}</span>}
                                                <div style={{ fontSize: '13px' }}>
                                                    {moment(item.createdAt).fromNow()}
                                                </div>
                                            </div>
                                        </Dropdown.Item>
                                    ))}
                                    <Dropdown.Divider />
                                    <Dropdown.Item onClick={BtnAllOreders} style={{ textAlign: "start" }} eventKey="2">{t("hedarAdmin.All notifications")} </Dropdown.Item>
                                </DropdownButton>
                            </div>
                            <div style={{ display: 'flex', margin: "0 20px" }}>
                                <Form.Select style={{
                                    paddingLeft: "40px",
                                    paddingRight: "20px",
                                    width: "80px",
                                    height: "35px",
                                    appearance: "none",
                                    background: `url(${img1}) no-repeat 10px center`,
                                    backgroundSize: "30px 30px, 100%",
                                    backgroundColor: "#ffffff",
                                    textAlign: "center"
                                }} className="language-selector" onChange={(e) => BtnLanguge(e.target.value)} value={i18n.language}>
                                    {/* <option>en</option> */}
                                    <option value={"ar"}>AR</option>
                                    <option value={"he"}>HE</option>
                                </Form.Select>
                            </div>
                            {/* <div style={{display:'flex'}}>
                                <Button variant="outline-liht" className="Button-hedear" onClick={() => openCart()}>{<TbShoppingBag style={{ fontSize: "25px", color: "#ea004b" }} />}<div style={{}}>{cartQuantity != 0 && cartQuantity}</div>  </Button>
                            </div> */}
                        </div>
                    </div>
                </div>}

        </nav>
    )
}

export default HederAdmin;

