import React, { useContext, useEffect, useState } from "react";
import { useShoppingCart } from "../../context/shoppingCartContext";
import axios from "axios";
import './Articales.css';
import { Accordion, Button, Stack, Tab, Tabs, Nav, ProgressBar } from "react-bootstrap";
import CartItem from "./CartItem";
import { User } from "../../context/context";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MsgModal, MsgToast } from "../../components/MsgComponent";
import { FaRegCreditCard } from "react-icons/fa6";
import { MdDeliveryDining } from "react-icons/md";
import { IoStorefront } from "react-icons/io5";
import { FaMoneyBillWave } from "react-icons/fa";
import { BsDeviceSsdFill } from "react-icons/bs";
import { LoadingBtn } from "../../refreshPage/loading";

const FinishMarket = () => {
    const { t } = useTranslation();
    const { cartItems, removeAllCart, cartQuantity } = useShoppingCart()
    const usernaw = useContext(User)
    const userId = usernaw.auth.userDetals._id

    const address = usernaw.auth.userDetals.address
    const token = usernaw.auth.token
    const nav = useNavigate()

    const [statusBtn, setStatusBtn] = useState(t("Pay"))
    const [dataaArticales, setDataaArticales] = useState()
    const [city, setCity] = useState()
    const [tap, setTab] = useState("address")
    const [district, setDistrict] = useState()
    const [street, setStreet] = useState()
    const [architectureName, setArchitectureName] = useState()
    const [apartmentNumber, setApartmentNumber] = useState()
    const [floorNumber, setFloorNumber] = useState()
    const [additionalDetails, setAdditionalDetails] = useState()
    const [payment, setPayment] = useState({ ar: "كاش", he: "כסף מזומן" })
    const [receipt, setReceipt] = useState({ he: "מְסִירָה", ar: "دلفري" })
    const [msgDeleteAddress, setMsgDeleteAddress] = useState(false)
    const [idAddress, setIdAddress] = useState()
    const [err, setErr] = useState()

    const [show, setShow] = useState(false);

    const [getPriceDelevary, setGetPriceDelevary] = useState()

    const PriceFreeDelivery = getPriceDelevary?.priceDelevaryOffer

    const now1 = cartItems.reduce((total, cartitem) => {
        const item = dataaArticales && dataaArticales.find((i) => i._id === cartitem.id);
        const itemTotal = (item?.newPricee || item?.price || 0) * cartitem.quantity;
        return total + itemTotal;
    }, 0);

    const discountPercentage = ((now1 / PriceFreeDelivery) * 100) >= 100 ? 100 : (now1 / PriceFreeDelivery) * 100;
    const now = discountPercentage.toFixed(0)

    const [selectedItemId, setSelectedItemId] = useState();

    const handleChange = (selectedId) => {
        setSelectedItemId(selectedId);
    }
    const handleChange3 = (selected) => {
        const selectedValue = JSON.parse(selected);
        setPayment(selectedValue);
    }
    const handleChange4 = (selected) => {
        const selectedValue = JSON.parse(selected);
        setReceipt(selectedValue);
    }

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/clineArticales/GetAnOfferPriceDelevary`)
            .then((doc) => { setGetPriceDelevary(doc.data) })
            .catch((err) => console.log(err))
    }, [show, msgDeleteAddress])

    useEffect(() => {
        GetProduct()
        // HandleDisabledBtnPay()
        setErr()
    }, [show, cartItems, selectedItemId, receipt.ar, msgDeleteAddress])

    const GetProduct = (async () => {
        await axios.get(`${process.env.REACT_APP_API_URL}/clineArticales/GetArticales`)
            .then((doc) => setDataaArticales(doc.data))
            .catch((err) => console.log("err Get : ", err))
    })

    const dataAddressPost = {
        city: city,
        district: district,
        street: street,
        architectureName: architectureName,
        apartmentNumber: apartmentNumber,
        floorNumber: floorNumber,
        additionalDetails: additionalDetails
    }

    const handleChange1 = (e) => {
        const selectedValue = JSON.parse(e.target.value);
        setPayment(selectedValue);
    };
    const handleChange2 = (e) => {
        const selectedValue = JSON.parse(e.target.value);
        setReceipt(selectedValue);
    };

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 500);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const btnAddAddress = async () => {
        await axios.post(`${process.env.REACT_APP_API_URL}/users/addaddress/${userId}/address`, dataAddressPost,
            {
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + token,
                }
            }
        )
            .then(() => { setShow(true) })
            .then(() => {
                setTimeout(() => {
                    window.location.reload()
                }, 4000)
            })
            .catch((err) => { console.log("err post address : ", err) })
    }

    const btnDeleteAddress = async ({ idAddress }) => {
        await axios.post(`${process.env.REACT_APP_API_URL}/users/removeaddress/${userId}/address/${idAddress}`, dataAddressPost,
            {
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + token,
                }
            }
        )
            .then(() => { setMsgDeleteAddress(false) })
            .then(() => window.location.reload())
            .catch((err) => { console.log("err delete address : ", err) })
    }

    const Subtotal = cartItems.reduce((total, cartitem) => {
        const item = dataaArticales && dataaArticales.find((i) => i._id === cartitem.id)
        return total + (item?.newPricee || item?.price || 0) * cartitem.quantity;
    }, 0)
    const dataDelivary = getPriceDelevary?.priceDelevary
    const Delivery = cartQuantity == 0 ? 0 : now == 100 ? 0 : dataDelivary

    const Discount = cartItems.reduce((total, cartitem) => {
        const item = dataaArticales && dataaArticales.find((i) => i._id === cartitem.id)
        const Discountprice = item?.price - item?.newPricee
        return total + (Discountprice || 0) * cartitem.quantity;
    }, 0)

    const totalPrice = cartItems.reduce((total, cartitem) => {
        const item = dataaArticales && dataaArticales.find((i) => i._id === cartitem.id)
        return total + (item?.newPricee || item?.price || 0) * cartitem.quantity;
    }, 0) + Delivery
    const DataCreateOrder = { payment, receipt, receiptAddress: selectedItemId, cartItems, userId, totalPrice, Delivery, Discount, Subtotal }

    const btnPay = async () => {
        setStatusBtn(<LoadingBtn />)
        if (getPriceDelevary) {
            const currentDate = new Date();
            const currentDateHours = currentDate.getHours();
            const currentDateMinutes = currentDate.getMinutes();
            const currentTotalMinutes = currentDateHours * 60 + currentDateMinutes;

            const open = new Date(getPriceDelevary.isoTimeOpenMarket);
            const openHours = open.getHours();
            const openMinutes = open.getMinutes();
            const openTotalMinutes = openHours * 60 + openMinutes;

            const close = new Date(getPriceDelevary.isoTimeCloseMarket);
            const closeHours = close.getHours();
            const closeMinutes = close.getMinutes();
            const closeTotalMinutes = closeHours * 60 + closeMinutes;

            let marketIsOpen;

            if (closeTotalMinutes > openTotalMinutes) {
                marketIsOpen = currentTotalMinutes >= openTotalMinutes && currentTotalMinutes < closeTotalMinutes;
            } else {
                marketIsOpen = currentTotalMinutes >= openTotalMinutes || currentTotalMinutes < closeTotalMinutes;
            }

            if (!marketIsOpen) {
                setErr(t("The store is closed"));
                setStatusBtn(t("Pay"))
                return;
            }
        }

        if (!cartItems.length) {
            setErr(t("No items"));
            setStatusBtn(t("Pay"))
            return;
        }
        else if (!selectedItemId) {
            setStatusBtn(t("Pay"))
            setErr(t("Choose the delivery address"));
            return;
        }
        else if (payment.ar == "بطاقة") {
            const checkQuantities = async () => {
                for (const item of cartItems) {
                    if (item.data.number < item.quantity) {
                        return false; // الكميات غير كافية
                    }
                }
                return true; // الكميات كافية
            };

            const quantitiesSufficient = await checkQuantities();
            if (!quantitiesSufficient) {
                setStatusBtn(t("Pay"))
                setErr(t("Quantities are not enough"))
                return;
            }
            nav('/cline/Articales/FinishMarket/PayCard', { state: DataCreateOrder })
        } else if (payment.ar == "كاش" || payment.ar == "بطاقة للدلفري") {
            await axios.post(`${process.env.REACT_APP_API_URL}/clineArticales/CreateOrder`, DataCreateOrder)
                .then((doc) => nav(`/cline/Articales/FinishMarket/Invoice/${doc.data._id}`, { state: { Order: doc.data } }))
                .then(() => removeAllCart())
                .catch((err) => { { console.log("err", err) } { setErr(err.response.data.errors[0].msg) } })
        }
    }

    const handleSelect = (key) => {
        if (key === 'address') {
            setTab("address");
        }
        else if (key === 'addaddress') {
            setTab("addaddress");
        }
    };

    return (<>
        <div style={{ display: "flex", marginTop: isMobile ? "30px" : "65px", minHeight: "100vh", backgroundColor: "rgb(235, 235, 235)", overflow: "hidden" }}>
            <Nav className="NavDisktop" style={{ minHeight: "500px", width: "15%", borderRight: "solid 1px rgb(219, 218, 218)", margin: "0", backgroundColor: "white" }}>
                <Accordion style={{ width: "15%", position: "fixed" }} alwaysOpen defaultActiveKey={['0', '1']}>
                    {/* <div style={{ width: "99%", fontSize: "25px", height: "40px", borderBottom: "1px solid ", textAlign: "center" }}>Filter</div> */}
                    <Accordion.Item eventKey="0" style={{ width: "99%" }} >
                        <Accordion.Header style={{ fontSize: "20px", width: "99%", padding: "2px" }}> <span style={{ flexGrow: 1, textAlign: "start" }}>{t("Orders.Payment.Payment")}</span>  </Accordion.Header>
                        <Accordion.Body style={{ padding: "15px 0" }}>
                            <form onChange={handleChange1}>
                                <input type="radio" id="Cash" name="paymentMethod" value={JSON.stringify({ ar: "كاش", he: "כסף מזומן" })} style={{ width: "20%", cursor: "pointer" }} defaultChecked />
                                <label for={"Cash"} style={{ width: "80%", cursor: "pointer" }}><FaMoneyBillWave style={{ margin: "0 5px 5px 5px" }} /> {t("Orders.Payment.Cash")}</label>
                                <input type="radio" id="Card" name="paymentMethod" value={JSON.stringify({ he: "כַּרְטִיס", ar: "بطاقة" })} style={{ width: "20%", cursor: "pointer" }} />
                                <label for={"Card"} style={{ width: "80%", cursor: "pointer" }}><FaRegCreditCard style={{ margin: "0 5px 5px 5px" }} />{t("Orders.Payment.Card")}</label>
                                <input type="radio" id="CardinDelivery" name="paymentMethod" value={JSON.stringify({ ar: "بطاقة للدلفري", he: "כרטיס משלוח" })} style={{ width: "20%", cursor: "pointer" }} />
                                <label for={"CardinDelivery"} style={{ width: "80%", cursor: "pointer" }}><BsDeviceSsdFill style={{ margin: "0 5px 5px 5px" }} />{t("Orders.Payment.Card in Delivery")}</label>
                            </form>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1" style={{ width: "99%" }} >
                        <Accordion.Header style={{ fontSize: "20px", width: "99%", padding: "2px" }}> <span style={{ flexGrow: 1, textAlign: "start" }}>{t("FinishMarket.The receipt")}</span> </Accordion.Header>
                        <Accordion.Body style={{ padding: "15px 0" }}>
                            <form onChange={handleChange2}>
                                <input type="radio" id="delivery" name="receipt" value={JSON.stringify({ he: "מְסִירָה", ar: "دلفري" })} style={{ width: "20%", cursor: "pointer" }} defaultChecked />
                                <label for={"delivery"} name="receipt" style={{ width: "80%", cursor: "pointer" }}><MdDeliveryDining style={{ margin: "0 5px", fontSize: "18px" }} />{t("FinishMarket.Delivery")}</label>
                                <input type="radio" id="FromThePlace" name="receipt" value={JSON.stringify({ ar: "استلام من المكان", he: "קבלה מהמקום" })} className="custom-radio" style={{ width: "20%", cursor: "pointer" }} />
                                <label for={"FromThePlace"} name="receipt" style={{ width: "80%", cursor: "pointer" }}><IoStorefront style={{ margin: "0 5px" }} />{t("FinishMarket.From the place")}</label>
                            </form>
                        </Accordion.Body>
                    </Accordion.Item>
                    <div style={{ width: "90%", margin: "5% 5% 5% 5%" }}>
                        <Button bsPrefix className={"ButtonBNB"} style={{ width: "100%", height: "40px", bottom: "0" }} onClick={btnPay} >{statusBtn}</Button>
                    </div>
                </Accordion>
            </Nav>
            <div style={{ width: isMobile ? "100%" : "85%" }}>
                <div style={{ backgroundColor: "white", width: "96%", minHeight: "100vh", borderRadius: "10px", boxShadow: " 5px 5px 5px 0 rgb(219, 218, 218)", display: "flex", flexDirection: isMobile && "column", padding: "1%", margin: "2%" }}>
                    <div style={{ width: isMobile ? "100%" : "50%" }}>
                        <div style={{ fontSize: "18px", minHeight: "40px", maxHeight: "43px", Height: "1%", textAlign: "center", marginBottom: "4px", borderBottom: "1px solid rgb(228, 228, 228)", paddingBottom: "2%" }}>
                            {t("Orders.Order details")}
                        </div>
                        <Stack style={{ width: "100%", padding: "3%", maxHeight: "50vh", overflowY: "auto" }}>
                            <div style={{ fontSiz: "50px" }}>
                                {cartItems.map((item) => (
                                    <CartItem key={item.id} {...item} />
                                ))}
                            </div>
                            <div style={{ display: 'flex', justifyContent: "space-between", padding: "0 0 0 10%" }}>
                                <div>
                                    {t("Subtotal")}
                                </div>
                                <div>
                                    {Subtotal} ₪
                                </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: "space-between", padding: "0 0 0 10%" }}>
                                <div>
                                    {t("FinishMarket.Delivery")}
                                </div>
                                <div>
                                    {cartQuantity == 0 ? <div style={{ color: "#ea004b" }}>{t("free")}</div> : now == 100 ? <div style={{ color: "#ea004b" }}>{t("free")}</div> : <div> {getPriceDelevary?.priceDelevary} ₪</div>}
                                </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: "space-between", padding: "0 0 0 10%", borderBottom: "1px solid #b9b9b9" }}>
                                <div>
                                    {t("Discount")}
                                </div>
                                <div>
                                    {Discount} ₪
                                </div>
                            </div>
                        </Stack>
                        {now == 0 ? null : <>
                            <div style={{ display: "flex", textAlign: 'center', justifyContent: "center" }}>
                                {now == 100 ? <div style={{ color: "#ea004b" }} >{t("Free delivery")}</div> : <>{t('Purchase to get free delivery', { price: PriceFreeDelivery })}</>}
                            </div>
                            <div style={{ display: "flex", justifyContent: 'center', marginTop: "10px" }}>
                                <ProgressBar variant="danger" style={{ width: "80%" }} now={now} label={`${now}%`} />
                            </div>
                        </>
                        }
                        <div className=" ms-auto fw-bold fs-5" style={{ display: "flex", justifyContent: "center", textAlign: "center", marginBottom: "4px", borderBottom: "1px solid rgb(228, 228, 228)", paddingBottom: "2%" }}>
                            <div >
                                {t("Total")}{"  :  "}
                            </div>
                            <div>
                                {"   "}
                                {cartItems.reduce((total, cartitem) => {
                                    const item = dataaArticales && dataaArticales.find((i) => i._id === cartitem.id)
                                    return total + (item?.newPricee || item?.price || 0) * cartitem.quantity;
                                }, 0) + Delivery} ₪
                            </div>
                        </div>
                        <div style={{ color: "red", fontSize: "20px", width: "100%", textAlign: "center", backgroundColor: "#ffecec" }}>{err}</div>
                    </div>
                    <div style={{ width: isMobile ? "100%" : "50%" }}>
                        {isMobile && <>
                            <div>
                                <div>
                                    {t("Orders.Payment.Payment")}
                                </div>
                                <div style={{ width: "100%", display: 'flex', justifyContent: "center" }}>
                                    <form style={{ width: "70%" }}>
                                        <div
                                            style={{
                                                border: "1px solid rgb(219, 218, 218)",
                                                borderRadius: "10px",
                                                padding: '20px 20px 20px 10px',
                                                display: "flex",
                                                margin: "2% 0",
                                                boxShadow: "2px 2px 5px rgb(219, 218, 218)",
                                                cursor: "pointer",
                                                color: payment.ar === "كاش" && "#ea004b"
                                            }}
                                            onClick={() => handleChange3(JSON.stringify({ ar: "كاش", he: "כסף מזומן" }))}
                                        >
                                            <div style={{ width: "10%", height: "30px" }}>
                                                <div>
                                                    <input
                                                        type="radio"
                                                        id={`selectAcash`}
                                                        name="selectA"
                                                        value={""}
                                                        style={{ width: "50%", cursor: "pointer" }}
                                                        checked={payment.ar === "كاش"}
                                                    // readOnly
                                                    />
                                                    <label htmlFor={`selectAcash`} style={{ width: "60%" }}></label>
                                                </div>
                                            </div>
                                            <div style={{ width: "80%" }}>
                                                <div style={{ display: "flex", flexDirection: 'row', textAlign: "start" }}>
                                                    <div>
                                                        <FaMoneyBillWave style={{ margin: "0 10px" }} /> {t("Orders.Payment.Cash")}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                border: "1px solid rgb(219, 218, 218)",
                                                borderRadius: "10px",
                                                padding: '20px 20px 20px 10px',
                                                display: "flex",
                                                margin: "2% 0",
                                                boxShadow: "2px 2px 5px rgb(219, 218, 218)",
                                                cursor: "pointer",
                                                color: payment.ar === "بطاقة" && "#ea004b"
                                            }}
                                            onClick={() => handleChange3(JSON.stringify({ he: "כַּרְטִיס", ar: "بطاقة" }))}
                                        >
                                            <div style={{ width: "10%", height: "30px" }}>
                                                <div>
                                                    <input
                                                        type="radio"
                                                        id={`selectAcard`}
                                                        name="selectA"
                                                        value={""}
                                                        style={{ width: "50%", cursor: "pointer" }}
                                                        checked={payment.ar === "بطاقة"}
                                                    // readOnly
                                                    />
                                                    <label htmlFor={`selectAcard`} style={{ width: "60%" }}></label>
                                                </div>
                                            </div>
                                            <div style={{ width: "80%" }}>
                                                <div style={{ display: "flex", flexDirection: 'row', textAlign: "start" }}>
                                                    <div>
                                                        <FaRegCreditCard style={{ margin: "0 10px" }} />  {t("Orders.Payment.Card")}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                border: "1px solid rgb(219, 218, 218)",
                                                borderRadius: "10px",
                                                padding: '20px 20px 20px 10px',
                                                display: "flex",
                                                margin: "2% 0",
                                                boxShadow: "2px 2px 5px rgb(219, 218, 218)",
                                                cursor: "pointer",
                                                color: payment.ar === "بطاقة للدلفري" && "#ea004b"
                                            }}
                                            onClick={() => handleChange3(JSON.stringify({ ar: "بطاقة للدلفري", he: "כרטיס משלוח" }))}
                                        >
                                            <div style={{ width: "10%", height: "30px" }}>
                                                <div>
                                                    <input
                                                        type="radio"
                                                        id={`selectAcardInDelivery`}
                                                        name="selectA"
                                                        value={""}
                                                        style={{ width: "50%", cursor: "pointer" }}
                                                        checked={payment.ar === "بطاقة للدلفري"}
                                                    // readOnly
                                                    />
                                                    <label htmlFor={`selectAcardInDelivery`} style={{ width: "60%" }}></label>
                                                </div>
                                            </div>
                                            <div style={{ width: "80%" }}>
                                                <div style={{ display: "flex", flexDirection: 'row', textAlign: "start" }}>
                                                    <div>
                                                        <BsDeviceSsdFill style={{ margin: "0 10px" }} /> {t("Orders.Payment.Card in Delivery")}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div>
                                <div>
                                    {t("FinishMarket.The receipt")}
                                </div>
                                <div style={{ width: "100%", display: 'flex', justifyContent: "center" }}>
                                    <form style={{ width: "70%" }}>
                                        <div
                                            style={{
                                                border: "1px solid rgb(219, 218, 218)",
                                                borderRadius: "10px",
                                                padding: '20px 20px 20px 10px',
                                                display: "flex",
                                                margin: "2% 0",
                                                boxShadow: "2px 2px 5px rgb(219, 218, 218)",
                                                cursor: "pointer",
                                                color: receipt.ar === "دلفري" && "#ea004b"
                                            }}
                                            onClick={() => handleChange4(JSON.stringify({ he: "מְסִירָה", ar: "دلفري" }))}
                                        >
                                            <div style={{ width: "10%", height: "30px" }}>
                                                <div>
                                                    <input
                                                        type="radio"
                                                        id={`selectAcard`}
                                                        name="selectA"
                                                        value={""}
                                                        style={{ width: "50%", cursor: "pointer" }}
                                                        checked={receipt.ar === "دلفري"}
                                                    // readOnly
                                                    />
                                                    <label htmlFor={`selectAcard`} style={{ width: "60%" }}></label>
                                                </div>
                                            </div>
                                            <div style={{ width: "80%" }}>
                                                <div style={{ display: "flex", flexDirection: 'row', textAlign: "start" }}>
                                                    <div>
                                                        <MdDeliveryDining style={{ margin: '0 10px' }} /> {t("FinishMarket.Delivery")}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                border: "1px solid rgb(219, 218, 218)",
                                                borderRadius: "10px",
                                                padding: '20px 20px 20px 10px',
                                                display: "flex",
                                                margin: "2% 0",
                                                boxShadow: "2px 2px 5px rgb(219, 218, 218)",
                                                cursor: "pointer",
                                                color: receipt.ar === "استلام من المكان" && "#ea004b"

                                            }}
                                            onClick={() => handleChange4(JSON.stringify({ ar: "استلام من المكان", he: "קבלה מהמקום" }))}
                                        >
                                            <div style={{ width: "10%", height: "30px" }}>
                                                <div>
                                                    <input
                                                        type="radio"
                                                        id={`selectAcardInDelivery`}
                                                        name="selectA"
                                                        value={""}
                                                        style={{ width: "50%", cursor: "pointer" }}
                                                        checked={receipt.ar === "استلام من المكان"}
                                                    // readOnly
                                                    />
                                                    <label htmlFor={`selectAcardInDelivery`} style={{ width: "60%" }}></label>
                                                </div>
                                            </div>
                                            <div style={{ width: "80%" }}>
                                                <div style={{ display: "flex", flexDirection: 'row', textAlign: "start" }}>
                                                    <div>
                                                        <IoStorefront style={{ margin: '0 10px' }} />{t("FinishMarket.From the place")}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </>
                        }

                        <Tabs
                            defaultActiveKey="address"
                            id="uncontrolled-tab-example"
                            className="mb-3"
                            onSelect={handleSelect}
                            style={{ display: isMobile && receipt.ar == "استلام من المكان" && "none", justifyContent: "space-evenly", minHeight: "40px", maxHeight: "43px" }}
                        >
                            <Tab eventKey="address" title={t("FinishMarket.Specify your address")} style={{ color: "black", padding: " 0 3% 3% 3%", minHeight: "40px" }} >
                                {tap == "address" &&
                                    <form >
                                        {address.map((item, index) => (
                                            <div
                                                // key={item._id}
                                                style={{
                                                    border: "1px solid rgb(219, 218, 218)",
                                                    borderRadius: "10px",
                                                    padding: '20px 20px 20px 10px',
                                                    margin: "2% 0",
                                                    boxShadow: "2px 2px 5px rgb(219, 218, 218)",
                                                    display: isMobile ? receipt.ar == "استلام من المكان" ? "none" : "flex" : "flex",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() => handleChange(item._id)}
                                                onMouseEnter={() => setIdAddress(item._id)}
                                            >

                                                <div style={{ width: "10%" }}>
                                                    <div>
                                                        <Button variant="outline-danger" style={{ fontSize: "15px", padding: "0 6px" }} onClick={() => setMsgDeleteAddress(true)}> &times;</Button>
                                                    </div>
                                                </div>
                                                <div style={{ width: "10%" }}>
                                                    <div>
                                                        <input
                                                            type="radio"
                                                            id={`selectA${index}`}
                                                            name="selectA"
                                                            value={item._id}
                                                            style={{ width: "30%", cursor: "pointer" }}
                                                            checked={selectedItemId === item._id}
                                                        // readOnly
                                                        />
                                                        <label htmlFor={`selectA${index}`} style={{ width: "60%" }}></label>
                                                    </div>
                                                </div>
                                                <div style={{ width: "80%" }}>
                                                    <div style={{ display: "flex", flexDirection: 'row-reverse' }}>
                                                        <div>
                                                            {item.city}.
                                                        </div>
                                                        <div>
                                                            {item.district} ,
                                                        </div>
                                                    </div>
                                                    <div style={{ color: "rgb(116 116 116)", display: "flex", flexWrap: "wrap", flexDirection: 'row-reverse' }}>
                                                        <div>
                                                            {t("Orders.Orderdetails.Street")}  {item.street}.
                                                        </div>
                                                        <div>
                                                            {t("Orders.Orderdetails.Architecture")} {item.architectureName} ,
                                                        </div>
                                                        <div>
                                                            {t("Orders.Orderdetails.Floor")} {item.floorNumber} ,
                                                        </div>
                                                        <div>
                                                            {t("Orders.Orderdetails.Apartment")}  {item.apartmentNumber} ,
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                        }
                                    </form>}
                            </Tab>
                            <Tab eventKey="addaddress" title={t("FinishMarket.Add Address")} style={{ color: "black", padding: "3%", display: isMobile ? receipt.ar == "استلام من المكان" ? "none" : "flex" : "flex", }} >
                                {tap == "addaddress" &&
                                    <div style={{ width: "96%", margin: "0 2%" }}>
                                        <div style={{ display: "flex", width: "100%" }}>
                                            <div style={{ width: "50%" }}>
                                                <div class="form-floating mb-3" style={{ width: '95%' }} >
                                                    <input type="text" class="form-control" id="floatingInput" onChange={(e) => { setCity(e.target.value) }} placeholder="name@example.com" />
                                                    <label for="floatingInput">{t("FinishMarket.City")}</label>
                                                </div>
                                            </div>
                                            <div style={{ width: "50%" }}>
                                                <div class="form-floating mb-3" style={{ width: '95%' }}>
                                                    <input type="text" class="form-control" id="floatingInput" onChange={(e) => { setDistrict(e.target.value) }} placeholder="name@example.com" />
                                                    <label for="floatingInput">{t("FinishMarket.neighborhood")}</label>

                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ display: "flex" }}>
                                            <div style={{ width: "50%" }}>
                                                <div class="form-floating mb-3" style={{ width: '95%' }}>
                                                    <input type="text" class="form-control" id="floatingInput" onChange={(e) => { setStreet(e.target.value) }} placeholder="name@example.com" />
                                                    <label for="floatingInput">{t("Orders.Orderdetails.Street")}</label>

                                                </div>
                                            </div>
                                            <div style={{ width: "50%" }}>
                                                <div class="form-floating mb-3" style={{ width: '95%' }}>
                                                    <input type="text" class="form-control" id="floatingInput" onChange={(e) => { setArchitectureName(e.target.value) }} placeholder="name@example.com" />
                                                    <label for="floatingInput">  {t("FinishMarket.Building name/number")}</label>

                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ display: "flex" }}>
                                            <div style={{ width: "50%" }}>
                                                <div class="form-floating mb-3" style={{ width: '95%' }}>
                                                    <input type="number" class="form-control" id="floatingInput" onChange={(e) => { setApartmentNumber(e.target.value) }} placeholder="name@example.com" />
                                                    <label for="floatingInput"> {t("Orders.Orderdetails.Apartment")}</label>

                                                </div>
                                            </div>
                                            <div style={{ width: "50%" }}>
                                                <div class="form-floating mb-3" style={{ width: '95%' }}>
                                                    <input type="number" class="form-control" id="floatingInput" onChange={(e) => { setFloorNumber(e.target.value) }} placeholder="name@example.com" />
                                                    <label for="floatingInput"> {t("Orders.Orderdetails.Floor")}</label>

                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="form-floating mb-3" style={{ width: '97%', alignItems: "center" }}>
                                                <input type="text" class="form-control" style={{ width: '100%' }} id="floatingInput" onChange={(e) => { setAdditionalDetails(e.target.value) }} placeholder="name@example.com" />
                                                <label for="floatingInput"> {t("FinishMarket.Additional details")}</label>
                                            </div>
                                        </div>
                                        <div style={{ textAlign: "center" }} >
                                            <Button bsPrefix className={"ButtonBNB"} style={{ width: "100%", height: "40px", bottom: "0" }} onClick={btnAddAddress}> {t("FinishMarket.Add Adress")}</Button>
                                        </div>
                                    </div>}
                            </Tab>
                        </Tabs>
                        {isMobile &&
                            <div style={{ width: "90%", margin: "5% 5% 5% 5%" }}>
                                <Button bsPrefix className={"ButtonBNB"} style={{ width: "100%", height: "40px", bottom: "0" }} onClick={btnPay} >{t("Pay")}</Button>
                            </div>
                        }

                    </div>
                </div>
            </div>

            <MsgToast type={"success"} show={show} setShow={setShow} title={t("FinishMarket.Title added")} body={t("FinishMarket.The address has been added, choose your address now")} />
            <MsgModal show={msgDeleteAddress} handleClose={() => setMsgDeleteAddress(false)} opj={() => btnDeleteAddress({ idAddress })} title={t("FinishMarket.confirmation message")} body={t("FinishMarket.Do you really want to delete the address?")} BtnState={t("Yes sure")} />
        </div >
    </>)
}

export default FinishMarket;