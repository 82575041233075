import { useContext, useEffect, useState } from "react";
import "../../cline/market/Articales.css";
import axios from "axios";
import Swal from "sweetalert2";
import { User } from "../../context/context";
import { useNavigate } from "react-router-dom";
import { Loading } from "../../refreshPage/loading";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";



function CreateArticales() {
    const { t, i18n } = useTranslation();
    const usernaw = useContext(User);
    const token = usernaw.auth.token;
    // const nav = useNavigate();

    const [dataa, setDataa] = useState([]);
    const [category, setCategory] = useState();
    const [nameeAr, setNameeAr] = useState("");
    const [nameeHe, setNameeHe] = useState("");
    const [titleeAr, setTitleeAr] = useState("");
    const [titleeHe, setTitleeHe] = useState("");
    const [informationnAr, setInformationnAr] = useState("");
    const [informationnHe, setInformationnHe] = useState("");
    const [pricee, setPricee] = useState("");
    const [newPricee, setNewPricee] = useState();
    const [discount, setDiscount] = useState();
    const [numberr, setNumberr] = useState();
    const [emg, setEmg] = useState(null);
    const [formData, setFormData] = useState(new FormData());
    const [errMsg, setErrMsg] = useState()

    const [isOn, setIsOn] = useState(false);

    const handleClick = () => {
        setIsOn(!isOn);
    };

    useEffect(() => {
        if (pricee && newPricee) {
            const oldP = parseFloat(pricee);
            const newP = parseFloat(newPricee);
            const discountPercentage = ((oldP - newP) / oldP) * 100;
            setDiscount(discountPercentage.toFixed(0));
        }
        setErrMsg()

    }, [pricee, newPricee])

    useEffect(() => {
        setErrMsg()
    }, [informationnHe, informationnAr, titleeHe, titleeAr, nameeHe, nameeAr, emg])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/articales/GetCategoryMarket`, {
            headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "multipart/form-data"
            }
        })
            .then((response) => {
                setDataa(response.data);
                setCategory(response.data[0] ? response.data[0].name : '');
            })
            .catch((error) => {
                console.log("Error fetching categories:", error);
            });
    }, []);


    function handleFileSelect(event) {
        const files = event.target.files;
        const selectedFiles = [];


        for (let i = 0; i < files.length; i++) {
            selectedFiles.push(files[i]);
        }
        setEmg(selectedFiles);
        if (isOn == true) {
            const updatedFormData = new FormData();
            updatedFormData.append("category_ar", category.ar);
            updatedFormData.append("category_he", category.he);
            updatedFormData.append("name_ar", nameeAr);
            updatedFormData.append("name_he", nameeHe);
            updatedFormData.append("title_ar", titleeAr);
            updatedFormData.append("title_he", titleeHe);
            updatedFormData.append("information_ar", informationnAr);
            updatedFormData.append("information_he", informationnHe);
            updatedFormData.append("price", pricee);
            updatedFormData.append("Offers", true);
            updatedFormData.append("newPricee", newPricee);
            updatedFormData.append("discount", discount)
            updatedFormData.append("number", numberr);
            for (let i = 0; i < selectedFiles.length; i++) {
                updatedFormData.append("file", selectedFiles[i]);
            }
            setFormData(updatedFormData);
        }
        else {
            const updatedFormData = new FormData();
            updatedFormData.append("category_ar", category.ar);
            updatedFormData.append("category_he", category.he);
            updatedFormData.append("name_ar", nameeAr);
            updatedFormData.append("name_he", nameeHe);
            updatedFormData.append("title_ar", titleeAr);
            updatedFormData.append("title_he", titleeHe);
            updatedFormData.append("information_ar", informationnAr);
            updatedFormData.append("information_he", informationnHe);
            updatedFormData.append("price", pricee);
            updatedFormData.append("number", numberr);
            for (let i = 0; i < selectedFiles.length; i++) {
                updatedFormData.append("file", selectedFiles[i]);
            }
            setFormData(updatedFormData);
        }
    }

    async function btnInsert() {
        // if (!nameeAr || !nameeHe || !titleeAr || !titleeHe || !informationnAr || !informationnHe || !pricee || !numberr) {
        //     setErrMsg(t("Please fill out the fields"))
        //     return;
        // }
        if (!emg || emg.length === 0) {
            setErrMsg(t("Please add a photo"))
            return;
        }
        if (Number(newPricee) >= Number(pricee)) {
            setErrMsg(t("The new price must be lower than the old price"));
            return;
        }

        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/articales/CreateArticales`, formData, {
                headers: {
                    Authorization: "Bearer " + token,
                    "Content-Type": "multipart/form-data"
                }
            });

            await Swal.fire({
                icon: 'success',
                title: '<h1> Success </h1> <br /> تم اضافة المنتج ',
                showConfirmButton: false,
                timer: 1500,
                html: true
            });
            // nav("/admin/Home/Home4");
        } catch (error) {
            console.log("Error posting data:", error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Failed to create product',
            });
        }
    }



    const categoryLanguage = (e) => {
        const LCategory = JSON.parse(e);
        setCategory(LCategory)
    }

    return (
        <div id="allPage">
            {dataa ? (
                <div id="PageAdmin">
                    <div id="H1Login">
                        <h1>{t("hedarAdmin.Create Product")}</h1>
                    </div>
                    <label>{t("Category Market Name")}</label>
                    <select className="form-select form-select-lg mb-3" aria-label="Large select example" style={{ width: "80%" }} onChange={(e) => categoryLanguage(e.target.value)} >
                        {dataa.map((item, index) => <option key={index} value={JSON.stringify(item.name)} >{i18n.language = "ar" ? item.name.ar : item.name.he}</option>)}
                    </select>

                    <div style={{ width: "80%", display: "flex" }}>
                        <div className="mb-3" style={{ width: "48%", margin: "0 0 0 2%" }}>
                            <label htmlFor="exampleFormControlInput1" className="form-label">{t("ProductId.name")}</label>
                            <input type="text" className="form-control" id="exampleFormControlInput1" onChange={(e) => setNameeAr(e.target.value)} placeholder="بالعربية" />
                            <input type="text" style={{ marginTop: "5px" }} className="form-control" id="exampleFormControlInput1" onChange={(e) => setNameeHe(e.target.value)} placeholder="בעברית" />
                        </div>
                        <div className="mb-3" style={{ width: "48%", margin: "0 2% 0 0" }}>
                            <label htmlFor="exampleFormControlInput1" className="form-label">{t("ProductId.title")}</label>
                            <input type="text" className="form-control" id="exampleFormControlInput1" onChange={(e) => setTitleeAr(e.target.value)} placeholder="بالعربية" />
                            <input type="text" style={{ marginTop: "5px" }} className="form-control" id="exampleFormControlInput1" onChange={(e) => setTitleeHe(e.target.value)} placeholder="בעברית" />
                        </div>
                    </div>

                    <div className="mb-3" style={{ width: "80%" }}>
                        <label className="form-label">{t("ProductId.information")}</label>
                        <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" onChange={(e) => setInformationnAr(e.target.value)} placeholder="بالعربية"></textarea>
                        <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" onChange={(e) => setInformationnHe(e.target.value)} placeholder="בעברית"></textarea>
                    </div>
                    <div className="form-floating mb-3" style={{ display: 'flex', alignItems: 'center', width: "80%" }}>
                        <div style={{ marginLeft: '10%' }}>
                            عرض ؟
                        </div>

                        <button onClick={handleClick} style={{
                            padding: '5px 20px',
                            fontSize: '16px',
                            backgroundColor: isOn ? 'green' : 'red',
                            color: 'white',
                            border: 'none',
                            borderRadius: '5px',
                            cursor: 'pointer'
                        }}>
                            {isOn ? 'Yas' : 'No'}
                        </button>
                    </div>
                    {isOn ?
                        <div style={{ width: "80%", display: "flex" }}>
                            <div className="form-floating mb-3">
                                <input type="number" className="form-control" id="floatingInputPrice" onChange={(e) => setPricee(e.target.value)} placeholder="0" />
                                <label htmlFor="floatingInputPrice">{t("old price")}</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input type="number" className="form-control" id="floatingInputNumber" onChange={(e) => setNewPricee(e.target.value)} placeholder="0" />
                                <label htmlFor="floatingInputNumber">{t("New price")}</label>
                            </div>
                            <div className="form-floating mb-3" style={{ display: "flex", justifyContent: "center", alignItems: 'center' }}>
                                {t("Discount")} : {discount} %
                            </div>
                            <div className="form-floating mb-3">
                                <input type="number" className="form-control" id="floatingInputNumber" onChange={(e) => setNumberr(e.target.value)} placeholder="0" />
                                <label htmlFor="floatingInputNumber">{t("ProductId.Existing quantity")}</label>
                            </div>
                        </div>
                        :
                        <div style={{ width: "80%", display: "flex" }}>
                            <div className="form-floating mb-3">
                                <input type="number" className="form-control" id="floatingInputPrice" onChange={(e) => setPricee(e.target.value)} placeholder="0" />
                                <label htmlFor="floatingInputPrice">{t("Price")}</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input type="number" className="form-control" id="floatingInputNumber" onChange={(e) => setNumberr(e.target.value)} placeholder="0" />
                                <label htmlFor="floatingInputNumber">{t("ProductId.Existing quantity")}</label>
                            </div>
                        </div>
                    }
                    <div className="form-floating mb-3">
                        <input type="file" className="form-control" id="floatingInputNumber" onChange={(e) => handleFileSelect(e)} multiple />
                        <label htmlFor="floatingInputNumber">{t("ProductId.emg")}</label>
                    </div>

                    <div style={{ color: "red", margin: "10px", fontSize: "16px" }}>{errMsg}</div>
                    <div className="col-12">
                        <Button bsPrefix className={"ButtonBNB"} style={{ width: "200px" }} onClick={btnInsert}>{t("hedarAdmin.Create Product")}</Button>
                    </div>
                </div>
            ) : (
                <Loading />
            )}
        </div>
    );
}

export default CreateArticales;
