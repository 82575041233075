import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Loading } from '../../../refreshPage/loading';
import { TbListDetails } from 'react-icons/tb';
import { MdOutlineRestartAlt } from "react-icons/md";
import { Button, Form } from 'react-bootstrap';
import { useShoppingCart } from '../../../context/shoppingCartContext';
import { useTranslation } from "react-i18next";
import PaginatedItems from '../../../components/pagination';
import CartComponent from '../../../components/CartComponent';


const MyOrders = () => {
    const { t, i18n } = useTranslation();
    const { reorderCartQuantity } = useShoppingCart()
    let params = useParams()
    const nav = useNavigate()

    const [dataa, setDataa] = useState()

    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(50)
    const start = (page - 1) * limit
    const end = Number(start) + Number(limit)
    // const final = dataa && dataa.slice(start, end)

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 500);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const postData = { page, limit }
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/clineArticales/GetOrders/${params.UserId}/${limit}/${page}`)
            .then((doc) => setDataa(doc.data))
            .catch((err) => console.log(err))
    }, [page, limit])

    const BtnDetalisOrder = (Order) => {
        nav(`/cline/Articales/MyOrders/DetalisOrder/${Order._id}`, { state: { Order } })
    }

    const BtnReOrder = async (item) => {
        await item.cart.map((i) => reorderCartQuantity({ data: i, id: i._id, NumQuantity: i.quantity }))
        nav("/cline/Articales/FinishMarket")
    }

    const orderStatus = (item) => {
        if (item.status.ar === "جديد") {
            return <span style={{ color: "red" }}>{t("Orders.Status.ok")}</span>
        } else if (item.status.ar === "تم المشاهدة") {
            return <span style={{ color: "red" }}>{t("Orders.Status.ok")}</span>
        } else if (item.status.ar === "قيد التجهيز") {
            return <span style={{ color: "red" }}>{t("Orders.Status.in preparation")}</span>
        } else if (item.status.ar === "في الطريق") {
            return <span style={{ color: "red" }}>{t("Orders.Status.in the way")}</span>
        } else if (item.status.ar === "تم التسليم") {
            return <span style={{ color: "green" }}>{t("Orders.Status.received")}</span>
        } else if (item.status.ar === "الغاء") {
            return <span style={{ color: "green" }}>{t("the request has been canceled")}</span>;
        }
    }

    return (<>
        <div style={{ display: 'flex' }}>
            <div id="allPage" style={{ width: "100%", padding: "40px 0 0 0 ", margin: "0", display: "flex", flexDirection: "row", justifyContent: "center", backgroundColor: isMobile && "rgb(233 233 233)" }}>
                <div id="Page" style={{ alignItems: "stretch", paddingBottom: "0", width: "100%", margin: "0" }}>
                    {dataa ?
                        isMobile ? <div style={{ padding: "0", margin: " 0", backgroundColor: "rgb(233 233 233)" }}>
                            <div class="table  table-hover table-light " style={{ fontSize: "15px", borderRadius: "5px", boxShadow: "2px 2px 5px 0 rgb(219, 218, 218)", textAlign: "start" }}>
                                {dataa.doc.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map((item, index) => (<>
                                    <div style={{ padding: "0", width: "94%", margin: "3%", borderRadius: "10px", overflow: "hidden" }} className="table table-hover table-light">
                                        <div key={index} style={{ padding: "0", width: "100%" }}>
                                            <div >{index + 1}</div>
                                            <div >{t("Orders.Orderdetails.item")} : {item.cart.map((i) => (<>{i18n.language === "ar" ? i.name.ar : i.name.he} ,</>))}</div>
                                            <div >{t("Orders.Orderdetails.Total Price")} : {item.totalPrice} ₪</div>
                                            <div >{t("Orders.Date Order")} : {item.DateOrder}</div>
                                            <div >{t("hedarAdmin.Status")} : {orderStatus(item)} </div>
                                            <div style={{ display: "table-cell", padding: "0 1% 0 1%", width: "20%" }} >
                                                <div style={{ display: "flex", alignItems: "end", padding: "0", alignItems: "center" }}>
                                                    {isMobile ? null : <div className="btnMyOrders" style={{ paddingLeft: "0", padding: "0", width: "20%" }}>
                                                        <Button
                                                            onClick={() => BtnReOrder(item)}
                                                            title={t("Orders.Reorder")}
                                                            style={{ border: "none", padding: "0", transition: "none", backgroundColor: "initial", color: "red", fontSize: "30px" }}
                                                        >
                                                            <MdOutlineRestartAlt />
                                                        </Button>
                                                    </div>}
                                                    <div style={{ width: "100%", padding: "3px 0", display: 'flex', justifyContent: 'center' }}>
                                                        <Button bsPrefix className={"ButtonBNB"} style={{ width: "80%", height: "40px", bottom: "0" }}
                                                            onClick={() => BtnDetalisOrder(item)}
                                                        >
                                                            {t("Orders.Order details")}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>))
                                }
                            </div >
                            <div style={{ display: 'flex', justifyContent: "flex-end", marginRight: "2%", alignItems: 'baseline' }}>
                                <Form.Select style={{ width: "80px" }} onChange={(e) => setLimit(e.target.value)}>
                                    {/* <option value={20}>20</option> */}
                                    {/* <option value={30}>30</option> */}
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                    <option value={150}>150</option>
                                </Form.Select>
                                <PaginatedItems total={dataa.total} itemsPerPage={limit} setPage={setPage} />
                            </div>
                        </div>
                            :
                            <div style={{ padding: "0", margin: "3% 0 0 0" }}>
                                <table class="table  table-hover table-light " style={{ fontSize: "15px", backgroundColor: "white", borderRadius: "5px", boxShadow: "2px 2px 5px 0 rgb(219, 218, 218)", textAlign: "start" }}>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>{t("Orders.Orderdetails.item")}</th>
                                            <th>{t("Orders.Orderdetails.Total Price")}</th>
                                            <th>{t("Orders.Date Order")}</th>
                                            <th>{t("hedarAdmin.Status")}</th>
                                            <th >{<TbListDetails />}</th>
                                        </tr>
                                    </thead>
                                    {dataa?.doc.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map((item, index) => (<>
                                        <tbody style={{ padding: "0", width: "100%" }} className="table table-hover table-light">
                                            <tr key={index} style={{ padding: "0", width: "100%" }}>
                                                <td >{index + 1}</td>
                                                <td >{item.cart.map((i) => (<>{i18n.language === "ar" ? i.name?.ar : i.name?.he} ,</>))}</td>
                                                <td >{item.totalPrice} ₪</td>
                                                <td >{item.DateOrder}</td>
                                                <td >{orderStatus(item)} </td>
                                                <td style={{ display: "table-cell", padding: "0 1% 0 1%", width: "20%" }} >
                                                    <div style={{ display: "flex", alignItems: "end", padding: "0", alignItems: "center" }}>
                                                        {isMobile ? null : <div className="btnMyOrders" style={{ paddingLeft: "0", padding: "0", width: "20%" }}>
                                                            <Button
                                                                onClick={() => BtnReOrder(item)}
                                                                title={t("Orders.Reorder")}
                                                                style={{ border: "none", padding: "0", transition: "none", backgroundColor: "initial", color: "red", fontSize: "30px" }}
                                                            >
                                                                <MdOutlineRestartAlt />
                                                            </Button>
                                                        </div>}
                                                        <div style={{ width: isMobile ? "100%" : "80%", padding: "3px 0" }}>
                                                            <Button bsPrefix className={"ButtonBNB"} style={{ width: "100%", height: isMobile ? "60px" : "40px", bottom: "0" }}
                                                                onClick={() => BtnDetalisOrder(item)}
                                                            >
                                                                {t("Orders.Order details")}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </>))
                                    }
                                </table >
                                <div style={{ display: 'flex', justifyContent: "flex-end", marginRight: "2%", alignItems: 'baseline' }}>
                                    <Form.Select style={{ width: "80px" }} onChange={(e) => setLimit(e.target.value)}>
                                        {/* <option value={20}>20</option> */}
                                        {/* <option value={30}>30</option> */}
                                        <option value={50}>50</option>
                                        <option value={100}>100</option>
                                        <option value={150}>150</option>
                                    </Form.Select>
                                    <PaginatedItems total={dataa.total} itemsPerPage={limit} setPage={setPage} />
                                </div>
                            </div>
                        : <Loading />}
                </div>
            </div>
            {isMobile ? null :
                <div style={{ minHeight: "100vh", height: "100%", width: "25%", maxWidth: "500px", marginTop: "65px" }}>
                    {<CartComponent />}
                </div>
            }
        </div>
    </>)
}

export default MyOrders;