import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Stack } from "react-bootstrap";
import { useShoppingCart } from "../../context/shoppingCartContext";
import { useTranslation } from "react-i18next";
import { FaPlus } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa";

const CartItem = ({ data, id, quantity }) => {
    const { t, i18n } = useTranslation();
    const { removeFromCart, decreaseCartQuantity, increaseCartQuantity } = useShoppingCart()

    const item = data

    if (item == null) return null

    return (<>
        <Stack direction="horizontal" style={{ padding: "3px 0", borderBottom: "1px solid rgb(228, 228, 228)", display: "flex", height: "90px" }} >
            <img src={`${process.env.REACT_APP_API_URL}/files/${item.file[0]}`} alt="cart-img" style={{ width: "60px", height: "60px", objectFit: "cover", backgroundColor: 'rgb(228, 228, 228)', borderRadius: "10px" }} />
            <div className="me-auto" style={{ width: '20%' }}>
                <div style={{ fontSize: "13px", fontWeight: "400" }}>
                    {i18n.language == "ar" ? item.title.ar : item.title.he} {" "}               
                </div>
            </div>
            <div style={{ width: "25%", marginLeft: "5px", display: "flex", flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ display: "flex", flexDirection: 'column' }}>
                    {item.newPricee && <div style={{ textDecoration: 'line-through', color: "#818181", fontSize: "13px" }}>
                        {item.price * quantity}₪
                    </div>}
                    {(item.newPricee || item.price) * quantity}₪
                </div>
                <div style={{ boxShadow: "#cbcbcb 1px 1px 7px", width: "70px", height: "29px", display: "flex", justifyContent: 'space-between', marginLeft: "10px" }}>
                    <div >
                        <button style={{ border: "none", backgroundColor: "white", color: "#ea004b" }} onClick={() => decreaseCartQuantity(item)}>
                            <div className="btnPlass" ><FaMinus style={{ fontSize: "28px", padding: '8px' }} /></div>
                        </button>
                    </div>
                    <div style={{ fontWeight: '500' }}>
                        {quantity}
                    </div>
                    <div>
                        <button style={{ border: "none", backgroundColor: "white", color: "#ea004b" }} onClick={() => increaseCartQuantity(item)}>
                            <div className="btnPlass"><FaPlus style={{ fontSize: "28px", padding: '8px' }} /></div>
                        </button>
                    </div>
                </div>

            </div>
        </Stack>
    </>)
}


export default CartItem;