import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { User } from "../../context/context";
import { Loading, LoadingBtn } from "../../refreshPage/loading";
import { useNavigate } from "react-router-dom";
import { Button, Form, Modal } from "react-bootstrap";
import { RiMailSendLine } from "react-icons/ri";
import { useTranslation } from "react-i18next";


const MyAccount = () => {
    const { t } = useTranslation();
    const usercontext = useContext(User)
    const IdUser = usercontext.auth.userDetals._id
    const role = usercontext.auth.userDetals.role
    const token = usercontext.auth.token
    const [dataaForgot, setDataaForgot] = useState()
    const [dataa, setDataa] = useState()
    const [errMsgForgot, setErrMsgForgot] = useState()
    const [emailVerify, setEmailVerify] = useState()
    const [showVerify, setShowVerify] = useState(false);
    const [statusSendEmail, setStatusSendEmail] = useState(<><RiMailSendLine style={{ fontSize: "20px", paddingBottom: "4px" }} />  {t("Send Email")}</>)

    const nav = useNavigate()
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/users/GetUser/${IdUser}`, {
            headers: {
                Authorization: "Bearer " + token,
            }
        })
            .then((doc) => { setDataa(doc.data) })
            .catch((err) => console.log("err get user : ", err))
    }, [])
    console.log(role)
    const UpDateUser = () => {
        if (role == "admin") {
            nav(`/admin/user/UpDateUser/${IdUser}`, { state: { dataa } })
        } else {
            nav(`/cline/user/UpDateUser/${IdUser}`, { state: { dataa } })
        }
    }
    const dataVerify = { email: emailVerify, type: "edit", oldEmail: dataa?.email, role }

    const btnVerify = async (event) => {
        event.preventDefault();
        setStatusSendEmail(<><LoadingBtn /></>)
        await axios.patch(`${process.env.REACT_APP_API_URL}/auth/editEmail`, dataVerify)
            .then((doc) => {
                setDataaForgot(doc.data)
                setStatusSendEmail(<><RiMailSendLine style={{ fontSize: "20px", paddingBottom: "4px" }} />  Send Email</>)
            })
            .catch((err) => {
                setStatusSendEmail(<><RiMailSendLine style={{ fontSize: "20px", paddingBottom: "4px" }} />  Send Email</>)
                {
                    if (err.response.data.errors) {
                        setErrMsgForgot(err.response.data)
                    } { console.log(err) }
                }
            })
    }

    const handleKeyPressVerify = (event) => {
        if (event.key === 'Enter') {
            btnVerify(event);
        }
    };

    return (<>
        <div>
            <div id="allPage">
                <div id="PageAdmin" style={{ width: "94%", margin: "0 3%" }}>
                    {dataa ?
                        <div style={{ width: "100%", padding: "5%" }}>
                            <div id="H1Login">
                                <h1>{t("Account Information")}</h1>
                            </div>
                            <li id="liUserId"><div>• {t("CreateUser.User")} : </div>{dataa.user}.</li>
                            <div style={{ display: "flex", }}>
                                <li id="liUserId" style={{ display: 'flex', overflowX: "auto", maxWidth: "420px", alignItems: "flex-start" }}>
                                    <div>• {t("Email address")} : </div><div>{dataa.email}.</div>
                                </li>
                                <Button style={{ backgroundColor: "white", color: "red", border: "none" }} onClick={() => setShowVerify(true)}>{t("Edit")}</Button>
                            </div>
                            <li id="liUserId"><div>• {t("CreateUser.Phone")} : </div>{dataa.phone}.</li>
                            {/* <li id="liUserId"><div>• {t("Country")} : </div>{dataa.select}.</li> */}
                            <li id="liUserId"><div>• {t("CreateUser.Date")} : </div>{dataa.date}.</li>
                            <div class="col-12" style={{ display: "flex", justifyContent: "center", paddingTop: "2%", borderTop: "1px solid #c5c2c2" }}>
                                <Button bsPrefix className={"ButtonBNB"} style={{ width: "200px" }} onClick={UpDateUser}> {t("UpDate User")}</Button>
                            </div>
                        </div>
                        : <Loading />}
                </div>
            </div>
        </div>
        <Modal show={showVerify} onHide={() => setShowVerify(false)} style={{ display: 'flex', justifyContent: "space-between" }}>
            <Modal.Header style={{ display: "flex", justifyContent: "space-between" }} >
                <Modal.Title>{t("Email verification")} </Modal.Title>
                <Button variant="close" onClick={() => setShowVerify(false)} />
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>{t("Email address")}</Form.Label>
                        <Form.Control onKeyDown={handleKeyPressVerify} type="email" placeholder="name@example.com" autoFocus onChange={e => setEmailVerify(e.target.value)} />
                        <div><div id="err" style={{ color: "red", backgroundColor: '#ffdddd', padding: "0 5px" }}>{errMsgForgot ? errMsgForgot.errors.map(error => error.msg).join("<br>") : ''}</div></div>
                        <div><div id="success" style={{ color: "green", backgroundColor: '#bff5bf', padding: "0 5px" }}>{dataaForgot ? dataaForgot.message : ''}</div></div>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowVerify(false)}> {t("Close")} </Button>
                <Button bsPrefix className={"ButtonBNB"} style={{ width: "120px", padding: '3px 7px 0 0', height: '35px', marginRight: "10px" }} onClick={btnVerify}><div style={{ marginBottom: "5px" }}>{statusSendEmail}</div> </Button>
            </Modal.Footer>
        </Modal>
    </>)
}
export default MyAccount;