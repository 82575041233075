import React, { useState } from 'react';
import './App.css';

function App() {
  // إنشاء حالة للتحكم في حالة الزر
  const [isDisabled, setIsDisabled] = useState(false);

  // دالة لتبديل حالة الزر
  const toggleButton = () => {
    setIsDisabled(!isDisabled);
  };

  return (
    <div className="App">
      <h1>تفعيل/تعطيل الزر في React</h1>
      <button onClick={toggleButton}>
        {isDisabled ? 'تفعيل الزر' : 'تعطيل الزر'}
      </button>
      <button disabled={isDisabled}>
        زر قابل للتفعيل/التعطيل
      </button>
    </div>
  );
}

export default App;

