import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Loading } from '../../refreshPage/loading';
import { useTranslation } from "react-i18next";
import RefundCard from './RefundCard';
import { Button } from 'react-bootstrap';
import axios from 'axios';

const Invoice = () => {
    const { t, i18n } = useTranslation();
    const [dataOrder, setDataOrder] = useState()
    const [statusBtn, setStatusBtn] = useState(t("Request Refund"))
    const [err, setErr] = useState()

    const Location = useLocation()
    const { Order } = Location.state || {}

    let params = useParams()

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/clineArticales/GetOrder/${params.id}`)
            .then((doc) => { setDataOrder(doc.data) })
            .catch((err) => console.log("err", err))
    }, [statusBtn])

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 500);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const DataPatchOrderRefund = { cancelOrder: true, status: { ar: "الغاء", he: "ביטול" }, dataOrder }

    const BtnRefundCash = async () => {
        setStatusBtn(t("Returning..."))
        await axios.patch(`${process.env.REACT_APP_API_URL}/clineArticales/PatchOrderRefund/${dataOrder._id}`, DataPatchOrderRefund)
            .then((doc) => {
                setStatusBtn(t("Refund Successful"))
            })
            .catch((err) => setErr(err))
    }

    const dataCart = dataOrder?.cart.map((item) => ({
        _id: item._id,
        category: i18n.language === "ar" ? item.category.ar : item.category.he,
        name: i18n.language === "ar" ? item.name.ar : item.name.he,
        title: i18n.language === "ar" ? item.title.ar : item.title.he,
        information: i18n.language === "ar" ? item.information.ar : item.information.he,
        price: item.price,
        newPricee: item.newPricee,
        discount: item.discount,
        number: item.number,
        quantity: item.quantity,
        file: item.file,
    }))

    return (<>
        <div id="allPage">
            <div id={"PageAdmin"} style={{ width: isMobile ? "95%" : "60%", margin: isMobile ? "0 2.5%" : "0 20%" }}>
                {dataOrder ?
                    <div style={{ marginTop: "30px", border: "10px", width: "90%" }}>
                        <div style={{ display: "flex", justifyContent: "space-around", textAlign: "start", paddingBottom: "2%" }}>
                            <div>
                                <div>{t("Name")} : {dataOrder.UserDetails.name}</div>
                                <div>{t("Orders.Orderdetails.Order Id")} : {Order._id}</div>
                            </div>
                            <div>
                                <div>{t("CreateUser.Phone")} :  {dataOrder.UserDetails.phone}</div>
                                <div>{t("Orders.Date Order")} : {dataOrder.DateOrder}</div>
                            </div>
                        </div>
                        <div style={{ borderTop: "1px solid #dbdada ", padding: "2% 0" }}>
                            <table class="table  table-hover table-light " style={{ fontSize: "20px", height: "10px", width: "96%", backgroundColor: "white", marginLeft: "2%", borderRadius: "5px", boxShadow: "2px 2px 5px 0 rgb(219, 218, 218)", }}>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>{t("Orders.Orderdetails.item")}</th>
                                        <th>{t("Orders.Orderdetails.quantity")}</th>
                                        <th>{t("Price")}</th>
                                    </tr>
                                </thead>
                                {dataCart.map((item, index) => (<>
                                    <tbody style={{ padding: "0" }}>
                                        <tr key={index} style={{ padding: "0" }} >
                                            <td >{index + 1}</td>
                                            <td >{item.title}</td>
                                            <td >{item.quantity}</td>
                                            {/* <td >{item.price} $</td> */}
                                            <td style={{ display: "flex", flexDirection: 'column' }}>
                                                {item.newPricee && <div style={{ fontWeight: "400", fontSize: "13px", textDecoration: 'line-through', color: "#818181" }}>
                                                    {item.price}₪
                                                </div>}
                                                {(item.newPricee || item.price)}₪
                                            </td>
                                        </tr>
                                    </tbody>
                                </>))}
                            </table >
                        </div>
                        <div style={{ display: 'flex', color: 'red', borderTop: "1px solid #dbdada ", justifyContent: "space-evenly", padding: "2% 0" }}>
                            <div >{t("Orders.Orderdetails.Total Price")} :   {dataOrder.totalPrice} {" ₪  "}</div>
                            <div > {t("Orders.Payment.Payment")}: {i18n.language == "ar" ? dataOrder.payment.ar : dataOrder.payment.he} {" "}</div>
                        </div>
                        <div style={{ borderTop: "1px solid #dbdada ", padding: " 2% 0 " }}>{t("Orders.Orderdetails.Receipt")} : {i18n.language == "ar" ? dataOrder.receipt.ar : dataOrder.receipt.he} {" "}</div>
                        <div style={{ borderTop: "1px solid #dbdada ", paddingTop: "2%" }}>
                            <div style={{ textAlign: "start", marginLeft: "20%", marginRight: "20%", paddingBottom: "2px" }}>{t("Orders.Orderdetails.Receipt Address")} :</div>
                            <div style={{ border: "1px solid ", borderRadius: "5px", width: "60%", margin: "0 20%", textAlign: "right", padding: "2%" }}>
                                <div >
                                    {dataOrder.receiptAddress.city} {" "},
                                    {dataOrder.receiptAddress.district} {" "}.
                                </div>
                                <div>
                                    {t("Orders.Orderdetails.Street")} {dataOrder.receiptAddress.street} {" "},
                                    {t("Orders.Orderdetails.Architecture")} {dataOrder.receiptAddress.architectureName} {" "},
                                    {t("Orders.Orderdetails.Floor")} {dataOrder.receiptAddress.floorNumber} {" "},
                                    {t("Orders.Orderdetails.Apartment")}  {dataOrder.receiptAddress.apartmentNumber} {" "}.
                                </div>
                            </div>
                        </div>
                        <div style={{ marginTop: "20px" }}>
                            {(dataOrder.status.ar === "الغاء" ?
                                (<div style={{ color: "red" }}>
                                    {t("the request has been canceled")}
                                </div>)
                                :
                                ((dataOrder.status.ar === "جديد" || dataOrder.status.ar === "مشاهد") && (dataOrder.payment.ar === "بطاقة" ?
                                    <div>
                                        <RefundCard Order={dataOrder} />
                                    </div>
                                    :
                                    <div>
                                        <Button bsPrefix className={"ButtonBNB"} onClick={BtnRefundCash} style={{ width: "150px", height: "40px", bottom: "0" }}>{statusBtn}</Button>
                                    </div>)))
                               
                            }
                            { (dataOrder.status.ar !== "الغاء" && dataOrder.status.ar !== "جديد" && dataOrder.status.ar !== "مشاهد") &&
                                <div style={{color:'red'}}>
                                    {t("Your order")} {i18n.language == "ar" ? dataOrder.status.ar : dataOrder.status.he}
                                </div>}
                        </div>
                        <div style={{ color: "red" }}>{err?.response?.data?.errors[0].msg}</div>
                    </div>
                    : <Loading />
                }
            </div>
        </div>
    </>)
}
export default Invoice;