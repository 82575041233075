import { Accordion, Button, Nav, Offcanvas, Stack } from "react-bootstrap";
import { useShoppingCart } from "../context/shoppingCartContext";
import CartItem from "../cline/market/CartItem";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import ProgressBar from 'react-bootstrap/ProgressBar';

const CartComponent = () => {
    const { cartItems, cartQuantity } = useShoppingCart()
    const { t } = useTranslation()
    const nav = useNavigate()

    const [getPriceDelevary, setGetPriceDelevary] = useState()
    const [data, setData] = useState()

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/clineArticales/GetArticales`)
            .then((doc) => setData(doc.data))
            .catch((err) => console.log("err : ", err))

        axios.get(`${process.env.REACT_APP_API_URL}/clineArticales/GetAnOfferPriceDelevary`)
            .then((doc) => setGetPriceDelevary(doc.data))
            .catch((err) => console.log(err))
    }, [])

    const btnFinishMarket = () => {
        nav("/cline/Articales/FinishMarket")
    }
    const Discount = cartItems.reduce((total, cartitem) => {
        const item = data && data.find((i) => i._id === cartitem.id)
        const Discountprice = item?.price - item?.newPricee
        return total + (Discountprice || 0) * cartitem.quantity;
    }, 0)

    const PriceFreeDelivery = getPriceDelevary?.priceDelevaryOffer

    const now1 = cartItems.reduce((total, cartitem) => {
        const item = data && data.find((i) => i._id === cartitem.id);
        const itemTotal = (item?.newPricee || item?.price || 0) * cartitem.quantity;
        return total + itemTotal;
    }, 0);

    const discountPercentage = ((now1 / PriceFreeDelivery) * 100) >= 100 ? 100 : (now1 / PriceFreeDelivery) * 100;
    const now = discountPercentage.toFixed(0)

    const dataDelivary = getPriceDelevary?.priceDelevary
    const Delivery = cartQuantity == 0 ? 0 : now == 100 ? 0 : dataDelivary

    return (<>
        <Nav className="NavDisktop" style={{ width: "20%", maxWidth: "500px", borderRight: "solid 1px rgb(219, 218, 218)", margin: "0", backgroundColor: "white", position: "fixed", minHeight: "100vh", boxShadow: "1px 1px 11px 2px #dfdfdf" }}>
            <Accordion style={{ width: "100%" }} alwaysOpen >
                <div>
                    <div style={{ width: "100%", textAlign: "center", fontWeight: "500", height: "5vh", marginTop: '10px', marginBottom: "20px" }}>
                        <div>{t("Cart")}</div>
                        {cartQuantity == 0 &&
                            <div style={{ width: "100%", textAlign: "center", fontWeight: "300", height: "20vh" }}>
                                <div>{t("Start adding items to your cart")}</div>
                            </div>
                        }
                    </div>
                    <div style={{ width: "100%", maxHeight: "50vh", overflowY: "auto", padding: "10px" }}>
                        <div>
                            {cartItems.map((item) =>
                                <CartItem key={item.id} {...item} />
                            )}
                        </div>
                        <div style={{ display: 'flex', justifyContent: "space-between", padding: "0 10px" }}>
                            <div>
                                {t("Subtotal")}
                            </div>
                            <div>
                                {cartItems.reduce((total, cartitem) => {
                                    const item = data && data.find((i) => i._id === cartitem.id)
                                    return total + (item?.newPricee || item?.price || 0) * cartitem.quantity;
                                }, 0)} ₪
                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: "space-between", padding: "0 10px" }}>
                            <div>
                                {t("FinishMarket.Delivery")}
                            </div>
                            <div>
                                {cartQuantity == 0 ? <div style={{ color: "#ea004b" }}>{t("free")}</div> : now == 100 ? <div style={{ color: "#ea004b" }}>{t("free")}</div> : <div> {getPriceDelevary?.priceDelevary} ₪</div>}
                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: "space-between", padding: "0 10px", borderBottom: "1px solid #b9b9b9" }}>
                            <div>
                                {t("Discount")}
                            </div>
                            <div>
                                {Discount}  ₪
                            </div>
                        </div>
                    </div>
                    {now == 0 ? null : <>
                        <div style={{ display: "flex", textAlign: 'center', justifyContent: "center" }}>
                            {now == 100 ? <div style={{ color: "#ea004b" }} >{t("Free delivery")}</div> : <>{t('Purchase to get free delivery', { price: PriceFreeDelivery })}</>}
                        </div>
                        <div style={{ display: "flex", justifyContent: 'center', marginTop: "10px" }}>
                            <ProgressBar variant="danger" style={{ width: "80%" }} now={now} label={`${now}%`} />
                        </div>
                    </>
                    }
                    <div>
                        <div className="ms-auto fw-bold fs-9" style={{ bottom: "50px", display: 'flex', justifyContent: "space-around", marginTop: "20px" }}>
                            <div>
                                {t("Total")}{" "}
                            </div>
                            <div>
                                {cartItems.reduce((total, cartitem) => {
                                    const item = data && data.find((i) => i._id === cartitem.id)
                                    return total + (item?.newPricee || item?.price || 0) * cartitem.quantity;
                                }, 0) + Delivery} ₪
                            </div>
                        </div>
                    </div>
                    <div style={{ width: "90%", margin: "5% 5%", bottom: "0" }}>
                        <Button bsPrefix className={"ButtonBNB"} style={{ width: "100%", height: "40px", bottom: "0" }} disabled={!cartQuantity} onClick={btnFinishMarket}>{t("Pay")}</Button>
                    </div>
                </div>
            </Accordion>
        </Nav>
    </>)
}

export default CartComponent;