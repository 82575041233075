import React, { useEffect, useState } from 'react';
import img from '../file/Screenshot_2024-07-06_at_05.39.59.png'
import { FaFacebook } from "react-icons/fa";
import { FaSquareInstagram } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";
import { ModalFooter } from 'react-bootstrap';
// import './Footer.css';



function Footer() {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 500);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (<>
        <div style={{ minHeight: "100px", backgroundColor: "white", boxShadow: "#d7d7d7 1px -1px 20px 0px", position: "relative", zIndex: 5, display: "flex", justifyContent: 'center' }}>
            <div style={{ display: 'flex', flexDirection: isMobile ? "column" : 'row-reverse', alignItems: "center", maxWidth: "1414px", padding: isMobile ? 0 : "0 40px", width: "100%", justifyContent: isMobile && "center" }}>
                <div style={{ maxHeight: "50px", width: '100%', display: "flex", flexDirection: "row-reverse", alignItems: 'center', justifyContent: isMobile && "center" }}>
                    <img style={{ maxHeight: "30px" }} src={img} />
                </div>
                <div style={{ display: "flex", gap: "15px", fontSize: "20px" }}>
                    <a href="https://www.facebook.com/profile.php?id=61562134475130">
                        <FaFacebook style={{ color: "#0866ff" }} />
                    </a>
                    <a href="https://www.instagram.com/hldsh_tech/">
                        <FaSquareInstagram style={{ color: "#ea004b" }} />
                    </a>
                    <a href="">
                        <FaLinkedin style={{ color: "#0a66c2" }} />
                    </a>
                </div>
            </div>
        </div>
    </>)
}

export default Footer