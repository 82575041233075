import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';


const VerifyEditeEmail = () => {
    const [err, setErr] = useState()
    let params = useParams()
    const nav = useNavigate()
    useEffect(() => {
        axios.patch(`${process.env.REACT_APP_API_URL}/auth/editEmail/${params.token}/${params.type}/${params.oldEmail}/${params.role}`)
            .then(() => {
                if (params.role == "admin") {
                    nav(`/admin/user/GetUserId`)
                } else {
                    nav(`/cline/user/GetUserId`)
                }
            })
            .catch((err) => { { console.log(err) } { setErr(err) } })
    })
    return (<>
        <div style={{ marginTop: "165px", width: '100%', textAlign: "center", fontSize: "30px" }}>
            <div style={{ color: "red" }}>{err?.response?.data?.errors[0].msg}</div>
        </div>
    </>)
}

export default VerifyEditeEmail;