import axios from "axios";
import { useContext, useEffect, useState } from "react";
import "../../cline/market/Articales.css"
import Swal from "sweetalert2";
import { User } from "../../context/context";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";


function UpDateArticale() {
    const { t, i18n } = useTranslation();

    const usernaw = useContext(User)
    const token = usernaw.auth.token

    const nav = useNavigate()
    const location = useLocation()
    const { dataa } = location.state || {}

    // const [nameeAr, setNameeAr] = useState(dataa.name.ar ? dataa.name.ar : "");
    // const [nameeHe, setNameeHe] = useState(dataa.name.he ? dataa.name.he : "");
    const [titleeAr, setTitleeAr] = useState(dataa.title.ar);
    const [titleeHe, setTitleeHe] = useState(dataa.title.he);
    const [informationnAr, setInformationnAr] = useState(dataa.information.ar);
    const [informationnHe, setInformationnHe] = useState(dataa.information.he);

    const [pricee, setPricee] = useState(dataa.price);
    const [numberr, setNumberr] = useState(dataa.number);
    const [discount, setDiscount] = useState(dataa.discount);
    const [newPricee, setNewPricee] = useState(dataa.newPricee);
    const [isOn, setIsOn] = useState(false);
    const [errMsg, setErrMag] = useState()

    const [emgg, setEmg] = useState(null);
    const [dataaa, setDataaa] = useState([]);
    const [category, setCategory] = useState(dataa?.category);

    const categoryLanguage = (e)=>{
        console.log(e)
        const LCategory = JSON.parse(e);
        setCategory(LCategory)
    }

    useEffect(() => {
        if (pricee && newPricee) {
            const oldP = parseFloat(pricee);
            const newP = parseFloat(newPricee);
            const discountPercentage = ((oldP - newP) / oldP) * 100;
            setDiscount(discountPercentage.toFixed(0));
        }
        axios.get(`${process.env.REACT_APP_API_URL}/articales/GetCategoryMarket`, {
            headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "multipart/form-data"
            }
        })
            .then((response) => {
                setDataaa(response.data);
                if (!category) {
                    setCategory(response.data[0] ? response.data[0].name : '');
                }
            })
            .catch((error) => {
                console.log("Error fetching categories:", error);
            });
        setErrMag()
    }, [pricee, newPricee])

    useEffect(() => {
        setErrMag()
    }, [informationnHe, informationnAr, titleeHe, titleeAr,/* nameeHe, nameeAr,*/ emgg])

    const handleClick = () => {
        setIsOn(!isOn);
    };

    async function btnInsert() {
        if (/*!nameeAr || !nameeHe ||*/ !titleeAr || !titleeHe || !informationnAr || !informationnHe || !pricee || !numberr) {
            setErrMag(t("Please fill out the fields"));
            return;
        }
        if (Number(newPricee) >= Number(pricee)) {
            setErrMag(t("The new price must be lower than the old price"));
            return;
        }

        const formDataToSend = new FormData();
        // formDataToSend.append("name_ar", nameeAr);
        // formDataToSend.append("name_he", nameeHe);
        formDataToSend.append("title_ar", titleeAr);
        formDataToSend.append("title_he", titleeHe);
        formDataToSend.append("information_ar", informationnAr);
        formDataToSend.append("information_he", informationnHe);
        formDataToSend.append("category_ar", category.ar);
        formDataToSend.append("category_he", category.he);
        formDataToSend.append("number", numberr);
        formDataToSend.append("price", pricee);
        formDataToSend.append("Offers", isOn);

        if (isOn) {
            formDataToSend.append("newPricee", newPricee);
            formDataToSend.append("discount", discount);
        }

        // Add files only if there are selected files
        if (emgg && emgg.length > 0) {
            for (let i = 0; i < emgg.length; i++) {
                formDataToSend.append("file", emgg[i]);
            }
        }

        await axios.patch(`${process.env.REACT_APP_API_URL}/articales/PatchArticale/${dataa._id}`, formDataToSend, {
            headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "multipart/form-data"
            }
        })
            .then(async (doc) =>
                await Swal.fire({
                    icon: 'success',
                    title: '<h1> Success </h1> <br /> تم تعديل المنتج ',
                    showConfirmButton: false,
                    timer: 1500,
                }))
                .then(()=>{nav("/admin/Home/Home4")})
            .catch((err) => console.log("err Post : ", err));
    }

    return (
        <div id="allPage">
            <div id="PageAdmin" >
                <div id="H1Login">
                    <h1>{t("ProductId.Up Date Product")}</h1>
                </div>

                <label>{t("Category Market Name")}</label>
                <select className="form-select form-select-lg mb-3" aria-label="Large select example" style={{ width: "80%" }} onChange={(e) => categoryLanguage(e.target.value)}  defaultValue={JSON.stringify(dataa.category)} >
                    {dataaa.map((item, index) => <option key={index} value={JSON.stringify(item.name)}>{i18n.language === "ar" ? item.name.ar : item.name.he}</option>)}
                </select>


                <div style={{ width: "80%", display: "flex" }}>
                    {/* <div className="mb-3" style={{ width: "48%", margin: "0 0 0 2%" }}>
                        <label htmlFor="exampleFormControlInput1" className="form-label">{t("ProductId.name")}</label>
                        <input type="text" className="form-control" id="exampleFormControlInput1" onChange={(e) => setNameeAr(e.target.value)} placeholder="بالعربية" defaultValue={dataa.name.ar} />
                        <input type="text" style={{ marginTop: "5px" }} className="form-control" id="exampleFormControlInput1" onChange={(e) => setNameeHe(e.target.value)} placeholder="בעברית" defaultValue={dataa.name.he} />
                    </div> */}
                    <div className="mb-3" style={{ width: "48%", margin: "0 2% 0 0" }}>
                        <label htmlFor="exampleFormControlInput1" className="form-label">{t("ProductId.title")}</label>
                        <input type="text" className="form-control" id="exampleFormControlInput1" onChange={(e) => setTitleeAr(e.target.value)} placeholder="بالعربية" defaultValue={dataa.title.ar} />
                        <input type="text" style={{ marginTop: "5px" }} className="form-control" id="exampleFormControlInput1" onChange={(e) => setTitleeHe(e.target.value)} placeholder="בעברית" defaultValue={dataa.title.ar} />
                    </div>
                </div>
                <div className="mb-3" style={{ width: "80%" }}>
                    <label className="form-label">{t("ProductId.information")}</label>
                    <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" onChange={(e) => setInformationnAr(e.target.value)} placeholder="بالعربية" defaultValue={dataa.information.ar}></textarea>
                    <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" onChange={(e) => setInformationnHe(e.target.value)} placeholder="בעברית" defaultValue={dataa.information.he}></textarea>
                </div>

                <div className="form-floating mb-3" style={{ display: 'flex', alignItems: 'center', width: "80%" }}>
                    <div style={{ marginLeft: '10%' }}>
                        عرض ؟
                    </div>

                    <button onClick={handleClick} style={{
                        padding: '5px 20px',
                        fontSize: '16px',
                        backgroundColor: isOn ? 'green' : 'red',
                        color: 'white',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer'
                    }}>
                        {isOn ? 'Yas' : 'No'}
                    </button>
                </div>
                {isOn ?
                    <div style={{ width: "80%", display: "flex" }}>
                        <div className="form-floating mb-3">
                            <input type="number" className="form-control" id="floatingInputPrice" onChange={(e) => setPricee(e.target.value)} placeholder="0" defaultValue={isOn ? dataa.price : null} />
                            <label htmlFor="floatingInputPrice">{t("old price")}</label>
                        </div>
                        <div className="form-floating mb-3">
                            <input type="number" className="form-control" id="floatingInputNumber" onChange={(e) => setNewPricee(e.target.value)} placeholder="0" defaultValue={isOn ? dataa.newPricee : null} />
                            <label htmlFor="floatingInputNumber">{t("New price")}</label>
                        </div>
                        <div className="form-floating mb-3" style={{ display: "flex", justifyContent: "center", alignItems: 'center' }}>
                            {t("Discount")} : {discount} %
                        </div>
                        <div className="form-floating mb-3">
                            <input type="number" className="form-control" id="floatingInputNumber" onChange={(e) => setNumberr(e.target.value)} placeholder="0" defaultValue={isOn ? dataa.number : null} />
                            <label htmlFor="floatingInputNumber">{t("ProductId.Existing quantity")}</label>
                        </div>
                    </div>
                    :
                    <div style={{ width: "80%", display: "flex" }}>
                        <div className="form-floating mb-3">
                            <input type="number" className="form-control" id="floatingInputPrice" onChange={(e) => setPricee(e.target.value)} placeholder="0" defaultValue={isOn ? null : dataa.price} />
                            <label htmlFor="floatingInputPrice">{t("Price")}</label>
                        </div>
                        <div className="form-floating mb-3">
                            <input type="number" className="form-control" id="floatingInputNumber" onChange={(e) => (setNumberr(e.target.value))} placeholder="0" defaultValue={isOn ? null : dataa.number} />
                            <label htmlFor="floatingInputNumber">{t("ProductId.Existing quantity")}</label>
                        </div>
                    </div>
                }
                <div style={{ color: "red", fontSize: "20px", marginBottom: "5px", fontWeight: "500" }}>
                    {errMsg}
                </div>
                <div class="form-floating mb-3">
                    <input type="file" class="form-control" id="floatingInputNumber" onChange={(e) => setEmg(e.target.files)} multiple placeholder="name@example.com" />
                    <label for="floatingInputNumber">{t("ProductId.emg")}</label>
                    <div className="errMsgInbut" id="errphone"></div>
                </div>

                <div class="col-12">
                    <Button bsPrefix className={"ButtonBNB"} style={{ width: "200px" }} onClick={btnInsert}> {t("ProductId.Up Date Product")}</Button>
                </div>
            </div>
        </div>
    )
}

export default UpDateArticale;
