import { Button, Offcanvas, ProgressBar, Stack } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useShoppingCart } from "../../context/shoppingCartContext";
import CartItem from "./CartItem";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useTranslation, initReactI18next } from "react-i18next";


const ShoppingCart = ({ isOpen }) => {
    const { t, i18n } = useTranslation();
    const { cartItems, closeCart, cartQuantity } = useShoppingCart()
    const [dataa, setDataa] = useState()
    const [getPriceDelevary, setGetPriceDelevary] = useState()

    const nav = useNavigate()
    useEffect(() => {
        belal()
    }, [])
    const belal = (() => {
        axios.get(`${process.env.REACT_APP_API_URL}/clineArticales/GetArticales`)
            .then((doc) => setDataa(doc.data))
            .catch((err) => console.log("err Get : ", err))
        axios.get(`${process.env.REACT_APP_API_URL}/clineArticales/GetAnOfferPriceDelevary`)
            .then((doc) => setGetPriceDelevary(doc.data))
            .catch((err) => console.log(err))
    })
    const btnFinishMarket = () => {
        nav("/cline/Articales/FinishMarket")
        closeCart()
    }
    const now1 = cartItems.reduce((total, cartitem) => {
        const item = dataa && dataa.find((i) => i._id === cartitem.id);
        const itemTotal = (item?.newPricee || item?.price || 0) * cartitem.quantity;
        return total + itemTotal;
    }, 0);
    
    const PriceFreeDelivery = getPriceDelevary?.priceDelevaryOffer
    const discountPercentage = ((now1 / PriceFreeDelivery) * 100) >= 100 ? 100 : (now1 / PriceFreeDelivery) * 100;
    const now = discountPercentage.toFixed(0)

    const Discount = cartItems.reduce((total, cartitem) => {
        const item = dataa && dataa.find((i) => i._id === cartitem.id)
        const Discountprice = item?.price - item?.newPricee
        return total + (Discountprice || 0) * cartitem.quantity;
    }, 0)

    const dataDelivary = getPriceDelevary?.priceDelevary
    const Delivery = cartQuantity == 0 ? 0 : now == 100 ? 0 : dataDelivary


    const isRtl = i18n.dir() === 'rtl';

    return (
        <Offcanvas show={isOpen} onHide={closeCart} placement="end" className={isRtl ? 'offcanvas-rtl' : ''}>
            <Offcanvas.Header style={{ display: 'flex', justifyContent: "space-between" }} closeButton>
                <Offcanvas.Title>{t("Cart")}</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <div style={{ width: "100%", maxHeight: "50vh", overflowY: "auto", padding: "10px" }}>
                    <div>
                        {cartItems.map((item) =>
                            <CartItem key={item.id} {...item} />
                        )}
                    </div>
                    <div style={{ display: 'flex', justifyContent: "space-between", padding: "0 10px" }}>
                        <div>{t("Subtotal")}</div>
                        <div>
                            {cartItems.reduce((total, cartitem) => {
                                const item = dataa && dataa.find((i) => i._id === cartitem.id)
                                return total + (item?.newPricee || item?.price || 0) * cartitem.quantity;
                            }, 0)} ₪
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: "space-between", padding: "0 10px" }}>
                        <div>{t("FinishMarket.Delivery")}</div>
                        <div>
                            {cartQuantity === 0 ? <div style={{ color: "#ea004b" }}>{t("free")}</div> : now == 100 ? <div style={{ color: "#ea004b" }}>{t("free")}</div> : <div> {dataDelivary} ₪</div>}
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: "space-between", padding: "0 10px", borderBottom: "1px solid #b9b9b9" }}>
                        <div>{t("Discount")}</div>
                        <div>{Discount} ₪</div>
                    </div>
                </div>
                {now != 0 && (
                    <>
                        <div style={{ display: "flex", textAlign: 'center', justifyContent: "center" }}>
                            {now === 100 ? <div style={{ color: "#ea004b" }} >{t("Free delivery")}</div> : <>{t('Purchase to get free delivery', { price: PriceFreeDelivery })}</>}
                        </div>
                        <div style={{ display: "flex", justifyContent: 'center', marginTop: "10px" }}>
                            <ProgressBar variant="danger" style={{ width: "80%" }} now={now} label={`${now}%`} />
                        </div>
                    </>
                )}
                <div className="ms-auto fw-bold fs-5" style={{ display: 'flex', justifyContent: "space-around", marginTop: "" }}>
                    <div>{t("Total")}{" "}</div>
                    <div>
                        {cartItems.reduce((total, cartitem) => {
                            const item = dataa && dataa.find((i) => i._id === cartitem.id);
                            return total + (item?.newPricee || item?.price || 0) * cartitem.quantity;
                        }, 0) + Delivery} ₪
                    </div>
                </div>
            </Offcanvas.Body>
            <div style={{ width: "90%", margin: "0 5%" }}>
                <Button bsPrefix className={"ButtonBNB"} style={{ width: "100%", height: "40px", bottom: "0" }} disabled={!cartQuantity} onClick={btnFinishMarket}>{t("Pay")}</Button>
            </div>
        </Offcanvas>

    )
}

export default ShoppingCart