import { Link, NavLink, useNavigate } from "react-router-dom";
import "./hederExperience.css";
import React, { useContext, useEffect } from "react";
import Cookies from "universal-cookie";
import axios from "axios";
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { User } from "../context/context";
import { IoLogInOutline } from "react-icons/io5";
import { RiMailSendLine } from "react-icons/ri";
import { LoadingBtn } from "../refreshPage/loading";
import { useTranslation } from "react-i18next";
import { IoMdMenu } from "react-icons/io";
import { Dropdown, DropdownButton } from "react-bootstrap";
import img1 from "../file/images.png"
import img2 from "../file/666555.png"
import { TbShoppingBag } from "react-icons/tb";


function HederExperience() {
    const { t, i18n } = useTranslation();
    const [user, setUser] = useState()
    const [password, setPassword] = useState()
    const [errMsg, setErrMsg] = useState()
    const [errMsgForgot, setErrMsgForgot] = useState()
    const [emailForgot, setEmailForgot] = useState()
    const [emailVerify, setEmailVerify] = useState()
    const [phoneVerify, setPhoneVerify] = useState()
    const [dataaForgot, setDataaForgot] = useState()
    const [dataAther, setDataAther] = useState()
    const [statuslogin, setStatusLogin] = useState(<> {t("login")}</>)
    const [statusSendEmail, setStatusSendEmail] = useState(<><RiMailSendLine style={{ fontSize: "20px", paddingBottom: "4px" }} />  {t("Send Email")}</>)
    const [statusSendEmailPhone, setStatusSendPhone] = useState(<> {t("Send Code")}</>)
    const [statusSendCodePhone, setStatusSendCodePhone] = useState(<> {t("Send Code")}</>)
    const [verificationCode, setVerificationCode] = useState()


    const cookie = new Cookies()
    const usernaw = useContext(User)
    const nav = useNavigate()

    const [showLogin, setShowLogin] = useState(false);
    const handleShowLogin = () => (
        setShowLogin(true),
        handleCloseForgot()
    );
    const handleCloseLogin = () => (
        setShowLogin(false),
        setErrMsg(null)
    );

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 500);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        document.body.dir = i18n.dir()
    }, [])

    const BtnLanguge = (lan) => (
        i18n.changeLanguage(lan),
        window.location.reload()
    )

    const [showForgot, setShowForgot] = useState(false);
    const handleShowForgot = () => (
        setShowForgot(true),
        handleCloseLogin()
    );

    const handleCloseForgot = () => (
        setShowForgot(false),
        setErrMsgForgot(null),
        setDataaForgot(null)
    );

    const [showVerify, setShowVerify] = useState(false);
    const handleShowVerify = () => (
        setShowVerify(true),
        handleCloseLogin()
    );

    const handleCloseVerify = () => (
        setShowVerify(false),
        setErrMsgForgot(null),
        setDataaForgot(null)
    );

    const [showVerifyPhone, setShowVerifyPhone] = useState(false);
    const handleShowVerifyPhone = () => (
        setShowVerifyPhone(true),
        handleCloseLogin()
    );

    const handleCloseVerifyPhone = () => (
        setShowVerifyPhone(false),
        setErrMsgForgot(null),
        setDataaForgot(null)
    );

    const [showVerifySendCodePhone, setShowVerifySendCodePhone] = useState(false);
    const handleShowSendCodeVerifyPhone = () => (
        setShowVerifySendCodePhone(true),
        setShowVerifyPhone(false)
    );

    const handleCloseSendCodeVerifyPhone = () => (
        setShowVerifySendCodePhone(false),
        setErrMsgForgot(null),
        setDataaForgot(null)
    );

    const btnSignup = () => {
        handleShowVerify()
    }

    const handleKeyPressVerify = (event) => {
        if (event.key === 'Enter') {
            btnVerify(event);
        }
    };
    const handleKeyPressVerifyPhone = (event) => {
        if (event.key === 'Enter') {
            btnVerifyPhone(event);
        }
    };
    const handleKeyPressVerifySendCodePhone = (event) => {
        if (event.key === 'Enter') {
            btnVerifyPhone(event);
        }
    };
    const handleKeyPressForgot = (event) => {
        if (event.key === 'Enter') {
            btnForgot(event);
        }
    };
    const handleKeyPressLogin = (event) => {
        if (event.key === 'Enter') {
            btnlog(event);
        }
    };

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/clineArticales/GetAnOfferPriceDelevary`)
            .then((doc) => setDataAther(doc.data))
            .catch(err => console.log('err get Ather : ', err))
    }, [])

    const open = new Date(dataAther?.isoTimeOpenMarket);
    const hours24Open = open.getHours();
    const hours12Open = hours24Open % 12 || 12;
    const formattedHours12Open = String(hours12Open).padStart(2, '0');
    const TimeOpenStatus = hours24Open >= 12 ? { ar: "م", he: "ע" } : { ar: "ص", he: "ב" }
    const openMinutes = String(open.getMinutes()).padStart(2, '0');
    const OpenTime = `${formattedHours12Open}:${openMinutes}${i18n.language == "ar" ? TimeOpenStatus.ar : TimeOpenStatus.he}`

    const close = new Date(dataAther?.isoTimeCloseMarket);
    const hours24Close = close.getHours();
    const hours12Close = hours24Close % 12 || 12;
    const formattedHours12Close = String(hours12Close).padStart(2, '0');
    const TimeCloseStatus = hours24Close >= 12 ? { ar: "م", he: "ע" } : { ar: "ص", he: "ב" }
    const closeMinutes = String(close.getMinutes()).padStart(2, '0');
    const closeTime = `${formattedHours12Close}:${closeMinutes}${i18n.language == "ar" ? TimeCloseStatus.ar : TimeCloseStatus.he}`

    const data = { user: user, password: password }
    const btnlog = async (event) => {
        event.preventDefault();
        setStatusLogin(<LoadingBtn />)
        await axios.post(`${process.env.REACT_APP_API_URL}/auth/login`, data)
            .then(async (doc) => {
                const token = doc.data.token
                const refreshToken = doc.data.retoken
                const userDetals = doc.data.data
                const decoded = doc.data.decoded
                await usernaw.setAuth({ token: token, userDetals: userDetals, decoded: decoded, refreshToken: refreshToken })
                await cookie.set("bearer", { token: token, refreshToken: refreshToken, userDetals: userDetals, decoded: decoded }, { path: "/" })
                if (userDetals.role === "admin") {
                    nav('/admin/Home/Home2')
                } else {
                    nav('/cline/Articales/GetCategoryMarketCline')
                }
            })
            .catch((err) => {
                setStatusLogin(<><IoLogInOutline style={{ fontSize: '21px' }} /> Login</>)
                {
                    if (err.response.data.errors) {
                        setErrMsg(err.response.data)
                    } { console.log(err) }
                }
            })
    }
    const dataForgot = { email: emailForgot }
    const btnForgot = async (event) => {
        event.preventDefault();
        setStatusSendEmail(<><LoadingBtn /></>)
        await axios.patch(`${process.env.REACT_APP_API_URL}/auth/forgotpassword`, dataForgot)
            .then((doc) => {
                setDataaForgot(doc.data)
                setStatusSendEmail(<><RiMailSendLine style={{ fontSize: "20px", paddingBottom: "4px" }} />  {t("Send Email")}</>)
            })
            .catch((err) => {
                setStatusSendEmail(<><RiMailSendLine style={{ fontSize: "20px", paddingBottom: "4px" }} /> {t("Send Email")}</>)
                {
                    if (err.response.data.errors) {
                        setErrMsgForgot(err.response.data)
                    } { console.log(err) }
                }
            })
    }

    const [countdown, setCountdown] = useState(0);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const dataVerify = { email: emailVerify, type: "create" }

    const btnVerify = async (event) => {
        event.preventDefault();
        setStatusSendEmail(<><LoadingBtn /></>)

        await axios.patch(`${process.env.REACT_APP_API_URL}/auth/verifyEmail`, dataVerify)
            .then((doc) => {
                setDataaForgot(doc.data)
                setStatusSendEmail(<><RiMailSendLine style={{ fontSize: "20px", paddingBottom: "4px" }} />  {t("Send Email")}</>)
            })
            .catch((err) => {
                setStatusSendEmail(<><RiMailSendLine style={{ fontSize: "20px", paddingBottom: "4px" }} /> {t("Send Email")}</>)
                {
                    if (err.response.data.errors) {
                        setErrMsgForgot(err.response.data)
                    } { console.log(err) }
                }
            })
        setIsButtonDisabled(true);
        setCountdown(30);
        const timer = setInterval(() => {
            setCountdown(prevCountdown => {
                if (prevCountdown === 1) {
                    clearInterval(timer);
                    setIsButtonDisabled(false);
                }
                return prevCountdown - 1;
            });
        }, 1000);
    }
    const [countdownPhone, setCountdownPhone] = useState(0);
    const [isButtonDisabledPhone, setIsButtonDisabledPhone] = useState(false);

    const dataVerifyPhone = { phoneNumber: phoneVerify }
    const btnVerifyPhone = async (event) => {
        event.preventDefault();
        setStatusSendPhone(<><LoadingBtn /></>)
        await axios.patch(`${process.env.REACT_APP_API_URL}/auth/sendVerifyPhone`, dataVerifyPhone)
            .then(async (doc) => {
                setDataaForgot(doc.data)
                console.log(doc.data)
                setStatusSendPhone(<> success</>)
                await setTimeout(() => {
                    handleShowSendCodeVerifyPhone()
                    setErrMsgForgot()
                    setDataaForgot()
                }, 2000)
            })
            .catch((err) => {
                setStatusSendPhone(<>{t("phone number")}</>)
                {
                    if (err?.response?.data?.errors) {
                        setErrMsgForgot(err.response.data)
                    } { console.log(err) }
                }
            })
    }

    const dataVerifyCodePhone = { Phone: phoneVerify, verificationCode }

    const btnVerifyCodePhone = async (event) => {
        event.preventDefault();
        setStatusSendCodePhone(<><LoadingBtn /></>)
        await axios.post(`${process.env.REACT_APP_API_URL}/auth/verifyPhone`, dataVerifyCodePhone)
            .then(async (doc) => {
                setDataaForgot(doc.data)
                setStatusSendCodePhone(<> success</>)
            })
            .catch((err) => {
                setStatusSendCodePhone(<>{t("Send Code")}</>)
                {
                    if (err?.response?.data?.errors) {
                        setErrMsgForgot(err.response.data)
                    } { console.log(err) }
                }
            })
    }

    const handleLink = (Link) => {
        nav(`${Link}`)
    }

    useEffect(() => {
        cookie.remove("bearer")
        setErrMsgForgot(null)
    }, [dataaForgot])

    return (<>
        <nav style={{ backgroundColor: "white", width: "100%" }}>
            {isMobile ?
                <div className="App" style={{ width: "100%", position: "fixed", zIndex: "6" }}><div>
                    <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "flex-start", width: "100%", position: "fixed" }} >
                        <div style={{ width: "100%", top: "0", maxHeight: "35px", padding: "0", backgroundColor: "white", boxShadow: "0 0 8px #898989 ", display: "flex", justifyContent: "space-between", position: "fixed", minHeight: "45px", alignItems: 'center' }}>
                            <header style={{ display: "flex", minHeight: "45px" }}>
                                <DropdownButton align={"start"} bsPrefix="custom-dropdown-button-mobail-hedar" id="dropdown-basic-button" title={<IoMdMenu style={{ minWidth: "110px", height: "30px" }} />}>
                                    <Dropdown.Header style={{ textAlign: "center", fontWeight: "600", backgroundColor: "#e9e9e9" }}>{t("Menu")}</Dropdown.Header>
                                    <Dropdown.Item onClick={() => handleLink("/")} style={{ textAlign: "start" }} >{t("Market")}</Dropdown.Item>
                                    <Dropdown.Header style={{ textAlign: "center", fontWeight: "600", backgroundColor: "#e9e9e9" }}>Account</Dropdown.Header>
                                    <Dropdown.Item onClick={handleShowLogin} style={{ textAlign: "start" }} >{t("login")}</Dropdown.Item>
                                    <Dropdown.Item onClick={btnSignup} style={{ textAlign: "start" }} >{t("signup")}</Dropdown.Item>
                                </DropdownButton>
                            </header>
                            <div style={{ color: '#ea004b', fontWeight: "500", fontFamily: "inherit" }}>
                                {t("closes in", { closeTime: closeTime })}
                            </div>
                            <div style={{ display: 'flex', margin: "0 20px" }}>
                                <Form.Select style={{
                                    paddingLeft: "40px",
                                    paddingRight: "20px",
                                    width: "80px",
                                    height: "30px",
                                    appearance: "none",
                                    background: `url(${img1}) no-repeat 10px center`,
                                    backgroundSize: "30px 30px, 100%",
                                    backgroundColor: "#ffffff",
                                    textAlign: "center"
                                }} className="language-selector" onChange={(e) => BtnLanguge(e.target.value)} value={i18n.language}>
                                    {/* <option>en</option> */}
                                    <option value={"ar"}>AR</option>
                                    <option value={"he"}>HE</option>
                                </Form.Select>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                :
                <div style={{ position: "fixed", width: "100%", top: "0", zIndex: "3", minHeight: "64px", maxHeight: "64px", padding: "7px 0", backgroundColor: "white", boxShadow: "0 0 8px #898989 ", display: "flex", justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ width: "1440px", padding: "0 40px", display: "flex", justifyContent: 'space-between', alignItems: 'center' }}>
                        <div className="HederExperienceActive" style={{ display: "flex", alignItems: 'center' }}>
                            <NavLink to={"/"} activeClassName="active-link" ><img style={{ maxHeight: "40px", marginTop: "10px" }} src={img2}></img></NavLink>
                        </div>
                        <div style={{ color: '#ea004b', fontWeight: "500", fontFamily: "inherit" }}>
                            {t("The store receives orders from to.", { open: OpenTime, close: closeTime })}
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ margin: "3px 10px 0 10px" }}>
                                <Button variant="outline-liht" onClick={handleShowLogin} className="btn-new-style">
                                    {t("login")}
                                </Button>
                                <Button variant="outline-liht" className="btn-new-style" onClick={btnSignup} >
                                    {t("signup")}
                                </Button>
                            </div>
                            <div style={{ display: 'flex', margin: "0 20px" }}>
                                <Form.Select style={{
                                    paddingLeft: "40px",
                                    paddingRight: "20px",
                                    width: "80px",
                                    height: "35px",
                                    appearance: "none",
                                    background: `url(${img1}) no-repeat 10px center`,
                                    backgroundSize: "30px 30px, 100%",
                                    backgroundColor: "#ffffff",
                                    textAlign: "center"
                                }} className="language-selector" onChange={(e) => BtnLanguge(e.target.value)} value={i18n.language}>
                                    {/* <option>en</option> */}
                                    <option value={"ar"}>AR</option>
                                    <option value={"he"}>HE</option>
                                </Form.Select>
                            </div>
                            <Button variant="outline-liht" className="Button-hedear" >{<TbShoppingBag style={{ fontSize: "25px", color: "#ea004b" }} />} </Button>
                        </div>
                    </div>
                </div>
            }

            <Modal show={showLogin} onHide={handleCloseLogin}>
                <Modal.Header style={{ display: "flex", justifyContent: "space-between" }} closeButton>
                    <Modal.Title>{t("Login Page")} </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={btnlog}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>{t("Email address")}</Form.Label>
                            <Form.Control onKeyDown={handleKeyPressLogin} type="email" placeholder="name@example.com" autoFocus onChange={e => setUser(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label> {t("Password")} </Form.Label>
                            <Form.Control onKeyDown={handleKeyPressLogin} type="password" placeholder={t("Password")} autoFocus onChange={e => setPassword(e.target.value)} />
                            <div id="errMsg" style={{ color: "red" }} dangerouslySetInnerHTML={{
                                __html: errMsg ? errMsg.errors.map(error => error.msg).join("<br>") : ''
                            }}></div>
                            <div id="errpassword" style={{ color: "red" }}></div>
                            <div id="errincorrect" style={{ color: "red" }}></div>
                        </Form.Group>
                    </Form>
                    <div style={{ fontSize: "13px" }}>{t("did you forget your password? Click")}<Link onClick={handleShowForgot} style={{ color: "#0d6efd" }}>{t("Forgot your password")}</Link></div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" style={{ width: "80px", padding: '0px 0 0 0', height: '35px' }} onClick={handleCloseLogin}> {t("Close")} </Button>
                    <Button bsPrefix className={"ButtonBNB"} style={{ width: "100px", padding: '2px 7px 3px 0', height: '35px', marginRight: "10px" }} onClick={btnlog}>  {statuslogin} </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showForgot} onHide={handleCloseForgot} style={{ display: 'flex', justifyContent: "space-between" }}>
                <Modal.Header style={{ display: "flex", justifyContent: "space-between" }} >
                    <Modal.Title>{t("Password Recovery")} </Modal.Title>
                    <Button variant="close" onClick={handleCloseForgot} />
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>{t("Email address")}</Form.Label>
                            <Form.Control onKeyDown={handleKeyPressForgot} type="email" placeholder="name@example.com" autoFocus onChange={e => setEmailForgot(e.target.value)} />
                            {errMsgForgot?.errors && <div><div id="err" style={{ color: "red", backgroundColor: '#ffdddd', padding: "10px 5px" }}>{errMsgForgot.errors.map(error => error.msg).join("<br>")}</div></div>}
                            {dataaForgot?.message && <div><div id="success" style={{ color: "green", backgroundColor: '#bff5bf', padding: "10px 5px" }}>{dataaForgot.message}</div></div>}
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseForgot}> {t("Close")} </Button>
                    <Button bsPrefix className={"ButtonBNB"} style={{ width: "120px", padding: '3px 7px 0 0', height: '35px', marginRight: "10px" }} onClick={btnForgot}><div style={{ marginBottom: "5px" }}>{statusSendEmail}</div></Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showVerify} onHide={handleCloseVerify} style={{ display: 'flex', justifyContent: "space-between" }}>
                <Modal.Header style={{ display: "flex", justifyContent: "space-between" }} >
                    <Modal.Title>{t("Email verification")} </Modal.Title>
                    <Button variant="close" onClick={handleCloseVerify} />
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>{t("Email address")}</Form.Label>
                            <Form.Control onKeyDown={handleKeyPressVerify} type="email" placeholder="name@example.com" autoFocus onChange={e => setEmailVerify(e.target.value)} />
                            {errMsgForgot?.errors && <div><div id="err" style={{ color: "red", backgroundColor: '#ffdddd', padding: "10px 5px" }}>{errMsgForgot.errors.map(error => error.msg).join("<br>")}</div></div>}
                            {dataaForgot?.message && <div><div id="success" style={{ color: "green", backgroundColor: '#bff5bf', padding: "10px 5px" }}>{dataaForgot.message}</div></div>}
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseVerify}> {t("Close")} </Button>
                    <Button bsPrefix className={"ButtonBNB"} style={{ width: "120px", padding: '3px 7px 0 0', height: '35px', marginRight: "10px" }} disabled={isButtonDisabled} onClick={btnVerify}><div style={{ marginBottom: "5px" }}>{<div style={{ marginBottom: "5px" }}>
                        {isButtonDisabled ? countdown : statusSendEmail}
                    </div>}</div> </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showVerifyPhone} onHide={handleCloseVerifyPhone} style={{ display: 'flex', justifyContent: "space-between" }}>
                <Modal.Header style={{ display: "flex", justifyContent: "space-between" }} >
                    <Modal.Title>{t("Phone verification")} </Modal.Title>
                    <Button variant="close" onClick={handleCloseVerifyPhone} />
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>{t("phone number")}</Form.Label>
                            <Form.Control onKeyDown={handleKeyPressVerifyPhone} type="email" placeholder="+972456787" autoFocus onChange={e => setPhoneVerify(e.target.value)} />
                            {errMsgForgot?.errors && <div><div id="err" style={{ color: "red", backgroundColor: '#ffdddd', padding: "10px 5px" }}>{errMsgForgot.errors.map(error => error.msg).join("<br>")}</div></div>}
                            {dataaForgot?.message && <div><div id="success" style={{ color: "green", backgroundColor: '#bff5bf', padding: "10px 5px" }}>{dataaForgot.message}</div></div>}
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseVerifyPhone}> {t("Close")} </Button>
                    <Button bsPrefix className={"ButtonBNB"} style={{ width: "120px", padding: '3px 7px 0 0', height: '35px', marginRight: "10px" }} disabled={isButtonDisabledPhone} onClick={btnVerifyPhone}><div style={{ marginBottom: "5px" }}>{<div style={{ marginBottom: "5px" }}>
                        { statusSendEmailPhone}
                    </div>}</div> </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showVerifySendCodePhone} onHide={handleCloseSendCodeVerifyPhone} style={{ display: 'flex', justifyContent: "space-between" }}>
                <Modal.Header style={{ display: "flex", justifyContent: "space-between" }} >
                    <Modal.Title>{t("Phone verification")} </Modal.Title>
                    <Button variant="close" onClick={handleCloseSendCodeVerifyPhone} />
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>{t("Enter the code")}</Form.Label>
                            <Form.Control onKeyDown={handleKeyPressVerifySendCodePhone} type="email" placeholder="485163" autoFocus onChange={e => setVerificationCode(e.target.value)} />
                            {errMsgForgot?.errors && <div><div id="err" style={{ color: "red", backgroundColor: '#ffdddd', padding: "10px 5px" }}>{errMsgForgot.errors.map(error => error.msg).join("<br>")}</div></div>}
                            {dataaForgot?.msg && <div><div id="success" style={{ color: "green", backgroundColor: '#bff5bf', padding: "10px 5px" }}>{dataaForgot.msg}</div></div>}
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseSendCodeVerifyPhone}> {t("Close")} </Button>
                    <Button bsPrefix className={"ButtonBNB"} style={{ width: "120px", padding: '3px 7px 0 0', height: '35px', marginRight: "10px" }} onClick={btnVerifyCodePhone}><div style={{ marginBottom: "5px" }}>{statusSendCodePhone}</div> </Button>
                </Modal.Footer>
            </Modal>
        </nav >
    </>)
}

export default HederExperience;