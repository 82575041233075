import axios from "axios"
import { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { MdPriceChange } from "react-icons/md";
import { Accordion, Form, InputGroup, Nav, Offcanvas, Stack } from "react-bootstrap";
import { IoSearchSharp } from "react-icons/io5";
import PaginatedItems from "../../components/pagination";
import { useTranslation } from "react-i18next";
import { IoFilterSharp } from "react-icons/io5";
import { ModalProduct } from "../../components/MsgComponent";


function CategoryArticale() {
    const { t, i18n } = useTranslation();
    let params = useParams()

    const [search, setSearch] = useState("")
    const [priceCategory, setPriceCategory] = useState()
    const [open, setOpen] = useState(false)
    const [show, setShow] = useState(false)

    const handlePriceCategory = () => {
        setOpen(true)
        setPriceCategory()
    }

    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(50)
    const start = (page - 1) * limit
    const end = Number(start) + Number(limit)
    // const final = dataa && dataa.slice(start, end)

    const [filterData, setFilterData] = useState()
    const dataSaerch = { search: search, category: params.category, priceCategory: priceCategory, page, limit }

    async function getSearchData() {
        await axios.post(`${process.env.REACT_APP_API_URL}/clineArticales/SearchProduct`, dataSaerch)
            .then((doc) => { setFilterData(doc.data) })
            .catch((err) => console.log("err : ", err))
    }

    useEffect(() => {
        const debounce = setTimeout(() => {
            getSearchData()
        }, 800)
        return () => clearTimeout(debounce)
    }, [search, priceCategory, page, limit, show])

    const CloseFilter = () => {
        setOpen(false)
    }

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 500);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (<>
        <div style={{ borderTop: "soled 1px black", marginTop: "65px", display: "flex", backgroundColor: "white" }}>
            <Nav className="NavDisktop" style={{ minHeight: "100vh", width: "15%", borderRight: "solid 1px rgb(219, 218, 218)", margin: "0", backgroundColor: "white", boxShadow: "#cdcdcd -1px -1px 5px", zIndex: "1" }}>
                <Accordion style={{ width: "15%", position: "fixed" }} alwaysOpen >
                    {/* <div style={{ width: "99%", fontSize: "25px", height: "40px", borderBottom: "1px solid ", textAlign: "center" }}>Filter</div> */}
                    <Accordion.Item eventKey="0" style={{ width: "99%" }} >
                        <Accordion.Header style={{ fontSize: "20px", width: "99%", padding: "2px" }}><span style={{ flexGrow: 1, textAlign: "start" }}>{<MdPriceChange style={{ marginRight: "5px", fontSize: "18px" }} />}  {t("Price")} </span> </Accordion.Header>
                        <Accordion.Body>
                            <form onChange={(e) => setPriceCategory(e.target.value)}>
                                <input type="radio" id="All" name="Price" style={{ width: "20%" }} defaultChecked />
                                <label for={"All"} style={{ width: "80%" }}>{t("All")}</label>
                                <input type="radio" id="0-199" name="Price" value="0-199" style={{ width: "20%" }} />
                                <label for={"0-199"} style={{ width: "80%" }}>0 - 199</label>
                                <input type="radio" id="200-499" name="Price" value="200-499" style={{ width: "20%" }} />
                                <label for={"200-499"} style={{ width: "80%" }}>200 - 499</label>
                                <input type="radio" id="500-999" name="Price" value="500-999" style={{ width: "20%" }} />
                                <label for={"500-999"} style={{ width: "80%" }}>500 - 999</label>
                                <input type="radio" id="1000" name="Price" value="1000" style={{ width: "20%" }} />
                                <label for={"1000"} style={{ width: "80%" }}> {t("more than")} 1000</label>
                            </form>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Nav>
            <div className="StyleMobail">
                <div style={{ width: "96%", backgroundColor: "white", margin: "10px 2%", borderRadius: "5px", display: "flex", justifyContent: "center" }}>
                    {isMobile && <><button style={{ backgroundColor: "initial", border: "none" }} onClick={() => { handlePriceCategory() }}> <IoFilterSharp size={"30"} style={{ margin: "5px 5px 5px 0" }} /></button></>}
                    <div className="d-flex" style={{ width: "50%", margin: "10px 25% 0 25%" }}>
                        <Form className="d-flex search-bar" style={{ width: "80%", margin: "0" }}>
                            <InputGroup>
                                <InputGroup.Text style={{ backgroundColor: "white", borderLeft: "none", borderRadius: "0" }}>
                                    <IoSearchSharp style={{ color: "#ea004b", fontSize: "20px" }} />
                                </InputGroup.Text>
                                <Form.Control
                                    type="search"
                                    placeholder={t("Search")}
                                    aria-label="Search"
                                    value={search}
                                    style={{ borderRadius: "0", borderRight: "none", margin: "0", padding: "0" }}
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                            </InputGroup>
                        </Form>
                        {/* </div> */}
                    </div>
                </div>
                <div style={{ backgroundColor: "white", minHeight: "70vh", width: '96%', margin: "0 2% 1% 2%", borderRadius: "5px" }}>
                    <div id="PageUlProduct" >
                        {filterData?.movies?.map((item) =>
                            <Link
                                key={item._id}
                                // to={`/admin/market/getArticales/${item._id}`}
                                onClick={() => { setShow({ show: true, dataa: item, role: "admin" }) }}
                                state={{ dataa: item }}
                                style={{ margin: "1%" }}
                            >
                                <div class="card" style={{ width: "150px", margin: "1%", border: "none", borderRadius: "10px", backgroundColor: "rgb(248, 248, 248)" }}>
                                    <img src={`${process.env.REACT_APP_API_URL}/files/${item.file[0]}`} class="card-img-top" style={{ maxHeight: "300px" }} />
                                    <div class="card-body" style={{ textAlign: "center" }}>
                                        <h5 class="card-title" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>{item.newPricee && <div>{item.newPricee} ₪</div>} <div style={item.newPricee && { fontWeight: "400", fontSize: "13px", textDecoration: 'line-through', color: "#818181" }}>{item.price} ₪</div>  </h5>
                                        {item.discount && <p class="card-text" style={{ textAlign: "center", backgroundColor: "#ea004b", borderRadius: "10px", color: "white" }}>{item.discount}% {t("Discount")}</p>}
                                        <p class="card-text">{i18n.language == "ar" ? item.title.ar : item.title.he}</p>
                                    </div>
                                </div>
                            </Link>
                        )}
                    </div>

                    <div style={{ display: 'flex', justifyContent: "flex-start", marginRight: "2%", alignItems: 'baseline' }}>
                        <Form.Select style={{ width: "80px" }} onChange={(e) => setLimit(e.target.value)}>
                            {/* <option value={20}>20</option> */}
                            {/* <option value={30}>30</option> */}
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                            <option value={150}>150</option>
                        </Form.Select>
                        <PaginatedItems total={filterData && filterData.total} itemsPerPage={limit} setPage={setPage} />
                    </div>
                </div>
            </div>
            <ModalProduct show={show.show} handleClose={() => setShow(false)} dataa={show.dataa} role={show.role} />
            <Offcanvas i18nIsDynamicList={true} show={open} onHide={CloseFilter} placement="end" style={{ width: '150px', height: '100%' }}>
                <Offcanvas.Header closeButton>
                    {/* <Offcanvas.Title> asd</Offcanvas.Title> */}
                    <div className="ms-auto fw-bold fs-5" style={{ bottom: "50px", marginRight: "20%" }}>
                        {t("Filter")}
                    </div>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Stack >
                        <form onChange={(e) => setPriceCategory(e.target.value)}>
                            <input type="radio" id="1All" name="Price1" style={{ width: "20%" }} defaultChecked />
                            <label for={"1All"} style={{ width: "80%" }}>{t("All")}</label>
                            <input type="radio" id="10-199" name="Price1" value="0-199" style={{ width: "20%" }} />
                            <label for={"10-199"} style={{ width: "80%" }}>0 - 199</label>
                            <input type="radio" id="1200-499" name="Price1" value="200-499" style={{ width: "20%" }} />
                            <label for={"1200-499"} style={{ width: "80%" }}>200 - 499</label>
                            <input type="radio" id="1500-999" name="Price1" value="500-999" style={{ width: "20%" }} />
                            <label for={"1500-999"} style={{ width: "80%" }}>500 - 999</label>
                            <input type="radio" id="11000" name="Price1" value="1000" style={{ width: "20%" }} />
                            <label for={"11000"} style={{ width: "80%" }}> {t("more than")} 1000</label>
                        </form>
                    </Stack>
                </Offcanvas.Body>
                <div style={{ width: "90%", margin: "0 5%" }}>
                    {/* <Button variant="outline-success" style={{ width: "100%", bottom: "0" }} onClick={"btnFinishMarket"}> asdsad</Button> */}
                </div>
            </Offcanvas>
        </div>
    </>)
}



export default CategoryArticale