import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';


const BtnVerifyEmail = () => {
    const [err, setErr] = useState()
    let params = useParams()
    const nav = useNavigate()
    useEffect(() => {
        axios.patch(`${process.env.REACT_APP_API_URL}/auth/verifyEmail/${params.token}`)
            // .then(() => nav("/cline/user/GetUserId"))
            .then(() => {
                nav(`/CreateUser/${params.token}`)
            })
            .catch((err) => { { console.log(err) } { setErr(err) } })
    })
    return (<>
        <div style={{ marginTop: "165px", width: '100%', textAlign: "center", fontSize: "30px" }}>
            <div style={{ color: "red" }}>{err?.response?.data?.errors[0].msg}</div>
        </div>
    </>)
}

export default BtnVerifyEmail;