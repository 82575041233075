import React, { useContext, useEffect, useState } from 'react';
import { Form, ToastContainer } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import PaginatedItems from './pagination';
import { useShoppingCart } from '../context/shoppingCartContext';
import { useTranslation } from 'react-i18next';
import { FaPlus } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa";
import axios from 'axios';
import CartComponent from './CartComponent';
import { ModalProduct, MsgToast } from './MsgComponent';
import { User } from '../context/context';


const SearchPage = () => {

    const usernaw = useContext(User)
    const role = usernaw.auth.userDetals?.role

    const { t, i18n } = useTranslation();
    const [showProduct, setShowProduct] = useState(false)
    const [dataa, setDataa] = useState()
    const [show, setShow] = useState(false);

    const { getItemQuantity, increaseCartQuantity, decreaseCartQuantity } = useShoppingCart()

    let params = useParams()

    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(50)

    useEffect(() => {
        axios.post(`${process.env.REACT_APP_API_URL}/clineArticales/SearchProduct`, {
            search: params.titel,
            page: page,
            limit: limit
        })
            .then((doc) => setDataa(doc.data))
    }, [limit, page])

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 500);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const btnAddToCart = () => {
        setShow(true)
    }

    return (<>
        <div style={{ display: 'flex' }}>
            <div id='AllPage' style={{ marginTop: "65px", width: "100%" }}>
                <div id="PageUlProduct" style={{ display: 'flex' }}>
                    <div style={{ display: 'flex', width: "100%", justifyContent: "space-evenly", flexWrap: "wrap" }}>
                        {dataa?.movies?.map((item) =>
                            <div class="card" style={{ width: "150px", margin: "1%", border: "none", borderRadius: "10px", maxHeight: "250px", minWidth: isMobile && "150px" }}>
                                {role == "user" ? ((item.disable == "false" && item.number != 0) && (getItemQuantity(item._id) == 0 ?
                                    <button title={t("Add to cart")} onClick={() => increaseCartQuantity(item)} className="btnPlass" style={{ display: "block", position: "absolute", marginTop: "5px", marginRight: "5px", width: "40px", height: "40px", color: "#ea004b", borderRadius: "50%", backgroundColor: "white", border: "none" }}>
                                        {<FaPlus style={{ fontSize: "20px" }} />}
                                    </button>
                                    :
                                    <div style={{ display: "flex", position: "absolute", marginTop: "5px", marginRight: "5px", justifyContent: 'space-between', width: "80px", backgroundColor: "white", borderRadius: '5px' }}>
                                        <div style={{ height: "10px" }}>
                                            <button style={{ border: "none", backgroundColor: "white", color: "#ea004b", borderRadius: '5px' }} onClick={() => decreaseCartQuantity(item)}>
                                                <div className="btnPlass" ><FaMinus style={{ fontSize: "28px", padding: '8px' }} /></div>
                                            </button>
                                        </div>
                                        <div style={{ color: "#ea004b", fontWeight: "500", fontSize: "20px" }}>
                                            {getItemQuantity(item._id)}
                                        </div>
                                        <div>
                                            <button style={{ border: "none", backgroundColor: "white", color: "#ea004b", borderRadius: '5px' }} onClick={() => increaseCartQuantity(item)}>
                                                <div className="btnPlass"><FaPlus style={{ fontSize: "28px", padding: '8px' }} /></div>
                                            </button>
                                        </div>
                                    </div>))
                                    :
                                    !role && item.disable == "false" && <button title={t("Add to cart")} onClick={btnAddToCart} style={{ position: "absolute", marginTop: "5px", marginRight: "5px", width: "40px", height: "40px", color: "#ea004b", borderRadius: "50%", backgroundColor: "white", border: "none" }}>{<FaPlus style={{ fontSize: "20px" }} />}</button>
                                }
                                <Link onClick={() => { setShowProduct({ show: true, dataa: item, role: (role || "visitor") }) }} style={{ margin: "1%" }} >
                                    <img src={`${process.env.REACT_APP_API_URL}/files/${item.file[0]}`} class="card-img-top" style={{ maxHeight: "150px" }} />
                                    <div class="card-body" style={{ textAlign: "center" }}>
                                        {(item.number == 0 || item.disable === "true") && <div style={{ color: "red", fontWeight: "500" }}>{t("Out of stock")}</div>}
                                        <h5 class="card-title" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>{item.newPricee && <div>{item.newPricee} ₪</div>} <div style={item.newPricee && { fontWeight: "400", fontSize: "13px", textDecoration: 'line-through', color: "#818181" }}>{item.price} ₪</div>  </h5>
                                        {item.discount && <p class="card-text" style={{ textAlign: "center", backgroundColor: "#ea004b", borderRadius: "10px", color: "white" }}>{item.discount}% {t("Discount")}</p>}
                                        <p class="card-text">{i18n.language == "ar" ? item.title.ar : item.title.he}</p>
                                    </div>
                                </Link>
                            </div>
                        )}
                    </div>
                    <div style={{ display: 'flex', justifyContent: "flex-start", marginRight: "2%", alignItems: 'baseline', width: "100%" }}>
                        <Form.Select style={{ width: "80px", marginRight: "10px" }} onChange={(e) => setLimit(e.target.value)}>
                            {/* <option value={20}>20</option> */}
                            {/* <option value={30}>30</option> */}
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                            <option value={150}>150</option>
                        </Form.Select>
                        <PaginatedItems total={dataa?.total} itemsPerPage={limit} setPage={setPage} />
                    </div>
                </div>
            </div>
            {isMobile ? null :
                <div style={{ minHeight: "100vh", height: "100%", width: "25%", maxWidth: "500px", marginTop: "65px" }}>
                    {<CartComponent />}
                </div>
            }
            <ToastContainer >
                <MsgToast setShow={setShow} body={t("You are not registered, please log in and shop immediately")} show={show} title={t("You are not registered")} type={"danger"} />
            </ToastContainer >
            <ModalProduct show={showProduct.show} handleClose={() => setShowProduct(false)} dataa={showProduct.dataa} role={showProduct.role} />
        </div>
    </>)
}

export default SearchPage;