import axios from "axios"
import { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import "./HomeAdmin.css"
import { User } from "../../context/context"
import { Loading } from "../../refreshPage/loading"
import CloseButton from 'react-bootstrap/CloseButton';
import img1 from "../../file/555.png"
import Form from 'react-bootstrap/Form';
import { ModalProduct, MsgModal } from "../../components/MsgComponent"
import { useTranslation } from "react-i18next";
import { InputGroup } from "react-bootstrap"
import { IoSearchSharp } from "react-icons/io5";
import PaginatedItems from "../../components/pagination"
import SearchBar from "../../components/SearchBar"


function Home4admin() {
    const { t, i18n } = useTranslation();
    const [dataa, setDataa] = useState()

    const context = useContext(User)
    const token = context.auth.token

    const [hoveredItemId, setHoveredItemId] = useState(null);
    const [showProduct, setShowProduct] = useState(false)
    const [dataaOffers, setDataaOffers] = useState()
    const [show, setShow] = useState(false);
    const [itemId, setItemId] = useState();
    const [dataaAllProduct, setDataaAllProduct] = useState()
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(50)
    const [pageOffers, setPageOffers] = useState(1)
    const [limitOffers, setLimitOffers] = useState(50)

    const handleClose = () => setShow(false);
    const handleShow = (id) => {
        setShow(true)
        setItemId(id)
    };

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/articales/GetCategoryMarket`,
            {
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + token,
                }
            })
            .then((doc) => setDataa(doc.data))
            .catch((err) => {
                console.log("err Get :", err)
            })

        axios.get(`${process.env.REACT_APP_API_URL}/clineArticales/GetArticaleOffers/${limitOffers}/${pageOffers}`)
            .then((doc) => { setDataaOffers(doc.data) })
            .catch((err) => console.log("err Get Offers :", err))

        axios.get(`${process.env.REACT_APP_API_URL}/clineArticales/postGetArticales/${limit}/${page}`)
            .then((doc) => { setDataaAllProduct(doc.data) })
            .catch((err) => console.log("err Get Offers :", err))

    }, [token, limit, page, showProduct.show, show])
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 500);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const shwfile = (id) => {
        axios.delete(`${process.env.REACT_APP_API_URL}/articales/DeleteCategoryMarket/${id}`, {
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            }
        })
            .then(() => { setShow(false) })
            .then(() => { window.location.replace(`/admin/Home/Home4`) })
            .catch((err) => {
                console.log("err delet id : ", err)
            })
    }

    const data = dataa?.map((item) => ({
        _id: item._id,
        name: (i18n.language === 'ar' ? item.name.ar : item.name.he),
        file: item.file
    })) || [];

    return (
        <div id="allPage" style={{ paddingTop: '0', paddingBottom: "0" }}>
            {dataa ? <>
                <MsgModal show={show} handleClose={handleClose} opj={() => shwfile(itemId)} title={t("confirmation message")} body={t("Do you really want to delete this category?")} BtnState={t("Yes sure")} />
                <div style={{ width: "100%", minHeight: "100px", backgroundColor: "#ea004b", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                    <img src={`${img1}`} style={{ maxHeight: "70px", marginLeft: "50px" }}></img>
                </div>
                <div id="Page" style={{ borderTop: "soled 1px black", width: "100%", margin: '0', padding: "0", borderRadius: '0' }}>
                    <div style={{ height: "40px", width: isMobile ? "90%" : "50%", marginTop: "-25px", position: "sticky", top: isMobile ? "50px" : "70px", zIndex: "2", backgroundColor: "#ffffff", borderRadius: "20px", display: "flex", }}>
                        <InputGroup.Text style={{ backgroundColor: "white", borderLeft: "none", borderRadius: "0" }}>
                            <IoSearchSharp style={{ color: "#ea004b", fontSize: "20px" }} />
                        </InputGroup.Text>
                        <SearchBar />
                    </div>
                    <div id="PageUlCategory" style={isMobile ? { display: "flex", justifyContent: "center", marginTop: "40px", zIndex: "1" } : { marginTop: "40px", zIndex: "1" }}>
                        <h2 style={{ width: "100%", maxWidth: "1410px", textAlign: "start" }}>{t("All categories")}</h2>
                        {data.map((item) => (
                            <div
                                key={item._id}
                                className="card"
                                style={{ width: "100px", margin: "1%", border: "none", position: "relative" }}
                                onMouseEnter={() => setHoveredItemId(item._id)}
                                onMouseLeave={() => setHoveredItemId(null)}
                            >
                                {isMobile ? (
                                    <CloseButton
                                        style={{ position: "absolute", top: 0, left: 0, zIndex: 10 }}
                                        onClick={() => handleShow(item._id)}
                                    />
                                ) : hoveredItemId === item._id && (
                                    <CloseButton
                                        style={{ position: "absolute", top: 0, left: 0, zIndex: 10 }}
                                        onClick={() => handleShow(item._id)}
                                    />
                                )}
                                <Link to={`/admin/Home/Home4/${item.name}`}>
                                    <div>
                                        <img
                                            src={`${process.env.REACT_APP_API_URL}/files/${item.file[0]}`}
                                            className="card-img-top"
                                            style={{ maxHeight: "300px", borderRadius: "10px" }}
                                            alt="item image"
                                        />
                                        <div className="card-body">
                                            <h5 className="card-title" style={{ textAlign: "center", fontSize: "13px" }}>
                                                {item.name}
                                            </h5>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ))}
                    </div>
                    <div id="PageUlCategory">
                        <h2 style={{ width: "100%", maxWidth: "1410px", textAlign: "start" }}>{t("Offers")}</h2>
                        {dataaOffers?.doc?.map((item) =>
                            <div class="card" style={{ width: "150px", margin: "1%", border: "none", borderRadius: "10px", maxHeight: "250px" }}>
                                <Link onClick={() => { setShowProduct({ show: true, dataa: item, role: "admin" }) }} style={{ margin: "1%" }} >
                                    <img src={`${process.env.REACT_APP_API_URL}/files/${item.file[0]}`} class="card-img-top" style={{ maxHeight: "150px" }} />
                                    <div class="card-body" style={{ textAlign: "center" }}>
                                        <h5 class="card-title" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>{item.newPricee && <div>{item.newPricee} ₪</div>} <div style={item.newPricee && { fontWeight: "400", fontSize: "13px", textDecoration: 'line-through', color: "#818181" }}>{item.price} ₪</div>  </h5>
                                        {item.discount && <p class="card-text" style={{ textAlign: "center", backgroundColor: "#ea004b", borderRadius: "10px", color: "white" }}>{item.discount}% {t("Discount")}</p>}
                                        <p class="card-text">{i18n.language == "ar" ? item.title.ar : item.title.he}</p>
                                    </div>
                                </Link>
                            </div>
                        )}
                        <div style={{ display: 'flex', justifyContent: "flex-start", marginRight: "2%", alignItems: isMobile ? "center" : 'baseline', width: "98%", flexDirection: isMobile && "column-reverse", }}>
                            <Form.Select style={{ width: "80px", marginRight: isMobile ? "0" : "10px" }} onChange={(e) => setLimitOffers(e.target.value)}>
                                {/* <option value={20}>20</option> */}
                                {/* <option value={30}>30</option> */}
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                                <option value={150}>150</option>
                            </Form.Select>
                            <PaginatedItems total={dataaOffers?.total} itemsPerPage={limitOffers} setPage={setPageOffers} />
                        </div>
                    </div>
                    <div id="PageUlCategory" >
                        <h2 style={{ width: "100%", maxWidth: "1410px", textAlign: "start" }}>{t("All Products")}</h2>
                        <div style={{ display: 'flex', width: "100%", justifyContent: "space-evenly", flexWrap: "wrap" }}>
                            {dataaAllProduct?.doc?.map((item) =>
                                <div class="card" style={{ width: "150px", margin: "1%", border: "none", borderRadius: "10px", maxHeight: "250px", minWidth: isMobile && "150px" }}>
                                    <Link onClick={() => { setShowProduct({ show: true, dataa: item, role: "admin" }) }} style={{ margin: "1%" }} >
                                        <img src={`${process.env.REACT_APP_API_URL}/files/${item.file[0]}`} class="card-img-top" style={{ maxHeight: "150px" }} />
                                        <div class="card-body" style={{ textAlign: "center" }}>
                                            <h5 class="card-title" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>{item.newPricee && <div>{item.newPricee} ₪</div>} <div style={item.newPricee && { fontWeight: "400", fontSize: "13px", textDecoration: 'line-through', color: "#818181" }}>{item.price} ₪</div>  </h5>
                                            {item.discount && <p class="card-text" style={{ textAlign: "center", backgroundColor: "#ea004b", borderRadius: "10px", color: "white" }}>{item.discount}% {t("Discount")}</p>}
                                            <p class="card-text">{i18n.language == "ar" ? item.title.ar : item.title.he}</p>
                                        </div>
                                    </Link>
                                </div>
                            )}
                        </div>
                        <div style={{ display: 'flex', justifyContent: "flex-start", marginRight: "2%", alignItems: 'baseline', width: "100%" }}>
                            <Form.Select style={{ width: "80px", marginRight: "10px" }} onChange={(e) => setLimit(e.target.value)}>
                                {/* <option value={20}>20</option> */}
                                {/* <option value={30}>30</option> */}
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                                <option value={150}>150</option>
                            </Form.Select>
                            <PaginatedItems total={dataaAllProduct?.total} itemsPerPage={limit} setPage={setPage} />
                        </div>
                    </div>


                </div>
            </> : <Loading />}
            <ModalProduct show={showProduct.show} handleClose={() => setShowProduct(false)} dataa={showProduct.dataa} role={showProduct.role} />
        </div >
    )
}

export default Home4admin